import styles from "./styles.module.scss";
import PlaceholderAvatar from "./../../assets/images/avatar.png";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
interface Props {
  image: null | string;
  name: string;
  location: string;
  lookingFor: string;
  phone: string;
  email: string;
  bussinessName: string;
  countEmployees: string;
  revenue: string;
  industry?: string[];
}
export default function UserInfoBlock({ item }: { item: Props }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={styles.userInfo}>
      <div className={styles.userProfile}>
        <div style={{ position: "relative" }} className={styles.image}>
          {!imageLoaded && <Skeleton style={{ position: "absolute" }} width={300} height={200} />}

          <img src={item.image || PlaceholderAvatar} alt="USer Avatar" onLoad={() => setImageLoaded(true)} />
        </div>
        <div className={styles.personalInfo}>
          <span className={styles.userName}>{item.name}</span>
          <span className={styles.userLocation}>{item.location}</span>
        </div>
      </div>
      <div className={styles.userSection}>
        <span className={styles.title}>Looking for</span>
        <span className={styles.subtitle}>{item.lookingFor}</span>
      </div>
      <div className={styles.userSection}>
        <span className={styles.title}>Phone</span>
        <span className={styles.subtitle}>{item.phone}</span>
      </div>
      <div className={styles.userSection}>
        <span className={styles.title}>Email</span>
        <span className={styles.subtitle}>{item.email}</span>
      </div>
      <div className={styles.dividerX}></div>
      <div className={styles.userSection}>
        <span className={styles.title}>Business Name</span>
        <span className={styles.subtitle}>{item.bussinessName}</span>
      </div>
      <div className={styles.userSection}>
        <span className={styles.title}>Industry</span>
        <span className={styles.subtitle}>{item.industry?.join(", ")}</span>
      </div>
      <div className={styles.userSection}>
        <span className={styles.title}>Number of Employees</span>
        <span className={styles.subtitle}>{item.countEmployees}</span>
      </div>
      <div className={styles.userSection}>
        <span className={styles.title}>Revenue</span>
        <span className={styles.subtitle}>{item.revenue}</span>
      </div>
    </div>
  );
}
