import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/Button";
import Textarea from "../../../components/Textarea";
import { IOwner } from "../../../models/IOwner";
import GoogleInput from "../../../components/Input/GoogleInput";
import { editBusiness } from "../../../services/businessOwners.service";
import { PulseLoader } from "react-spinners";
import { industries, toastError } from "../../../constants";
import { toast } from "react-hot-toast";
import AgreementModal from "../../../modals/Agreement";
import MultiSelect from "../../../components/Select/MultiSelect";
import { getUser } from "../../../utils/tokenStorage";
import GoogleInputWithAddress from "../../../components/Input/GoogleInputWithAddress";
import SimpleMultiSelect from "../../../components/Select/SimpleMultiSelect";

const businessNeedsOption = [
  "Local Staff",
  "Diaspora Staff",
  "Partnership",
  "Funding",
  "Additional needs",
  "Contract & consulting opportunities",
  "Contractors",
  "Suppliers",
  "Buyers",
];

const BusinessDetails = ({
  owner,
  onGetOwner,
  id,
}: {
  owner?: IOwner | undefined;
  onGetOwner?: (value: string) => void;
  id: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [businessName, setBusinessName] = useState("");
  const [webSite, setWebSite] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [businessRevenue, setBusinessRevenue] = useState("0 - 100k");
  const [businessType, setBusinessType] = useState("Startup");
  const [buisnessStage, setBusinessStage] = useState("Launch");
  const [currentBusinessNeeds, setCurrentBusinessNeeds] = useState(["Local Staff"]);
  const [businessIndustry, setBusinessIndustry] = useState(["Education"]);
  const [currentBusinessNeedsOther, setCurrentBusinessNeedsOther] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState("0 - 50");
  const [travel, setTravel] = useState("1");
  const [businessSector, setBusinessSector] = useState("Road transport");
  const [yearsInBusiness, setYearsInBusiness] = useState("1 - 2 years ");
  const [businessDescription, setBusinessDescription] = useState("");
  const [location, setLocation] = useState<{ address: string; city: string; state?: string; country: string }>({
    address: "",
    city: "",
    state: "",
    country: "",
  });
  const saveRef = useRef<HTMLDivElement>(null);
  const [isEmptyLocation, setIsEmptyLocation] = useState(false);

  const [businessNameError, setBusinessNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [commonError, setCommonError] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isAgreeModal, setIsAgreeModal] = useState(false);

  const me = getUser();

  useEffect(() => {
    if (owner) {
      setBusinessName(owner?.business[0]?.name || "");
      setWebSite(owner?.business[0]?.website || "");
      setPhone(owner?.business[0]?.phone || "");
      setEmail(owner?.business[0]?.email || "");
      if (owner?.business[0]?.revenue?.min) {
        setBusinessRevenue("0 - 100k");
      } else if (
        typeof owner?.business[0]?.revenue?.min === "number" &&
        typeof owner?.business[0]?.revenue?.max === "number" &&
        owner?.business[0]?.revenue?.min === owner?.business[0]?.revenue?.max
      ) {
        setBusinessRevenue(`${owner?.business[0]?.revenue?.min}k +`);
      } else {
        setBusinessRevenue(
          `${
            owner?.business[0]?.revenue?.min > 0
              ? owner?.business[0]?.revenue?.min + "k"
              : owner?.business[0]?.revenue?.min || 0
          } - ${owner?.business[0]?.revenue?.max || 100}k`,
        );
      }
      setBusinessType(owner?.business[0]?.type || "Startup");
      setBusinessStage(owner?.business[0]?.stage || "Launch");
      setCurrentBusinessNeeds(owner?.business[0]?.currentNeeds || ["Local Staff"]);
      setTravel(owner?.business[0]?.travelFrequency || "1");
      if (typeof owner?.business[0]?.employees?.min === "undefined" && !owner?.business[0]?.employees?.min) {
        setNumberOfEmployees("0 - 50");
      } else if (owner?.business[0]?.employees?.min === owner?.business[0]?.employees?.max) {
        setNumberOfEmployees(`${owner?.business[0]?.employees?.min}+`);
      } else {
        setNumberOfEmployees(`${owner?.business[0]?.employees.min || 0} - ${owner?.business[0]?.employees.max || 50}`);
      }
      setBusinessIndustry(owner?.business[0]?.industry);
      setYearsInBusiness(
        `${
          !owner?.business[0]?.yearsInBusiness
            ? "1 - 2 years"
            : typeof owner?.business[0]?.yearsInBusiness.min === "number" &&
              typeof owner?.business[0]?.yearsInBusiness.max === "number" &&
              owner?.business[0]?.yearsInBusiness.min === owner?.business[0]?.yearsInBusiness.max
            ? `${owner?.business[0]?.yearsInBusiness.min} years +`
            : `${owner?.business[0]?.yearsInBusiness.min} - ${owner?.business[0]?.yearsInBusiness.max} years`
        }`,
      );
      setBusinessDescription(owner?.business[0]?.description || "");
      setLocation({
        address: owner?.business[0]?.location?.address || "",

        city: owner?.business[0]?.location?.city || "",
        state: owner?.business[0]?.location?.state || "",
        country: owner?.business[0]?.location?.country || "",
      });

      owner?.business[0]?.currentNeeds.forEach((item) => {
        if (!businessNeedsOption.includes(item) || item === "Other") {
          setCurrentBusinessNeedsOther(item);
        }
      });
    }
  }, [owner]);

  function validateEmail(email: string) {
    if (email.length > 80) {
      return "Email address must be at most 80 characters long.";
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regex.test(email)) {
      return "Please enter a valid email address.";
    }

    return true;
  }

  function validateWebsite(uri: string) {
    const regex = /^((https?|ftp):\/\/)?([^\s/]+\.[^\s/]+)\/?.*$/;

    if (!regex.test(uri)) {
      return "Please enter a valid URI.";
    }

    return true;
  }

  function validatePhone(phone: string) {
    const regex = /^[\d+\-() ]+$/;

    if (!regex.test(phone)) {
      return "Please enter a valid phone number.";
    }

    if (phone.includes("-")) {
      return "Phone number cannot contain hyphens.";
    }

    return true;
  }

  const onSave = async () => {
    try {
      if (businessName.length === 0) {
        setBusinessNameError("Name is required");
        return;
      }

      const emailError = validateEmail(email);
      if (typeof emailError === "string") {
        setEmailError(emailError);
        return;
      }

      const websiteError = validateWebsite(webSite);
      if (webSite.length > 0 && typeof websiteError === "string") {
        setWebsiteError(websiteError);
        return;
      }

      const phoneError = validatePhone(phone);
      if (phone.length > 0 && typeof phoneError === "string") {
        setPhoneError(phoneError);
        return;
      }

      if (isEmptyLocation) {
        setLocationError("Location is required");
        return;
      }

      if (businessDescription.length === 1) {
        setDescriptionError("Must be at least 2 characters");
        return;
      }

      if (businessDescription.length > 1000) {
        setDescriptionError("Must be at most 1000 characters");
        return;
      }

      if (currentBusinessNeedsOther.length === 1) {
        setCommonError("Field Other must be at least 2 characters");
        return;
      }

      if (businessIndustry.length === 0) {
        setCommonError("Industry is required");
        return;
      }
      if (businessIndustry.length > 3) {
        setCommonError("Industry must be at most 3");
        return;
      }
      setIsLoading(true);

      const formatedNeeds = currentBusinessNeeds.filter((need) => businessNeedsOption.includes(need));

      //@ts-ignore
      const { data } = await editBusiness(
        id,
        owner?.business[0]?.socialConnection || " ",
        businessName,
        webSite || null,
        phone || null,

        email,
        {
          //@ts-ignore
          min: +businessRevenue.match(/\d+/g)[0],
          max:
            //@ts-ignore
            businessRevenue.match(/\d+/g)?.length > 1
              ? //@ts-ignore
                +businessRevenue.match(/\d+/g)[1]
              : //@ts-ignore
                +businessRevenue.match(/\d+/g)[0],
        },
        businessType,
        travel,
        location,
        buisnessStage,
        currentBusinessNeedsOther.length > 0 ? [...formatedNeeds, currentBusinessNeedsOther] : formatedNeeds,
        {
          //@ts-ignore
          min: numberOfEmployees.match(/\d+/g)[0],
          max:
            //@ts-ignore
            numberOfEmployees.match(/\d+/g)?.length > 1
              ? //@ts-ignore
                +numberOfEmployees.match(/\d+/g)[1]
              : //@ts-ignore
                +numberOfEmployees.match(/\d+/g)[0],
        },
        {
          //@ts-ignore
          min: yearsInBusiness.match(/\d+/g)[0],
          max:
            //@ts-ignore
            yearsInBusiness.match(/\d+/g)?.length > 1
              ? //@ts-ignore
                +yearsInBusiness.match(/\d+/g)[1]
              : //@ts-ignore
                +yearsInBusiness.match(/\d+/g)[0],
        },
        businessIndustry,
        businessDescription || null,
      );
      if (data?.success) {
        onGetOwner?.(id || "");
        setBusinessNameError("");
        setEmailError("");
        setCommonError("");
        setLocationError("");
        setWebsiteError("");
        setDescriptionError("");
        setPhoneError("");
        setButtonDisabled(true);
      }
      setIsLoading(false);
      toast.success("User has been successfully updated");
    } catch (error: any) {
      setIsLoading(false);
      toast.error(toastError);

      if (!error?.success) {
        setCommonError(error.message);
      }
    }
  };

  const onSelectLocation = (location: { address: string; city: string; state?: string; country: string }) => {
    setLocation(location);
    setLocationError("");

    setButtonDisabled(false);
  };

  const handlePhoneChange = (event: any) => {
    setPhoneError("");
    let value = event.target.value;
    if (!value.startsWith("+")) {
      value = "+" + value;
    }
    setPhone(value);

    setButtonDisabled(false);
  };

  useEffect(() => {
    setIsEmptyLocation(true);
    setButtonDisabled(true);
    saveRef.current?.addEventListener("click", (e) => {
      const locationInput: any = document.querySelector(".pac-target-input");
      if (locationInput && locationInput.value.length === 0) {
        setIsEmptyLocation(true);
      } else {
        setIsEmptyLocation(false);
      }
    });
    return () => {
      saveRef.current?.removeEventListener("click", (e) => {
        const locationInput: any = document.querySelector(".pac-target-input");
        if (locationInput && locationInput.value.length === 0) {
          setIsEmptyLocation(true);
        } else {
          setIsEmptyLocation(false);
        }
      });
    };
  }, []);

  return (
    <>
      <AgreementModal
        isLoading={isLoading}
        isOpen={isAgreeModal}
        onAccept={onSave}
        setIsOpen={setIsAgreeModal}
        title="Confirmation"
      />
      {isLoading && !owner ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#236BFE"} />
        </div>
      ) : (
        <>
          <div className={styles.profileSection}>
            <span>Social Connection</span>
            <a href={owner?.business[0]?.website} target="_blank">
              {owner?.business[0]?.socialConnection}
            </a>
          </div>

          <div className={styles.businessName}>
            <Input
              label="Business Name"
              disabled={me?.role === "manager"}
              placeholder="Write your business name"
              style={{ padding: "12px 16px", width: "96%" }}
              value={businessName}
              error={businessNameError}
              onChange={(e) => {
                setBusinessName(e.target.value);
                setBusinessNameError("");

                setButtonDisabled(false);
              }}
            />
          </div>
          <div className={styles.formSection}>
            <Input
              label="Website"
              disabled={me?.role === "manager"}
              style={{ padding: "12px 16px" }}
              placeholder="https://"
              value={webSite}
              error={websiteError}
              onChange={(e) => {
                setWebSite(e.target.value);
                setWebsiteError("");

                setButtonDisabled(false);
              }}
            />
            <div className={styles.locationForm}>
              <GoogleInputWithAddress
                label="Location"
                disabled={me?.role === "manager"}
                placeholder="Write your location"
                error={locationError}
                value={
                  location.address
                    ? location.address
                    : location.city && location.country
                    ? `${location.city},${
                        location.state || (location.state && location.state?.length > 0) ? `${location.state}, ` : ""
                      } ${location.country}`
                    : ""
                }
                onSelect={onSelectLocation}
              />
            </div>
            <Input
              label="Phone"
              disabled={me?.role === "manager"}
              type="tel"
              placeholder="Write your business phone"
              style={{ padding: "12px 16px" }}
              value={phone}
              onChange={handlePhoneChange}
              error={phoneError}
            />
            <Input
              label="Email"
              disabled={me?.role === "manager"}
              placeholder="Write your email"
              style={{ padding: "12px 16px" }}
              error={emailError}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");

                setButtonDisabled(false);
              }}
            />
            <Select
              label="Business Type"
              disabled={me?.role === "manager"}
              options={["Startup", "Small business", "Medium business", "Large business"]}
              value={businessType}
              onChange={(option) => {
                setButtonDisabled(false);

                setBusinessType(option);
              }}
            />
            <Select
              label="Business Stage"
              disabled={me?.role === "manager"}
              options={["Launch", "Growth", "Shake-out", "Maturity", "Decline"]}
              value={buisnessStage}
              onChange={(option) => {
                setButtonDisabled(false);

                setBusinessStage(option);
              }}
            />
            <Select
              label="Business Revenue"
              disabled={me?.role === "manager"}
              options={["0 - 100k", "100k - 250k ", " 250k - 500k ", "500k - 700k ", "700k +"]}
              value={businessRevenue}
              onChange={(option) => {
                setButtonDisabled(false);

                setBusinessRevenue(option);
              }}
            />
            <Select
              label="Years in Business"
              disabled={me?.role === "manager"}
              options={["1 - 2 years ", "2 - 4 years ", "4 - 5 years ", "6 years +"]}
              value={yearsInBusiness}
              onChange={(option) => {
                setButtonDisabled(false);

                setYearsInBusiness(option);
              }}
            />
            <Select
              disabled={me?.role === "manager"}
              label="Number of Employees"
              options={["0 - 50", "50 - 100", "100+"]}
              value={numberOfEmployees}
              onChange={(option) => {
                setButtonDisabled(false);

                setNumberOfEmployees(option);
              }}
            />
            <Select
              disabled={me?.role === "manager"}
              label="Travel Frequency"
              options={["1", "2", "3", "4+"]}
              value={travel}
              onChange={(option) => {
                setButtonDisabled(false);

                setTravel(option);
              }}
            />
            {/* <Select
              label="Business Sector"
              disabled={me?.role === "manager"}
              options={[
                "Road transport",
                "Coastal transport",
                "Fisheries",
                "Fruits & vegetables",
                "Real estate",
                "Tourism",
                "Agribusiness",
                "Light manufacturing",
              ]}
              value={businessSector}
              onChange={(option) => {
                setButtonDisabled(false);

                setBusinessSector(option);
              }}
            /> */}
          </div>
          <Textarea
            label="Business Description"
            disabled={me?.role === "manager"}
            placeholder="Write description about your company"
            value={businessDescription}
            error={descriptionError}
            onChange={(e) => {
              setBusinessDescription(e.target.value);
              setDescriptionError("");

              setButtonDisabled(false);
            }}
            style={{ minHeight: "164px", width: "96%" }}
          />
          <div className={styles.multiSelect}>
            {" "}
            {/* <MultiSelect
              multiple
              removeOther
              disabled={me?.role === "manager"}
              setOtherFun={setCurrentBusinessNeedsOther}
              other={currentBusinessNeedsOther}
              label="Industry"
              subtitle="Choose which industry your business is for"
              options={[
                "Education",
                "Construction",
                "Design",
                "Corporate Services",
                "Retail",
                "Energy & Mining",
                "Manufacturing",
                "Finance",
              ]}
              setButtonDisabled={setButtonDisabled}
              value={businessIndustry}
              onChange={(option) => {
                setBusinessIndustry(option);
                setButtonDisabled(false);
              }}
            /> */}
            <SimpleMultiSelect
              value={businessIndustry}
              onChange={(option) => {
                setButtonDisabled(false);
                setBusinessIndustry(option);
              }}
              label="Industry"
              options={industries}
            />
          </div>
          <div className={styles.multiSelect}>
            {" "}
            <MultiSelect
              multiple
              disabled={me?.role === "manager"}
              setOtherFun={setCurrentBusinessNeedsOther}
              other={currentBusinessNeedsOther}
              label="Business Needs"
              subtitle="Choose all resources your business need"
              options={businessNeedsOption}
              setButtonDisabled={setButtonDisabled}
              value={currentBusinessNeeds}
              onChange={(option) => {
                setCurrentBusinessNeeds(option);
                setButtonDisabled(false);
              }}
            />
          </div>
          {commonError && <p className={styles.commonError}>{commonError}</p>}
          {me?.role !== "manager" && (
            <div ref={saveRef} className={styles.button}>
              <Button title="Save" onClick={() => setIsAgreeModal(true)} disabled={buttonDisabled || isLoading} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BusinessDetails;
