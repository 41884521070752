import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import {
  getAllBusinessOwnersVerificationVerify,
  getAllExplorersVerificationVerify,
} from "../../services/verification.service";
import Checkbox from "../../components/Checkbox";
import styles from "./styles.module.scss";
import { ArrowDownIcon } from "../../assets";
import clsx from "clsx";
import OwnerVerificationItem from "../../components/VerificationItem/OwnersVerificationItem";
import { PulseLoader } from "react-spinners";
import PageItem from "../../components/PageItem";
import { IExplorer } from "../../models/IExplorers";
import Empty from "../../components/Empty/Index";
import ExplorerVerificationItem from "../../components/VerificationItem/ExplorerVerificationItem";
import { NumbersContext } from "../../components/VerificationCounterContext/VerificationProvider";
const limit = 20;
export default function BusinessExplorersVerification({
  setVerifyUsers,
  setTypePage,
  isLoading: loadingServer,
  handleVerify,
  setTagExplorer,
  setTagOwner,
}: {
  setVerifyUsers: (value: string[]) => void;
  setTypePage: (value: "owner" | "explorer") => void;
  isLoading: boolean;
  handleVerify: (verifyData?: { id: string; status: "Approved" | "Declined" }) => void;
  setTagExplorer: (value: number) => void;
  setTagOwner: (value: number) => void;
}) {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState(false);
  const [owners, setOwners] = useState<IExplorer[]>([]);
  const [firstNameSort, setFirstNameSort] = useState<1 | -1 | 0>(0);
  const [lastNameSort, setLastNameSort] = useState<1 | -1 | 0>(0);
  const [citySort, setCitySort] = useState<1 | -1 | 0>(0);
  const [phoneSort, setPhoneSort] = useState<1 | -1 | 0>(0);
  const [emailSort, setEmailSort] = useState<1 | -1 | 0>(0);
  const [createdAtSort, setCreatedAtSort] = useState<1 | -1 | 0>(0);
  const verificationTotalCount = useContext(NumbersContext);
  const onGetExplorers = async (
    page: number = 1,
    limit: number,
    search: string = "",
    type?: "firstName" | "lastName" | "location.city" | "phone" | "email" | "createdAt",
  ) => {
    try {
      setIsLoading(true);
      let sort;
      switch (type) {
        case "firstName":
          sort = { firstName: firstNameSort };
          break;
        case "lastName":
          sort = { lastName: lastNameSort };
          break;
        case "location.city":
          sort = { "location.city": citySort };
          break;
        case "phone":
          sort = { phone: phoneSort };
          break;
        case "email":
          sort = { email: emailSort };
          break;
        case "createdAt":
          sort = { createdAt: createdAtSort };
          break;
        default:
          break;
      }
      const { data } = await getAllExplorersVerificationVerify(page, limit, search, sort || {});
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalExplorersNV / limit));
        setOwners(data?.explorers);
        setTagOwner(data?.totalOwnersNV);
        setTagExplorer(data?.totalExplorersNV);
        verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetExplorers(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetExplorers(1, limit);
    }
  }, [search]);
  useEffect(() => {
    setTypePage("explorer");
  }, []);

  useEffect(() => {
    if (firstNameSort !== 0) {
      onGetExplorers(page, 20, search, "firstName");
    } else if (citySort !== 0) {
      onGetExplorers(page, 20, search, "location.city");
    } else if (phoneSort !== 0) {
      onGetExplorers(page, 20, search, "phone");
    } else if (emailSort !== 0) {
      onGetExplorers(page, 20, search, "email");
    } else if (createdAtSort !== 0) {
      onGetExplorers(page, 20, search, "createdAt");
    } else {
      onGetExplorers(page, 20, search, "nothing" as "firstName");
    }
  }, [firstNameSort, citySort, phoneSort, emailSort, createdAtSort]);

  return (
    <div className={styles.wrapperExplorer}>
      <section className={styles.contentContainer}>
        <div className={styles.sectionHeader}>
          <div className={styles.checkbox}>
            <Checkbox isChecked={isChecked} setIsChecked={() => setIsChecked((current) => !current)} mainCheckBox />
          </div>
          <div
            onClick={() => {
              setCitySort(0);
              setPhoneSort(0);
              setEmailSort(0);
              setCreatedAtSort(0);
              setFirstNameSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetExplorers(page, 20, search, "firstName");
            }}
            className={clsx(styles.nameBlock, {
              [styles.activeBlockAZ]: firstNameSort === 1,
              [styles.activeBlockZA]: firstNameSort === -1,
            })}
          >
            <p>Name</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>
          <div
            onClick={() => {
              setFirstNameSort(0);
              setPhoneSort(0);
              setEmailSort(0);
              setCreatedAtSort(0);
              setCitySort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetExplorers(page, 20, search, "location.city");
            }}
            className={clsx(styles.industryBlock, {
              [styles.activeBlockAZ]: citySort === 1,
              [styles.activeBlockZA]: citySort === -1,
            })}
          >
            <p>Location</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>

          <div
            onClick={() => {
              setFirstNameSort(0);
              setCitySort(0);
              setEmailSort(0);
              setCreatedAtSort(0);
              setPhoneSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetExplorers(page, 20, search, "phone");
            }}
            className={clsx(styles.locationBlock, {
              [styles.activeBlockAZ]: phoneSort === 1,
              [styles.activeBlockZA]: phoneSort === -1,
            })}
          >
            <p>Phone</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>
          <div
            onClick={() => {
              setFirstNameSort(0);
              setCitySort(0);
              setPhoneSort(0);
              setCreatedAtSort(0);
              setEmailSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetExplorers(page, 20, search, "email");
            }}
            className={clsx(styles.sizeBlock, {
              [styles.activeBlockAZ]: emailSort === 1,
              [styles.activeBlockZA]: emailSort === -1,
            })}
          >
            <p>Email</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>
          <div
            onClick={() => {
              setFirstNameSort(0);
              setCitySort(0);
              setPhoneSort(0);
              setEmailSort(0);
              setCreatedAtSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetExplorers(page, 20, search, "createdAt");
            }}
            className={clsx(styles.sizeBlock, {
              [styles.activeBlockAZ]: createdAtSort === 1,
              [styles.activeBlockZA]: createdAtSort === -1,
            })}
          >
            <p className={styles.dateBlock}>Registration date</p> <ArrowDownIcon fill="#141415" width={"13px"} />
          </div>
        </div>
        <div className={styles.reportsContainer}>
          {isLoading && (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#236BFE"} />
            </div>
          )}
          {!isLoading &&
            owners.length > 0 &&
            owners.map((item, index) => {
              return (
                <ExplorerVerificationItem
                  allChecked={isChecked}
                  key={item.id}
                  isLoading={loadingServer || isLoading}
                  item={item}
                  setVerifyUsers={setVerifyUsers}
                  handleVerify={handleVerify}
                />
              );
            })}
          {!isLoading && owners.length === 0 && <Empty />}
        </div>
        <div className={styles.paginationContainer}>
          {pagesCount >= 0 &&
            Array(pagesCount === 0 ? 1 : pagesCount)
              .fill("_")
              .map((item, index) => (
                <PageItem
                  onPress={() => onGetExplorers(index + 1, limit, search)}
                  key={index}
                  page={index + 1}
                  isActive={index + 1 === page}
                />
              ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div onClick={() => onGetExplorers(page + 1, limit, search)} className={styles.nextBtn}>
              Next
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
