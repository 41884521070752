import styles from "./styles.module.scss";
import Input from "../../../components/Input";
import { SearchIcon } from "../../../assets";
import { useEffect, useState } from "react";
import PostItems from "../../../components/PostItem";
import PostModal from "../../../modals/Post";
import { getPosts } from "../../../services/businessOwners.service";
import { useParams } from "react-router-dom";
import { IPost } from "../../../models/IModel";
import PageItem from "../../../components/PageItem";
import Empty from "../../../components/Empty/Index";
import { PulseLoader } from "react-spinners";
const limit = 20;
const Posts = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState<IPost[]>();
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  const [totalPosts, setTotalPosts] = useState(0);
  const { id } = useParams();

  const onGetPosts = async (id: string, page?: number, limit?: number, search?: string) => {
    try {
      setIsLoading(true);
      const { data } = await getPosts(id, page || 1, limit || 20, search || "");
      if (data.success) {
        setPosts(data.posts);
        setPagesCount(Math.ceil(data?.totalPosts / (limit || 20)) || 1);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetPosts(id || "");
  }, []);
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetPosts(id || "", page, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetPosts(id || "", page, limit, search);
    }
  }, [search]);
  return (
    <>
      <div className={styles.profileSection}>
        <div className={styles.input}>
          <Input
            icon={SearchIcon}
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            setEmpty={() => setSearch("")}
            isEmpty={search.length === 0}
          />
        </div>
        <div className={styles.postBody}>
          {isLoading && (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#236BFE"} />
            </div>
          )}
          {!isLoading &&
            posts?.map((item) => {
              return (
                <div className={styles.postWrapper}>
                  <PostItems item={item} />
                </div>
              );
            })}
          {!isLoading && posts?.length === 0 && <Empty />}
          <div className={styles.paginationContainer}>
            {pagesCount >= 0 &&
              Array(pagesCount === 0 ? 1 : pagesCount)
                .fill("_")
                .map((item, index) => (
                  <PageItem
                    onPress={() => onGetPosts(id || "", index + 1, limit, search)}
                    key={index}
                    page={index + 1}
                    isActive={index + 1 === page}
                  />
                ))}
            {pagesCount > 1 && page !== pagesCount && (
              <div onClick={() => onGetPosts(id || "", page + 1, limit, search)} className={styles.nextBtn}>
                Next
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Posts;
