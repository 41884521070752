import { CheckIcon } from "../../assets";
import clsx from "clsx";
import React, { FC } from "react";
import styles from "./styles.module.scss";

interface Props {
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  iconSize?: number;
  mainCheckBox?: boolean;
}

const Checkbox = ({ isChecked, setIsChecked, iconSize = 14, mainCheckBox = false }: Props) => {
  return (
    <div
      onClick={() => setIsChecked(!isChecked)}
      className={clsx({
        [styles.checkedMain]: mainCheckBox && isChecked,
        [styles.checked]: isChecked,
        [styles.unChecked]: !isChecked,
      })}
    >
      <div className={styles.mainCheckBoxInner}></div>
      {isChecked && !mainCheckBox && <CheckIcon width={iconSize} height={iconSize} />}
    </div>
  );
};

export default Checkbox;
