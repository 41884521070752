import { FC, useEffect, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import { ArrowDownIcon } from "../../assets";
import { useNavigate } from "react-router-dom";
import StaffItem from "../StaffItem";
import { IExplorer } from "../../models/IExplorers";
import ActiveButton from "../ActiveButtons/ActiveButton";
import DeclineButton from "../ActiveButtons/DeclineButton";
import AgreementModal from "../../modals/Agreement";
import ApproveModal from "../../modals/Approve";
import { getUser } from "../../utils/tokenStorage";

const ExplorerVerificationItem = ({
  item,
  allChecked,
  setVerifyUsers,
  isLoading,
  handleVerify,
}: {
  item: IExplorer;
  allChecked: boolean;
  setVerifyUsers: (item: any) => void;
  isLoading: boolean;
  handleVerify: (verifyData?: { id: string; status: "Approved" | "Declined"; reason?: string }) => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const me = getUser();
  useEffect(() => {
    setIsChecked(allChecked);
  }, [allChecked]);
  // FC<Props>
  const navigate = useNavigate();

  useEffect(() => {
    if (isChecked) {
      setVerifyUsers((current: any) => [...current, item.id]);
    }
    if (!isChecked) setVerifyUsers((current: any) => current.filter((id: any) => id != item.id));
  }, [isChecked]);
  useEffect(() => {
    return () => setVerifyUsers([]);
  }, []);

  function formatDate(dateString: string) {
    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = MONTHS[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }
  const verify = (status?: "Approved" | "Declined", reason?: string) => {
    if (isDelete) {
      handleVerify({ id: item.id, status: status || "Declined", reason: reason || "" });
    } else handleVerify({ id: item.id, status: "Approved" });
  };
  return (
    <>
      <AgreementModal
        isLoading={isLoading}
        isOpen={showConfirmModal}
        setIsOpen={setShowConfirmModal}
        text={isDelete ? "Decline verification?" : "Verify user?"}
        title="Verification"
        onAccept={verify}
      />
      <ApproveModal isOpen={showApproveModal} setIsOpen={setShowApproveModal} onAccept={verify} user={item} />

      <div className={styles.businessBody}>
        <div className={styles.wrapperExplorer}>
          <div className={styles.checkbox}>
            <CheckBox isChecked={isChecked} setIsChecked={() => setIsChecked((current) => !current)} />
          </div>
          <div onClick={() => navigate(`/verification/explorer/${item.id}`)}>
            <StaffItem image={item.avatar} name={`${item.firstName} ${item.lastName}`} />
          </div>
          <div onClick={() => navigate(`/verification/explorer/${item.id}`)}>
            <div className={styles.ownerLocationWrapper}>
              <p>{`${item.location.city}${item.location.state ? `, ${item.location.state}` : ""}`}</p>
            </div>
          </div>
          <div onClick={() => navigate(`/verification/explorer/${item.id}`)}>
            <div className={styles.businessLocationWrapper}>
              <p>{item.phone}</p>
            </div>
          </div>
          <div onClick={() => navigate(`/verification/explorer/${item.id}`)}>
            <div className={styles.businessTypeWrapper}>
              <p>{item.email}</p>
            </div>
          </div>
          <div onClick={() => navigate(`/verification/explorer/${item.id}`)}>
            <div className={styles.revenueWrapper}>
              <p>{formatDate(item.createdAt)}</p>
            </div>
          </div>
          {me?.role !== "manager" && (
            <div className={styles.acceptButtons}>
              <div
                onClick={() => {
                  setShowConfirmModal(true);
                }}
              >
                <ActiveButton />
              </div>
              <div
                onClick={() => {
                  setShowApproveModal(true);
                  setDelete(true);
                }}
              >
                <DeclineButton />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExplorerVerificationItem;
