import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../assets";
// import { Button, Radio, Textarea } from "../../components";
import PlaceholderAvatar from "./../../assets/images/avatar.png";
// import { blockCandidate } from "../../services/candidate.service";
// import { blockEmployer } from "../../services/employer.service";
import styles from "./styles.module.scss";
import Textarea from "../../components/Textarea";
import Button from "../../components/Button";
import Tag from "../../components/Tags";
import UserInfoBlock from "../../components/UserInfoBlock";
import RadioSettings from "../../components/Selector";
import { approveRadios, blockReasonSelect } from "../../constants";
import { IOwner } from "../../models/IOwner";
import { IExplorer } from "../../models/IExplorers";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onAccept: (status?: "Approved" | "Declined", reason?: string) => void;
  user: IOwner | IExplorer;
}
const ApproveModal: FC<Props> = ({ isOpen, setIsOpen, onAccept, user }): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [approve, setApprove] = useState("approveUser"); //approveUser
  const [otherReason, setOtherReason] = useState<string>("");

  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };
  useEffect(() => {
    const contentWrapper = document.querySelector(".wrapper__body__content");
    if (isOpen) {
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.marginBottom = "0px";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.padding = "0px";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.overflowX = "hidden";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.marginBottom = "50px";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.padding = "30px";
    }
  }, [isOpen]);

  const confirmHandler = () => {
    const type = approve === "approveUser" ? "Approved" : "Declined";
    onAccept(type, blockReason === "Other" ? otherReason : blockReason);
    setIsOpen(false);
  };

  let revenue;
  //@ts-ignore
  if (!user?.business?.[0]?.revenue) {
    revenue = "";
    //@ts-ignore
  } else if (user?.business?.[0]?.revenue && user?.business?.[0]?.revenue?.min === user?.business?.[0]?.revenue?.max) {
    //@ts-ignore
    revenue = `${user?.business?.[0]?.revenue?.min}k +`;
  } else {
    revenue = `${
      //@ts-ignore
      user?.business?.[0]?.revenue?.min > 0
        ? //@ts-ignore
          user?.business?.[0]?.revenue?.min + "k"
        : //@ts-ignore
          user?.business?.[0]?.revenue?.min || 0
      //@ts-ignore
    } - ${user?.business?.[0]?.revenue?.max || 100}k`;
  }
  //@ts-ignore
  let number =
    //@ts-ignore
    typeof user?.business?.[0]?.employees?.min !== "undefined"
      ? //@ts-ignore
        user?.business?.[0]?.employees?.min === user?.business?.[0]?.employees?.max
        ? //@ts-ignore
          `${user?.business?.[0]?.employees?.min}+`
        : //@ts-ignore
        typeof user?.business?.[0]?.employees?.min === "number" &&
          //@ts-ignore
          typeof user?.business?.[0]?.employees?.max === "number"
        ? //@ts-ignore
          `${user?.business?.[0]?.employees?.min} - ${user?.business?.[0]?.employees?.max}`
        : ""
      : "";
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>User Verification</h5> <CloseIcon onClick={() => setIsOpen(false)} className={styles.closeBtn} />
        </div>
        <div className={styles.modalBody}>
          <UserInfoBlock
            item={{
              name: `${user.firstName} ${user.lastName}`,
              image: user.avatar || null,
              location: `${user.location.city} ${user.location.state ? ", " + user.location.state : ""}`,
              lookingFor: "Employment Opportunities",
              //@ts-ignore
              phone: user?.phone || user?.business?.[0]?.phone || "-",
              email: user.email,
              //@ts-ignore
              bussinessName: user?.business?.[0]?.name || "-",
              //@ts-ignore

              businessSector: user?.business?.[0]?.sector || "-",

              countEmployees: number || "-",
              //@ts-ignore
              revenue: revenue || "-",
              //@ts-ignore

              industry: user.industry || user?.business?.[0]?.industry || "-",
            }}
          />
          <div className={styles.approveBody}>
            <div className={styles.approveSection}>
              <div className={styles.radio}>
                <RadioSettings options={approveRadios} name="approve" value={approve} onChange={setApprove} />
                {/* <RadioSettings
                  options={[{ label: "Reject photo", value: "rejectPhoto" }]}
                  name="approve"
                  value={approve}
                  onChange={setApprove}
                />{" "} */}
                {/* approveRadios */}
              </div>
              {approve === "rejectPhoto" && (
                <div className={styles.subRadio}>
                  <p>Reason of rejection:</p>
                  <RadioSettings
                    options={blockReasonSelect}
                    name="reason"
                    value={blockReason}
                    onChange={setBlockReason}
                  />
                  {blockReason === "Other" && (
                    <Textarea
                      placeholder="Message"
                      value={otherReason}
                      onChange={onChangeOtherReason}
                      maxLength={240}
                      style={{ minHeight: "164px", width: "91%" }}
                    />
                  )}
                </div>
              )}
            </div>
            <Button title="Confirm" onClick={confirmHandler} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApproveModal;
