import { FunctionComponent, ReactNode, SVGProps, useState } from "react";
import Navbar from "../Navbar";
import styles from "./styles.module.scss";
import { navBarItems } from "../../constants";
import ContentWrapper from "./ContentWrapper";
import { SmallRightIcon } from "../../assets";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import BlockUser from "../../modals/BlockUser";
import Delete from "../../modals/Delete";
import { Block } from "../../models/ICommon";
import { getUser } from "../../utils/tokenStorage";
interface Props {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  isLoadingDelete?: boolean;
  onDeleteEmployer?: () => Promise<void>;
  onBlock?: (reason: string, type: "block" | "unblock") => Promise<void>;
  userId?: string;
  isBlocked?: Block | undefined;
}

export default function Layout({
  children,
  title,
  subtitle,
  isLoadingDelete,
  onDeleteEmployer,
  onBlock,
  userId,
  isBlocked,
}: Props) {
  const navigate = useNavigate();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowBlockModal, setIsShowBlockModal] = useState<boolean>(false);
  const me = getUser();
  return (
    <>
      {!!subtitle && !!onDeleteEmployer && !!onBlock && !!userId && (
        <>
          <BlockUser
            title={isBlocked?.isBlocked ? "Unblock user" : "Block user"}
            isBlocked={isBlocked as Block}
            isLoading={isLoadingDelete || false}
            isOpen={isShowBlockModal}
            setIsOpen={setIsShowBlockModal}
            onBlock={onBlock}
            type="block"
            userId={userId}
          />
          <Delete
            title="Delete user"
            onDelete={onDeleteEmployer}
            isLoading={isLoadingDelete || false}
            isOpen={isShowDeleteModal}
            setIsOpen={setIsShowDeleteModal}
            type="delete"
            userId={userId}
          />
        </>
      )}

      <div className={styles.container}>
        <div className={styles.layoutWrapper}>
          <Navbar title={"LDX ADMIN"} navItems={navBarItems} />
          <div className={styles.divider}></div>
          <div className={styles.body}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {subtitle && (
              <div className={styles.subPage}>
                <>
                  <div onClick={() => navigate(-1)} className={styles.subtitle}>
                    <SmallRightIcon className={styles.svg} />
                    <h3>{subtitle}</h3>
                  </div>
                  {me?.role !== "manager" && typeof userId === "string" && (
                    <div className={styles.btnWrapper}>
                      <Button
                        title={isBlocked?.isBlocked ? "Unblock" : "Block"}
                        styleType="outlined"
                        color="#000000"
                        onClick={() => {
                          if (isBlocked?.isBlocked) {
                            onBlock?.(isBlocked.reason, "unblock");
                          } else setIsShowBlockModal(true);
                        }}
                        style={{
                          border: "none",
                          width: "93px",
                          background: "#E4E4E4",
                          borderRadius: "16px",
                          color: "black",
                        }}
                      />
                      <Button
                        title={"Delete"}
                        styleType="filled"
                        onClick={() => setIsShowDeleteModal(true)}
                        loading={isLoadingDelete}
                        style={{ border: "none", width: "93px", background: "#FF3C3C", borderRadius: "16px" }}
                      />
                    </div>
                  )}
                </>
              </div>
            )}
            <ContentWrapper refMark="wrapper__body__content">{children}</ContentWrapper>
          </div>
        </div>
      </div>
    </>
  );
}
