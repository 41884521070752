import { ChangeEvent, FC, useState, useEffect, useRef } from "react";
import { PulseLoader } from "react-spinners";
import PlaceholderAvatar from "./../../../assets/images/avatar.png";
import styles from "./styles.module.scss";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Button from "../../../components/Button";
import { editOwner, getOneOwner } from "../../../services/businessOwners.service";
import { IOwner } from "../../../models/IOwner";
import { useParams } from "react-router-dom";
import GoogleInput from "../../../components/Input/GoogleInput";
import { toast } from "react-hot-toast";
import { uploadFile } from "../../../services/file.service";
import { getLinkForUploadImage } from "../../../services/auth.service";
import { toastError } from "../../../constants";
import { IExplorer } from "../../../models/IExplorers";
import AgreementModal from "../../../modals/Agreement";
import { getUser } from "../../../utils/tokenStorage";
import GoogleInputWithAddress from "../../../components/Input/GoogleInputWithAddress";
const AccountDetails = ({
  owner,
  onGetOwner,
  id,
}: {
  owner?: IOwner | undefined;
  onGetOwner?: (value: string) => void;
  id?: string;
}) => {
  const [firstName, setFirstName] = useState(owner?.firstName || "");
  const [lastName, setLastName] = useState(owner?.lastName || "");
  const [location, setLocation] = useState<{ address: string; city: string; state?: string; country: string }>(
    owner?.location || { address: "", city: "", state: "", country: "" },
  );
  const [gender, setGender] = useState<string>(owner?.gender || "Female");
  const [mobilityStatus, setMobilityStatus] = useState(owner?.mobilityStatus || "Lviv");
  const [isLoading, setIsLoading] = useState(false);
  const [age, setAge] = useState(`${owner?.age}` || "");
  const [avatar, setAvatar] = useState<string>(owner?.avatar || "");
  const [avatarFile, setAvatarFile] = useState<ArrayBuffer | null | undefined | string | any>();
  const [avatarFileName, setAvatarFileName] = useState<string>("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [commonError, setCommonError] = useState("");
  const [isEmptyLocation, setIsEmptyLocation] = useState(false);
  const [locationSecondary, setLocationSecondary] = useState(owner?.transientLocation || "");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isAgreeModal, setIsAgreeModal] = useState(false);

  const saveRef = useRef<HTMLDivElement>(null);

  const me = getUser();

  function validateFirstName(firstName: string) {
    if (firstName.length === 0) {
      return "First name is required";
    }
    return true;
  }

  function validatelastName(lastName: string) {
    if (lastName.length === 0) {
      return "Last name is required";
    }
    return true;
  }

  function validateAge(age: string) {
    const numericRegex = /^[0-9]+$/;
    if (!numericRegex.test(age)) {
      return "Age must be a valid number";
    }

    const ageNumber = parseInt(age, 10);

    if (ageNumber < 0) {
      return "Age must be greater than or equal to zero";
    }

    return true;
  }

  function validateLocation(location: { city: string; country: string }) {
    if (location.city.length === 0 && location.country.length === 0) {
      return "Location is required";
    }
    return true;
  }

  const inputFile = useRef<HTMLInputElement>(null);
  const showOpenFileDialog = () => {
    if (me?.role === "manager") {
      return;
    }
    inputFile.current?.click();
  };
  const onDeleteAvatar = () => {
    if (me?.role === "manager") {
      return;
    }
    setAvatarFile("");
    setAvatar("");
    setAvatarFileName("");
    setButtonDisabled(false);
  };

  const onSave = async () => {
    try {
      const firstNameError = validateFirstName(firstName);
      if (typeof firstNameError === "string") {
        setFirstNameError(firstNameError);
        return;
      }
      const lastNameError = validatelastName(lastName);
      if (typeof lastNameError === "string") {
        setLastNameError(lastNameError);
        return;
      }

      const locationError = validateLocation(location);
      if (typeof locationError === "string") {
        setLocationError(locationError);
        return;
      }

      if (isEmptyLocation) {
        setLocationError("Location is required");
        return;
      }

      const ageError = validateAge(age);
      if (typeof ageError === "string") {
        setAgeError(ageError);
        return;
      }

      setIsLoading(true);
      let imageLink = "";
      if (avatarFile) {
        //@ts-ignore
        const resLinkImg = await getLinkForUploadImage(avatarFileName);
        imageLink = resLinkImg?.data?.links[0].downloadUrl;
        let blob = await fetch(avatar).then((r) => r.blob());
        const resUploadImg = await uploadFile(blob, resLinkImg?.data?.links[0].uploadUrl);
      }
      const { data } = await editOwner(
        id || "",
        owner?.email || "",
        firstName,
        lastName,
        imageLink || avatar || null,
        gender,
        +age,
        mobilityStatus,
        location,
        locationSecondary,
      );
      if (data?.success) {
        onGetOwner?.(id || "");
        setFirstNameError("");
        setLastNameError("");
        setAgeError("");
        setLocationError("");
        setCommonError("");
      }
      setIsLoading(false);
      setButtonDisabled(true);
      toast.success("User has been successfully updated");
    } catch (error: any) {
      setIsLoading(false);
      if (!error?.success) {
        setCommonError(error.message);
        toast.error(toastError);
      }
    }
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (me?.role === "manager") {
      return;
    }
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setAvatar(URL.createObjectURL(e.target.files[0]));
      let file = e.target.files[0];
      setAvatarFile(e.target.files[0]);

      setAvatarFileName(file?.name);
      setButtonDisabled(false);
    } catch (error) {}
  };

  const onSelectLocation = (location: { address: string; city: string; state?: string; country: string }) => {
    setLocation(location);
    setLocationError("");
    setButtonDisabled(false);
  };

  const onSelectLocationSecodnary = (location: { address: string; city: string; state?: string; country: string }) => {
    setLocationSecondary(location.address);
    setButtonDisabled(false);
  };
  useEffect(() => {
    if (owner?.location?.city || owner?.location.country) {
      setIsEmptyLocation(false);
    }
  }, [owner]);

  useEffect(() => {
    setButtonDisabled(true);
    const locationInput: any = document.querySelector(".pac-target-input");
    setIsEmptyLocation(true);
    saveRef.current?.addEventListener("click", (e) => {
      if (locationInput && locationInput.value.length === 0) {
        setIsEmptyLocation(true);
      } else {
        setIsEmptyLocation(false);
      }
    });
    return () => {
      saveRef.current?.addEventListener("click", (e) => {
        if (locationInput && locationInput.value.length === 0) {
          setIsEmptyLocation(true);
        } else {
          setIsEmptyLocation(false);
        }
      });
    };
  }, []);

  return (
    <>
      <AgreementModal
        isLoading={isLoading}
        isOpen={isAgreeModal}
        onAccept={onSave}
        setIsOpen={setIsAgreeModal}
        title="Confirmation"
      />
      {isLoading && !owner ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#236BFE"} />
        </div>
      ) : (
        <>
          <div className={styles.profileSection}>
            <div className={styles.profileBody}>
              <div className={styles.avatarBody}>
                {avatar && <img src={avatar} alt={"avatar"} className={styles.avatar} />}
                {!avatar && (
                  <img
                    // src={employer?.image ? employer?.image : PlaceholderAvatar}
                    src={PlaceholderAvatar}
                    alt="avatar"
                    className={styles.avatar}
                  />
                )}
              </div>
              {me?.role !== "manager" && (
                <div className={styles.avatarInfoBody}>
                  <div>
                    <input
                      style={{ display: "none" }}
                      ref={inputFile}
                      type={"file"}
                      accept="image/*"
                      onChange={onSelectFile}
                      onClick={(e: any) => (e.target.value = null)}
                      className={styles.bootcampsSettingsUploadImgBtn}
                    />
                    <span onClick={showOpenFileDialog}>Change Avatar</span>
                  </div>
                  <span className={styles.deleteBody} onClick={onDeleteAvatar}>
                    Delete
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={styles.dividerX}></div>
          <div className={styles.formSection}>
            <Input
              label="First Name"
              disabled={me?.role === "manager"}
              placeholder="Write your first name"
              style={{ padding: "12px 16px" }}
              value={firstName}
              error={firstNameError}
              onChange={(e) => {
                setFirstName(e.target.value);
                setFirstNameError("");
                setButtonDisabled(false);
              }}
            />
            <Input
              label="Last Name"
              disabled={me?.role === "manager"}
              placeholder="Write your last name"
              style={{ padding: "12px 16px" }}
              value={lastName}
              error={lastNameError}
              onChange={(e) => {
                setLastName(e.target.value);
                setLastNameError("");
                setButtonDisabled(false);
              }}
            />
            <Input label="Email" style={{ padding: "12px 16px" }} value={owner?.email} disabled />
            <GoogleInputWithAddress
              disabled={me?.role === "manager"}
              label="Location"
              error={locationError}
              placeholder="Write your location"
              value={
                location.address
                  ? location.address
                  : location.city && location.country
                  ? `${location.city},${
                      location.state || (location.state && location.state?.length > 0) ? `${location.state}, ` : ""
                    } ${location.country}`
                  : ""
              }
              onSelect={onSelectLocation}
            />
            <GoogleInputWithAddress
              disabled={me?.role === "manager"}
              label="Secondary Address"
              placeholder="Your Secondary Address"
              value={locationSecondary}
              onSelect={onSelectLocationSecodnary}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setLocationSecondary("");
                  setButtonDisabled(false);
                }
              }}
            />
            <Select
              disabled={me?.role === "manager"}
              label="Gender"
              options={["Female", "Male"]}
              value={gender}
              onChange={(option) => {
                setGender(option);
                setButtonDisabled(false);
              }}
            />
            <Input
              label="Age"
              disabled={me?.role === "manager"}
              placeholder="Write your age"
              style={{ padding: "12px 16px" }}
              value={age}
              error={ageError}
              onChange={(e) => {
                setAge(e.target.value);
                setAgeError("");
                setButtonDisabled(false);
              }}
              type="string"
            />
            <Select
              label="Mobility Status"
              disabled={me?.role === "manager"}
              options={["Transient", "Permanently based"]}
              value={mobilityStatus}
              onChange={(option) => {
                setMobilityStatus(option);

                setButtonDisabled(false);
              }}
            />
          </div>
          {commonError && <p className={styles.commonError}>{commonError}</p>}
          {me?.role !== "manager" && (
            <div ref={saveRef} className={styles.button}>
              <Button title="Save" onClick={() => setIsAgreeModal(true)} disabled={buttonDisabled || isLoading} />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default AccountDetails;
