import { FunctionComponent, SVGProps, useContext, useState } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import ContentWrapper from "../Layout/ContentWrapper";
import profileImage from "../../assets/images/avatar.png";
import { motion } from "framer-motion";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChartIcon,
  CheckCircleIcon,
  FeedbackIcon,
  LogoutIcon,
  SearchVIcon,
  StoreIcon,
  UserIcon,
  UsersCandidatesIcon,
} from "../../assets";
import Skeleton from "react-loading-skeleton";

import { getUser, removeAccessToken, removeRefreshToken } from "../../utils/tokenStorage";
import { logout } from "../../services/auth.service";
import { NavLink } from "react-router-dom";
import Logo from "./../../assets/images/logo.png";
import { NumbersContext } from "../VerificationCounterContext/VerificationProvider";
import Tag from "../Tags";

interface Props {
  title: string;
  navItems: { logo: FunctionComponent<SVGProps<SVGSVGElement>>; description: string; path: string }[];
}

export default function Navbar({ title, navItems }: Props) {
  const [isOpenProfileSettings, setIsOpenProfileSettings] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const me = getUser();
  const navigationTotalCount = useContext(NumbersContext);

  const onLogOut = () => {
    logout();
    removeAccessToken();
    removeRefreshToken();
    navigate("/signIn");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.navOutside}>
        <nav className={styles.navWrapper}>
          <div>
            <h2 className={styles.title}>
              <div>
                <img src={Logo} />
              </div>
              {title}
            </h2>
            <NavLink
              to="/statistics"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <ChartIcon />
              <span>Statistics</span>
            </NavLink>
            <NavLink
              to="/business-owners"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <StoreIcon />
              <span>Business Owners</span>
            </NavLink>
            <NavLink
              to="/business-explorers"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <SearchVIcon />
              <span>Business Explorers</span>
            </NavLink>
            <NavLink
              to="/verification"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <CheckCircleIcon />
              <span>Verification</span>
              {navigationTotalCount.number > 0 && (
                <div className={styles.tag}>
                  <Tag label={`${navigationTotalCount.number}`} isSelected={location.pathname === "/verification"} />
                </div>
              )}
            </NavLink>
            <NavLink
              to="/feedback"
              className={({ isActive, isPending }) =>
                clsx({
                  [styles.navButtonSelected]: isActive,
                  [styles.navButton]: !isActive,
                })
              }
            >
              <FeedbackIcon />
              <span>Feedback</span>
            </NavLink>
            {me?.role === "admin" && (
              <NavLink
                to="/staff-management"
                className={({ isActive, isPending }) =>
                  clsx({
                    [styles.navButtonSelected]: isActive,
                    [styles.navButton]: !isActive,
                  })
                }
              >
                <UsersCandidatesIcon />
                <span>Staff Management</span>
              </NavLink>
            )}
          </div>
        </nav>
        <div onClick={() => setIsOpenProfileSettings((current) => !current)} className={styles.profileWrapper}>
          <motion.div
            animate={isOpenProfileSettings ? "open" : "closed"}
            variants={{
              open: { opacity: 1, visibility: "visible", y: "-55px", position: "absolute" },
              closed: { opacity: 0, visibility: "hidden", y: "50%", position: "absolute" },
            }}
            className={styles.logoutWrapper}
          >
            <ContentWrapper className={{ padding: "10px", marginBottom: "0" }}>
              <>
                <div onClick={onLogOut} className={styles.subNav}>
                  <LogoutIcon className={styles.svg} /> <span>Logout</span>
                </div>
              </>
            </ContentWrapper>
          </motion.div>
          <div className={styles.image}>
            {!imageLoaded && <Skeleton width={300} height={200} />}

            <img src={me?.avatar ? me?.avatar : profileImage} alt="profile image" onLoad={() => setImageLoaded(true)} />
          </div>
          <div className={styles.profileBody}>
            <span
              className={clsx(styles.profileTitle, {
                [styles.isLargeName]: `${me?.firstName} ${me?.lastName}`.length > 12,
              })}
            >
              {me?.firstName} {me?.lastName}
            </span>
            <p className={clsx({ [styles.organizatiorManager]: me?.role === "manager" })}>
              {me?.role === "admin" ? "Super Admin" : me?.role === "manager" ? "Organization Manager" : "Moderator"}
            </p>
          </div>
          <div className={styles.arrowWrapper}>
            <ArrowUpIcon width={"20px"} height="15px" />
            <ArrowDownIcon width={"20px"} height="15px" />
          </div>
        </div>
      </div>
    </div>
  );
}
