import { useState } from "react";
import Layout from "../../components/Layout";
import styles from "./styles.module.scss";
import RadioButtons from "../../components/Radio";
import { toastError, verificationTabs } from "../../constants";
import BusinessOwnersVerification from "./BusinessOwnersVerification";
import BusinessExplorersVerification from "./BusinessExplorersVerification";
import Button from "../../components/Button";
import { CheckIcon } from "../../assets";
import AgreementModal from "../../modals/Agreement";
import { verifyUserExplorer, verifyUserOwner } from "../../services/verification.service";
import { toast } from "react-hot-toast";
import { getUser } from "../../utils/tokenStorage";
const limit = 20;

export default function Verification() {
  const [currentTab, setCurrentTab] = useState("BusinessOwners");
  const [verifyUsers, setVerifyUsers] = useState<string[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMultiple, setIsmultiple] = useState(false);
  const [typePage, setTypePage] = useState<"owner" | "explorer">("owner");
  const [tagOwner, setTagOwner] = useState(0);
  const [tagExplorer, setTagExplorer] = useState(0);

  const me = getUser();

  const verify = async (verifyData?: { id: string; status: "Approved" | "Declined"; reason?: string }) => {
    if (verifyUsers.length === 0 && !isMultiple && !verifyData) {
      return;
    }
    try {
      setIsLoading(true);
      if (verifyData) {
        if (typePage === "owner") {
          const formatedVerify = [
            {
              ownerId: verifyData.id,
              status: verifyData.status as "Approved",
              ...(verifyData.reason && { reason: verifyData.reason }),
            },
          ];
          const { data } = await verifyUserOwner(formatedVerify);
          if (data?.success) {
            toast.success(`User has been successfully ${verifyData.status === "Approved" ? "verified" : "declined"}`);
          }
          setIsLoading(false);
        }
        if (typePage === "explorer") {
          const formatedVerify = [
            {
              explorerId: verifyData.id,
              status: verifyData.status as "Approved",
              ...(verifyData.reason && { reason: verifyData.reason }),
            },
          ];
          const { data } = await verifyUserExplorer(formatedVerify);
          if (data?.success) {
            toast.success(`User has been successfully ${verifyData.status === "Approved" ? "verified" : "declined"}`);
          }
          setIsLoading(false);
        }
      } else if (typePage === "owner") {
        const formatedVerify = verifyUsers.map((id) => {
          return { ownerId: id, status: "Approved" as "Approved" };
        });

        const { data } = await verifyUserOwner(formatedVerify);
        if (data?.success) {
          toast.success("User has been successfully verified");
        }
        setIsLoading(false);
      } else if (typePage === "explorer") {
        const formatedVerify = verifyUsers.map((id) => {
          return { explorerId: id, status: "Approved" as "Approved" };
        });

        const { data } = await verifyUserExplorer(formatedVerify);
        if (data?.success) {
          toast.success("User has been successfully verified");
        }
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(toastError);
      setIsLoading(false);
    }
    setShowConfirmModal(false);
  };

  return (
    <>
      <AgreementModal
        isLoading={isLoading}
        isOpen={showConfirmModal}
        setIsOpen={setShowConfirmModal}
        text={`Verify ${verifyUsers.length} users?`}
        title="Verification"
        setIsmultiple={setIsmultiple}
        onAccept={verify}
      />

      <Layout title={"Verification"}>
        <div className={styles.navBody}>
          <div className={styles.headerWrapper}>
            <div className={styles.tabWrapper}>
              <RadioButtons
                style={{ letterSpacing: "0.4px" }}
                name="time"
                options={verificationTabs}
                value={currentTab}
                onChange={setCurrentTab}
                tag={tagOwner && tagExplorer ? [tagOwner, tagExplorer] : undefined}
              />
            </div>
            {verifyUsers.length > 0 && me?.role !== "manager" && (
              <Button
                title={`Verify (${verifyUsers.length})`}
                styleType="filled"
                onClick={() => setShowConfirmModal(true)}
                loading={isLoading}
                icon={CheckIcon}
                style={{
                  border: "none",
                  width: "120px",
                  height: "43px",
                  background: "#63CD5A",
                  borderRadius: "16px",
                }}
              />
            )}
          </div>
          {currentTab === "BusinessOwners"
            ? !isLoading && (
                <BusinessOwnersVerification
                  setVerifyUsers={setVerifyUsers}
                  setTypePage={setTypePage}
                  isLoading={isLoading}
                  handleVerify={verify}
                  setTagExplorer={setTagExplorer}
                  setTagOwner={setTagOwner}
                />
              )
            : !isLoading && (
                <BusinessExplorersVerification
                  setVerifyUsers={setVerifyUsers}
                  setTypePage={setTypePage}
                  isLoading={isLoading}
                  handleVerify={verify}
                  setTagExplorer={setTagExplorer}
                  setTagOwner={setTagOwner}
                />
              )}
        </div>
      </Layout>
    </>
  );
}
