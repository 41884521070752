import axiosInstance from "../apis";
import { AxiosResponse } from "axios";
type ISort =
  | { firstName: 1 | -1 }
  | { lastName: 1 | -1 }
  | { "location.city": 1 | -1 }
  | { phone: 1 | -1 }
  | { email: 1 | -1 }
  | { createdAt: 1 | -1 };
// type IFilter =
// | { gender: "Male" | "Female" }
// | { "location.country": string }
// | { "location.city": string }
// | { "location.country": string }
interface IFilter {
  // | undefined;
  gender: string;
  location: {
    country: string;
    state: string;
    city: string;
  };
  age: {
    minAge: number;
    maxAge: number;
  };
  mobilityStatus: string[];
  industry: string[];
  currentNeeds: string[];
}
export const getAllBusinessExolorers = async (
  page: number,
  limit: number = 20,
  search: string,
  sort: ISort | {},
  filter?: IFilter,
): Promise<AxiosResponse> => {
  const body = {
    search: search || null,
    sort,
    filter: filter || {},
  };

  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`explorer/all?page=${page}&limit=${limit}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getOneExplorer = async (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`explorer?explorerId=${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const editExplorer = (
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  avatar: null | string,
  gender: "Male" | "Female",
  aboutMe: string,
  age: number,
  mobilityStatus: string,
  location: {
    country: string;
    state?: string;
    city: string;
  },
  phone: string,
  currentNeeds: string[],
  industry: string[],
  transientLocation?: string,
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      phone,
      gender,
      firstName,
      lastName,
      description: aboutMe,
      age,
      mobilityStatus,
      location,
      currentNeeds,
      avatar: avatar ? avatar : null,
      industry,
      transientLocation,
    };
    axiosInstance
      .post(`explorer?explorerId=${id}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const blockUserExplorers = async (
  id: string,
  reason: string,
  type: "block" | "unblock",
): Promise<AxiosResponse> => {
  const body = {
    explorerId: id,
    reason,
  };
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`explorer/${type}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const deleteUserExplorer = async (id: string | string[]): Promise<AxiosResponse> => {
  let idList;
  if (Array.isArray(id)) {
    idList = id;
  } else idList = [id];
  const body = {
    idList,
  };
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`explorer/delete`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
