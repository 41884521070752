import { AxiosResponse } from "axios";
import axiosInstance from "../apis";
import { getRefreshToken } from "../utils/tokenStorage";

export const login = (email: string, password: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      email,
      password,
    };

    axiosInstance
      .post("admin/login", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const logout = (): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const refreshToken: any = getRefreshToken();
    const body = {
      token: refreshToken,
    };
    axiosInstance
      .patch("admin/logout", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getNewAccessToken = (): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const refreshToken = getRefreshToken();
    const body = {
      token: refreshToken,
    };
    axiosInstance
      .post("admin/refresh", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getLinkForUploadImage = (fileName: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      fileNames: [fileName],
    };
    axiosInstance
      .post("file/upload", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
