import { useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { ArrowDownIcon } from "../../assets";

type CustomSelectorProps = {
  options: string[];
  label: string;
  value: string[];
  onChange: (options: string[]) => void;
  disabled?: boolean;
};

export default function SimpleMultiSelect({ options, onChange, label, value, disabled }: CustomSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  function handleOptionClick(option: string) {
    const index = value.indexOf(option);
    if (index !== -1) {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    } else {
      onChange([...value, option]);
    }
  }

  const LastElem = useMemo(() => options[options.length - 1], [options]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.wrapper} ref={dropdownRef}>
      <span className={styles.label}>{label}</span>
      <div className={styles.selectBody}>
        <div
          className={clsx({
            [styles.selectHeaderSimple]: !isOpen,
            [styles.selectHeaderOpenSimple]: isOpen,
            [styles.disabled]: disabled,
          })}
          onClick={() => !disabled && setIsOpen((current) => !current)}
        >
          <span>{value.join(", ")}</span>
          {!disabled && (
            <ArrowDownIcon width={"18px"} className={clsx({ [styles.arrowOpen]: isOpen, [styles.arrow]: !isOpen })} />
          )}
        </div>
        <div
          className={clsx({
            [styles.optionOpen]: isOpen,
            [styles.option]: !isOpen,
            [styles.lastOption]: options[options.length - 1] === LastElem && isOpen,
          })}
        >
          {options.map((option) => (
            <div
              key={option}
              style={{ margin: "10px 0 10px 0" }}
              className={clsx({ [styles.selected]: value.includes(option) }, styles.optionItem)}
              onClick={() => {
                handleOptionClick(option);
              }}
            >
              <span>{option}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
