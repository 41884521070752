import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../../assets/images/avatar.png";
import { PulseLoader } from "react-spinners";
import AccountDetails from "./AccountDetails";
import BusinessDetails from "./BusinessDetails";
import Posts from "./Posts";
import { toast } from "react-hot-toast";
import Layout from "../../../components/Layout";
import RadioButtons from "../../../components/Radio";
import { toastError, profileTabs } from "../../../constants";
import { IOwner } from "../../../models/IOwner";
import { getOneOwner, deleteUSer, blockUser } from "../../../services/businessOwners.service";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
interface Props {}

const OwnerVerificationProfile: FC<Props> = (): JSX.Element => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const [currentTab, setCurrentTab] = useState<"Posts" | "BusinessDetails" | "AccountDetails">("AccountDetails");
  const [search, setSearch] = useState<string>("");
  const [owner, setOwner] = useState<IOwner>(); // <IEmployer>
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const onGetOwner = async (id: string) => {
    try {
      setIsLoading(true);

      const { data } = await getOneOwner(id);
      if (data?.success) {
        setOwner(data?.owner[0]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      onGetOwner(id);
    }
  }, [id]);

  const onDeleteEmployer = async () => {
    try {
      setIsLoading(true);
      const { data } = await deleteUSer(id || "0");
      if (data?.success) {
        setIsLoading(false);
        navigate(-1);
        toast.success("User has been successfully deleted");
      }
    } catch (error) {
      toast.error(toastError);
      setIsLoading(false);
    }
  };

  const onBlock = async (reason: string, type: "block" | "unblock") => {
    try {
      setIsLoading(true);
      const { data } = await blockUser(owner?.id || "", reason || "", type);
      if (data?.success) {
        setIsLoading(false);
        onGetOwner(owner?.id || "");
        toast.success(type === "block" ? "User has been blocked" : "User has been unblocked ");
      }
    } catch (error) {
      toast.error(toastError);
      setIsLoading(false);
    }
  };

  return (
    <Layout
      subtitle={"Owner's Profile"}
      // onDeleteEmployer={onDeleteEmployer}
      // onBlock={onBlock}
      // isLoadingDelete={isLoadingDelete}
      // isBlocked={owner?.block}
      // userId={owner?.id || "0"}
    >
      <>
        <div className={styles.wrapper}>
          {isLoading && !owner ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#236BFE"} />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <div className={styles.info}>
                  <div style={{ position: "relative" }} className={styles.avatar}>
                    {!imageLoaded && <Skeleton style={{ position: "absolute" }} width={300} height={200} />}

                    <img
                      src={owner?.avatar ? owner?.avatar : PlaceholderAvatar}
                      alt="avatar"
                      onLoad={() => setImageLoaded(true)}
                    />
                  </div>
                  <div className={styles.nameWrapper}>
                    <p className={styles.name}>{`${owner?.firstName} ${owner?.lastName}`}</p>
                    <p className={styles.location}>{`${owner?.location.city} ${
                      owner?.location.state ? `, ${owner?.location.state}` : ""
                    }`}</p>
                  </div>
                </div>
                <div className={styles.socialbtnWrapper}></div>
              </div>
              <div className={styles.tabWrapper}>
                <RadioButtons
                  name="time"
                  options={profileTabs}
                  value={currentTab}
                  onChange={setCurrentTab as (value: string) => void}
                />
              </div>
              {currentTab === "AccountDetails" ? (
                <AccountDetails owner={owner} onGetOwner={onGetOwner} id={id} />
              ) : currentTab === "BusinessDetails" ? (
                <BusinessDetails owner={owner} onGetOwner={onGetOwner} id={id || ""} />
              ) : (
                <Posts />
              )}
            </>
          )}
        </div>
      </>
    </Layout>
  );
};

export default OwnerVerificationProfile;
