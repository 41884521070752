import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Cell, ResponsiveContainer } from "recharts";
import { PulseLoader } from "react-spinners";
import { getStatistics } from "../../services/statistics.service";

function ControlsBtn({ isActive = false, onClick = () => {}, title = "" }) {
  return (
    <div onClick={onClick} className={isActive ? styles.activeControlsBtn : styles.contorlsBtn}>
      <div className={isActive ? styles.activeControlsBtnText : styles.controlsBtnText}>{title}</div>
    </div>
  );
}

const CustomTooltip = (props: any) => {
  return (
    <div className={styles.tooltipWrapper}>
      <p className={styles.data}>{props?.payload[0]?.payload?.users}</p>
      <p className={styles.label}>{props?.label}</p>
    </div>
  );
};

function Charts({
  setCurrentTime,
  data: dataServer,
}: {
  setCurrentTime: (value: "week" | "year" | "month") => void,
  data: ExplorerPeriod[] | undefined,
}) {
  const [activeType, setActiveType] = useState(1);
  const [focusBar, setFocusBar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [barGraphData, setBarGraphData] = useState({});
  const [toolTipYPosition, setToolTipYPosition] = useState({});
  const [data, setData] = useState([]);
  const controls = [{ title: "Week" }, { title: "Month" }, { title: "Year" }];

  const onGetStatistics = async (period: "week" | "year" | "month") => {
    try {
      setIsLoading(true);
      const { data } = await getStatistics(period.title.toLowerCase());

      if (data?.success) {
        let periodData: any[] = [];
        if (period.title === "Month" || period.title === "Week") {
          periodData = data.totalPeriod.map((item) => {
            const period = item.name.split("-");
            return { ...item, name: `${period[1]}-${period[2]}` };
          });
        } else periodData = data.totalPeriod;
        setData(periodData);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    onGetStatistics("month");
  }, []);
  useEffect(() => {
    onGetStatistics(controls[activeType]);
  }, [activeType]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.chartsControls}>
        {controls.map((item, index) => {
          return (
            <ControlsBtn
              isActive={index === activeType}
              onClick={() => {
                setActiveType(index);
                setCurrentTime(controls[index]);
              }}
              title={item.title}
              key={index}
            />
          );
        })}
      </div>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#236BFE"} />
        </div>
      ) : (
        <ResponsiveContainer width={"100%"} height={200}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 30,
              bottom: 5,
            }}
            barSize={20}
            onMouseMove={(state: any) => {
              if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
              } else {
                setFocusBar(null);
              }
            }}
          >
            <XAxis
              dataKey="name"
              padding={{ left: 10, right: 10 }}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 10 }}
              allowDuplicatedCategory={true}
              allowDataOverflow
            />
            <YAxis axisLine={false} tickLine={false} tickMargin={15} tick={{ fontSize: 12 }} />
            <Tooltip
              content={<CustomTooltip />}
              cursor={false}
              offset={-38}
              allowEscapeViewBox={{ x: true, y: true }}
              position={{ y: -toolTipYPosition + 150 }}
              wrapperStyle={{ outline: "none" }}
            />

            <Bar
              dataKey="users"
              fill="#5444EB"
              background={false}
              barSize={26}
              radius={6}
              allowDuplicatedCategory={true}
              isAnimationActive={true}
              label={(e: any) => {
                if (e.index === focusBar) {
                  setToolTipYPosition(e.height);
                }
                return null;
              }}
            >
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} stroke={"none"} fill={focusBar === index ? "#236BFE" : "#DEE9FF"} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default Charts;
