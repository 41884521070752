import { AxiosResponse } from "axios";
import axiosInstance from "../apis";

interface TotalPeriodData {
  users: number;
  name: string;
}

export interface ExplorersPercent {
  success: boolean;
  totalOwners: number;
  totalExplorers: number;
  totalUsers: number;
  totalPeriod: TotalPeriodData[];
  ownersToday: number;
  explorersToday: number;
  usersToday: number;
  ownersPercent: number;
  explorersPercent: number;
  usersPercent: number;
  totalIndustries: number;
  totalPosts: number;
  postsPercent: number;
  totalEducation: number;
  educationPercent: number;
  totalConstruction: number;
  constructionPercent: number;
  totalDesign: number;
  designPercent: number;
  totalCorporateServices: number;
  corporateServicesPercent: number;
  totalRetail: number;
  retailPercent: number;
  totalEnergyMining: number;
  energyMiningPercent: number;
  totalManufacturing: number;
  manufacturingPercent: number;
  totalFinance: number;
  financePercent: number;
  totalOwnersNV: number;
  totalExplorersNV: number;
}

export const getStatistics = async (period: "week" | "month" | "year"): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`statistic?period=${period}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
