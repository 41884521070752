import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../assets";
import PlaceholderAvatar from "./../../assets/images/avatar.png";
import styles from "./styles.module.scss";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "../../components/Button";
import { uploadFile } from "../../services/file.service";
import { createStaff, editStaff } from "../../services/staff.service";
import { getLinkForUploadImage } from "../../services/auth.service";
import { IStaff } from "../../models/IStaff";
import { toastError } from "../../constants";
import { toast } from "react-hot-toast";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onGetAllStaff: (page: number, limit: number) => void;
  item: IStaff;
}
const EditManagerModal: FC<Props> = ({ isOpen, setIsOpen, onGetAllStaff, item }): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [role, setRole] = useState<string>("Moderator");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [commonError, setCommonError] = useState("");
  const [avatar, setAvatar] = useState<string>("");
  const [avatarFile, setAvatarFile] = useState<ArrayBuffer | null | undefined | string | any>();
  const [avatarFileName, setAvatarFileName] = useState<string>("");
  const inputFile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setAvatar(item.avatar || "");
    setFirstName(item.firstName);
    setLastName(item.lastName);
    setEmail(item.email);
    setRole(item.role === "manager" ? "Organization Manager" : "Moderator");
  }, [item]);

  const showOpenFileDialog = () => {
    inputFile.current?.click();
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setAvatar(URL.createObjectURL(e.target.files[0]));
      let file = e.target.files[0];
      setAvatarFile(e.target.files[0]);

      setAvatarFileName(file?.name);
    } catch (error) {}
  };

  function validatePassword(password: string) {
    if (password.length < 8 || password.indexOf(" ") !== -1) {
      return "Password must be at least 8 characters long and can't contain spaces.";
    }

    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[?.,!_\-~$%+=@#^&]).+$/;

    if (!regex.test(password)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one symbol, and one number.";
    }
    return true;
  }

  function validateEmail(email: string) {
    if (email.length > 80) {
      return "Email address must be at most 80 characters long.";
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regex.test(email)) {
      return "Please enter a valid email address.";
    }

    return true;
  }
  const onChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setFirstNameError("");
    setCommonError("");
  };

  const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    setLastNameError("");
    setCommonError("");
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError("");
    setCommonError("");
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);

    setPasswordError("");
    setCommonError("");
  };
  const onDeleteAvatar = () => {
    setAvatarFile("");
    setAvatar("");
    setAvatarFileName("");
  };

  const onCreate = async () => {
    try {
      if (firstName.length === 0) {
        setFirstNameError("First name is required");
        return;
      }
      if (lastName.length === 0) {
        setLastNameError("Last name is required");
        return;
      }
      const loginError = validateEmail(email);
      if (typeof loginError === "string") {
        setEmailError(loginError);
        return;
      }
      const passwordError = validatePassword(password);
      if (password.length > 0 && typeof passwordError === "string") {
        setPasswordError(passwordError);
        return;
      }
      setIsLoading(true);
      let imageLink = "";
      if (avatarFile) {
        //@ts-ignore
        const resLinkImg = await getLinkForUploadImage(avatarFileName);
        imageLink = resLinkImg?.data?.links[0].downloadUrl;
        let blob = await fetch(avatar).then((r) => r.blob());
        const resUploadImg = await uploadFile(blob, resLinkImg?.data?.links[0].uploadUrl);
      }

      let updatedPassword = password || null;

      const { data } = await editStaff(
        item.id,
        email,
        imageLink || avatar,
        firstName,
        lastName,
        updatedPassword,
        role === "Organization Manager" ? "manager" : "moderator",
      );
      if (data?.success) {
        onGetAllStaff(1, 20);
        setIsOpen(false);
        setEmailError("");
        setFirstNameError("");
        setLastNameError("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setPassword("");
        setPasswordError("");
        setAvatar("");
        setAvatarFile(undefined);
        setAvatarFileName("");
        toast.success("User has been successfully updated");
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(toastError);
      if (!error?.success) {
        setCommonError(error.message);
      }
    }
  };

  useEffect(() => {
    const contentWrapper = document.querySelector(".wrapper__body__content");
    if (isOpen) {
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.marginBottom = "0px";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.padding = "0px";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.overflowX = "hidden";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.marginBottom = "50px";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.padding = "30px";
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>Edit Manager</h5> <CloseIcon onClick={() => setIsOpen(false)} className={styles.closeBtn} />
        </div>
        <div className={styles.modalBody}>
          <div className={styles.profileSection}>
            <div className={styles.profileBody}>
              <div className={styles.avatarBody}>
                <div onClick={showOpenFileDialog} className={styles.avatarBtn}>
                  {avatar && <img src={avatar} alt={"avatar"} className={styles.avatar} />}
                  {!avatar && (
                    <img
                      // src={employer?.image ? employer?.image : PlaceholderAvatar}
                      src={PlaceholderAvatar}
                      alt="avatar"
                      className={styles.avatar}
                    />
                  )}
                </div>
              </div>
              <div className={styles.avatarInfoBody}>
                <div>
                  <input
                    style={{ display: "none" }}
                    ref={inputFile}
                    type={"file"}
                    accept="image/*"
                    onChange={onSelectFile}
                    onClick={(e: any) => (e.target.value = null)}
                    className={styles.bootcampsSettingsUploadImgBtn}
                  />
                  <span onClick={showOpenFileDialog}>Change Avatar</span>
                </div>
                <span className={styles.deleteBody} onClick={onDeleteAvatar}>
                  Delete
                </span>
              </div>
            </div>
          </div>
          <div className={styles.dividerX}></div>
          <div className={styles.form}>
            <div className={styles.names}>
              <Input
                label="First Name"
                style={{ width: "185px" }}
                value={firstName}
                onChange={onChangeFirstName}
                error={firstNameError}
              />
              <Input
                label="Last Name"
                style={{ width: "185px" }}
                value={lastName}
                onChange={onChangeLastName}
                error={lastNameError}
              />
            </div>
            <div className={styles.email}>
              <Input label="Email" value={email} onChange={onChangeEmail} error={emailError} />
            </div>
            <div className={styles.password}>
              <Input
                label="Password"
                isShow
                type="password"
                value={password}
                onChange={onChangePassword}
                error={passwordError}
              />
            </div>
            <div className={styles.role}>
              <Select label="Role" options={["Moderator", "Organization Manager"]} value={role} onChange={setRole} />
            </div>
            {commonError && <p className={styles.commonError}>{commonError}</p>}
            <Button title="Edit" onClick={onCreate} disabled={isLoading} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditManagerModal;
