import { useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { ArrowDownIcon } from "../../assets";
import Textarea from "../Textarea";

type CustomSelectorProps = {
  options: string[];
  label: string;
  value: string[];
  onChange: (options: string[]) => void;
  multiple?: boolean;
  subtitle?: string;
  setOtherFun?: (value: string) => void;
  setButtonDisabled?: (value: boolean) => void;
  disabled?: boolean;
  other?: string;
  removeOther?: boolean;
};

export default function MultiSelect({
  options,
  onChange,
  label,
  value,
  multiple = false,
  subtitle,
  setOtherFun,
  setButtonDisabled,
  disabled,
  removeOther,
  other: otherServer,
}: CustomSelectorProps) {
  const [other, setOther] = useState("");
  const [openOther, setOpenOther] = useState(false);
  const [localValues, setLocalValues] = useState([]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  function handleOptionClick(option: string) {
    const index = value.indexOf(option);
    if (index === -1) {
      if (option) {
        onChange([...value, option]);
      }
      // onChange([...value, option]);
    } else {
      onChange(value.filter((v) => v !== option));
    }
  }

  useEffect(() => {
    if (!openOther) setOtherFun?.("");
  }, [openOther]);

  useEffect(() => {
    if (otherServer && otherServer.length > 0) {
      setOther(otherServer);
      setOpenOther(true);
    }
  }, [otherServer]);

  return (
    <div className={clsx(styles.wrapper, styles.multiWrapper)} ref={dropdownRef}>
      <span className={styles.label}>{label}</span>
      <span className={styles.subtitle}>{subtitle}</span>
      <div className={styles.needWrapper}>
        {options.map((need) => {
          return (
            <div
              onClick={() => {
                !disabled && handleOptionClick(need);
              }}
              className={clsx({
                [styles.needBody]: !value.includes(need),
                [styles.needBodySelected]: value.includes(need),
                [styles.needBodyOther]: need === "Other" && openOther,
                [styles.disabled]: disabled,
              })}
            >
              {need}
            </div>
          );
        })}
        {!removeOther && (
          <div
            onClick={() => {
              !disabled && setOpenOther((current) => !current);
              !disabled && setOtherFun?.(other);
              !disabled && setButtonDisabled?.(false);
            }}
            className={clsx({
              [styles.needBody]: !openOther,
              [styles.needBodySelected]: openOther,
              [styles.disabled]: disabled,
            })}
          >
            Other
          </div>
        )}
      </div>
      {openOther && !removeOther && (
        <Textarea
          value={other}
          disabled={disabled}
          onChange={(e) => {
            setOther(e.target.value);
            setOtherFun?.(e.target.value);
            setButtonDisabled?.(false);
          }}
          maxLength={1000}
          style={{ width: "96%", marginTop: "10px" }}
          placeholder="Describe resources your business need"
        />
      )}
    </div>
  );
}
