import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { CloseIcon } from "../../assets";
import styles from "./styles.module.scss";
import Button from "../../components/Button";
import Textarea from "../../components/Textarea";
import RadioSettings from "../../components/Selector";
import { blockReasonSelect } from "../../constants";
import { blockUser } from "../../services/businessOwners.service";
import { useNavigate } from "react-router-dom";
import { Block } from "../../models/ICommon";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onBlock: (reason: string, type: "block" | "unblock") => void;
  isLoading: boolean;
  title: string;
  type: "block" | "delete";
  userId: string;
  isBlocked: Block;
}

const BlockUser: FC<Props> = ({
  isOpen,
  setIsOpen,
  isLoading: loading,
  title,
  type,
  userId,
  onBlock,
  isBlocked,
}): JSX.Element => {
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [otherReason, setOtherReason] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };
  const onBlockAccount = async () => {
    onBlock(blockReason !== "Other" ? blockReason : otherReason, isBlocked?.isBlocked ? "unblock" : "block");
    setIsOpen(false);
  };

  useEffect(() => {
    const contentWrapper = document.querySelector(".wrapper__body__content");
    if (isOpen) {
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.marginBottom = "0px";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.padding = "0px";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.overflowX = "hidden";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.marginBottom = "50px";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.padding = "30px";
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>{title}</h5> <CloseIcon onClick={() => setIsOpen(false)} className={styles.closeBtn} />
        </div>
        <div className={styles.optionsContainer}>
          <div className={styles.blockReasonWrapper}>
            <p className={styles.title}>{`Choose a reason why you want to ${title.toLowerCase()}`}:</p>
            <div className={styles.reasonList}>
              <RadioSettings options={blockReasonSelect} name="reason" value={blockReason} onChange={setBlockReason} />
              {blockReason === "Other" && (
                <Textarea
                  placeholder="Message"
                  value={otherReason}
                  onChange={onChangeOtherReason}
                  maxLength={240}
                  style={{ minHeight: "164px", width: "91%" }}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button
            title={type === "delete" ? "Delete" : "Block"}
            loading={isLoading}
            onClick={onBlockAccount}
            disabled={blockReason === "Other" && !otherReason}
            styleType={blockReason === "Other" && !otherReason ? "disabled" : "filled"}
            style={{ border: "none", background: "#FF3C3C", borderRadius: "16px" }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BlockUser;
