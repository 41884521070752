import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { CloseIcon } from "../../assets";
import styles from "./styles.module.scss";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onAccept: () => void;
  isLoading: boolean;
  title: string;
  text?: string;
  setIsmultiple?: (value: boolean) => void;
}

const AgreementModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  onAccept,
  isLoading: loading,
  title,
  text,
  setIsmultiple,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  useEffect(() => {
    const contentWrapper = document.querySelector(".wrapper__body__content");
    if (isOpen) {
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.marginBottom = "0px";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.padding = "0px";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.overflowX = "hidden";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.marginBottom = "50px";
      // // @ts-ignore
      // if (contentWrapper) contentWrapper.style.padding = "30px";
    }
  }, [isOpen]);

  const onAcceptHandler = () => {
    setIsOpen(false);
    onAccept();
    setIsmultiple?.(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>{title}</h5> <CloseIcon onClick={() => setIsOpen(false)} className={styles.closeBtn} />
        </div>
        <div className={styles.questionBody}>
          <h2>{text ? text : "Save changes?"}</h2>
        </div>
        <div className={styles.btnContainer}>
          <Button
            title={"Confirm"}
            loading={isLoading}
            onClick={onAcceptHandler}
            style={{ border: "none", borderRadius: "16px", width: "150px" }}
          />
          <div>
            <Button
              title={"Cancel"}
              onClick={() => setIsOpen(false)}
              loading={isLoading}
              style={{
                border: "none",
                // background: "background: rgb(228, 228, 228)",
                borderRadius: "16px",
                color: "black",
                width: "150px",
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AgreementModal;
