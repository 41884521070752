import React, { useEffect, useRef, useState } from "react";
import ContentWrapper from "../Layout/ContentWrapper";
import RadioSettings from "../Selector";
import { genderSelect, industries, reportersSelect } from "../../constants";
import styles from "./style.module.scss";
import Select from "../Select";
import Button from "../Button";
import Checkbox from "../Checkbox";
import UserSelect from "../Select/UserSelect";
import GoogleInput from "../Input/GoogleInput";
import SimpleMultiSelect from "../Select/SimpleMultiSelect";
import clsx from "clsx";

export default function ExplorerFilter({
  onClose,
  onApply,
  users,
}: {
  onClose: (value: boolean) => void;
  onApply?: (
    gender: string,
    location: {
      country: string;
      state: string;
      city: string;
    },
    age: string,
    mobilityStatus: string,
    industry: string[],
    currentNeeds: string[],
  ) => void;
  users?: any;
}) {
  const [gender, setGender] = useState("All");
  const [mobilityStatus, setMobilityStatus] = useState("All");
  const [age, setAge] = useState("All");
  const [businessIndustry, setBusinessIndustry] = useState(["All"]);
  const [businessNeeds, setBusinessNeeds] = useState(["All"]);
  const [location, setLocation] = useState<{ city: string; state?: string; country: string }>({
    city: "",
    state: "",
    country: "",
  });

  const onClearHandler = () => {
    setGender("All");
    setMobilityStatus("All");
    setAge("All");
    setBusinessIndustry(["All"]);
    setBusinessNeeds(["All"]);
    setLocation({ city: "", country: "", state: "" });
  };

  return (
    <ContentWrapper>
      <div className={styles.filterWrapper}>
        <div className={styles.filterHeader}>
          <p>Filter by</p>{" "}
          <span className={styles.clear} onClick={onClearHandler}>
            Clear all filters
          </span>
        </div>

        <div className={styles.radioBody}>
          <p>Gender</p>
          <RadioSettings
            options={[
              { label: "All", value: "All" },
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
            ]}
            name="gender"
            value={gender}
            onChange={setGender}
          />
          <p>Mobility status</p>
          <RadioSettings
            options={[
              { label: "All", value: "All" },
              { label: "Transient", value: "Transient" },
              { label: "Permanently based", value: "Permanently based" },
            ]}
            name="gender"
            value={mobilityStatus}
            onChange={setMobilityStatus}
          />
        </div>
        <div className={styles.dividerX}></div>
        <div className={clsx(styles.locationBody, styles.locationOwnerBody)}>
          <GoogleInput
            label="Location"
            placeholder="Write location"
            onChange={(e) => {
              if (e.target.value.length === 0) setLocation({ city: "", state: "", country: "" });
            }}
            value={
              location.city && location.country
                ? `${location.city},${
                    location.state || (location.state && location.state?.length > 0) ? `${location.state}, ` : ""
                  } ${location.country}`
                : ""
            }
            onSelect={setLocation}
          />
        </div>
        <div className={styles.dividerX}></div>
        <div>
          <Select label="Age" options={["All", "18-29", "30-50", "50+"]} value={age} onChange={setAge} />
        </div>
        <div className={styles.dividerX}></div>
        <div className={styles.radioBody}>
          <SimpleMultiSelect
            value={businessIndustry}
            onChange={setBusinessIndustry}
            label="Industry"
            options={industries}
          />
        </div>
        <div className={styles.dividerX}></div>
        <SimpleMultiSelect
          value={businessNeeds}
          onChange={setBusinessNeeds}
          label="Seeking Needs"
          options={[
            // "All",
            // "Local Staff",
            // "Diaspora Staff",
            // "Partnership",
            // "Funding",
            // "Additional needs",
            // "Contract & consulting opportunities",
            // "Contractors",
            // "Suppliers",
            // "Buyers",
            "All",
            "Contract opportunities",
            "Consulting opportunities",
            "Full Time Employment",
            "Part Time Employment",
            "Investment Opportunities",
            "Other",
          ]}
        />
        <div
          onClick={() => {
            onClose(false);
            onApply?.(
              gender,
              location as { country: string; state: string; city: string },
              age,
              mobilityStatus,
              businessIndustry,
              businessNeeds,
            );
          }}
          className={styles.applyButton}
        >
          <Button title="Apply" />
        </div>
      </div>
    </ContentWrapper>
  );
}
