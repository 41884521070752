import React, { ReactNode, createContext, useState } from "react";

type NumbersContextType = {
  number: number;
  addNumber: (number: number) => void;
};

export const NumbersContext = createContext<NumbersContextType>({ number: 0, addNumber: () => {} });

export const NumbersProvider = ({ children }: { children: ReactNode }) => {
  const [number, setNumber] = useState(0);

  const addNumber = (newNumber: number) => {
    setNumber(newNumber);
  };

  return <NumbersContext.Provider value={{ number, addNumber }}>{children}</NumbersContext.Provider>;
};
