import { ChangeEvent, useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import styles from "./styles.module.scss";
import Input from "../../components/Input";
import { AddIcon, ArrowDownIcon, FilterIcon, SearchIcon } from "../../assets";
import clsx from "clsx";
import Checkbox from "../../components/Checkbox";
import { PulseLoader } from "react-spinners";
import PageItem from "../../components/PageItem";
import OwnersItem from "../../components/OwnersItem";
import StaffManagementItem from "../../components/StaffManagementItem";
import Button from "../../components/Button";
import ManagerModal from "../../modals/Manager";
import { getUser } from "../../utils/tokenStorage";
import { getAllStaff } from "../../services/staff.service";
import { IStaff } from "../../models/IStaff";
import EditManagerModal from "../../modals/Manager/EditUser";
import Empty from "../../components/Empty/Index";
import { useNavigate } from "react-router-dom";
import { NumbersContext } from "../../components/VerificationCounterContext/VerificationProvider";
const limit = 20;

export default function StaffManagement() {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [staff, setStaff] = useState<IStaff[]>([]);
  const [searchedStaff, setSearchedStaff] = useState<IStaff[]>([]);
  const me = getUser();
  const [firstNameSort, setFirstNameSort] = useState<-1 | 1 | 0>(0);
  const [emailSort, setEmailSort] = useState<-1 | 1 | 0>(0);
  const [roleSort, setRoleSort] = useState<-1 | 1 | 0>(0);
  const navigate = useNavigate();

  const verificationTotalCount = useContext(NumbersContext);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetAllStaff = async (
    page: number = 1,
    limit: number,
    search: string = "",
    type?: { role: 1 | -1 } | { firstName: 1 | -1 } | { email: 1 | -1 } | undefined,
  ) => {
    try {
      setIsLoading(true);

      const { data } = await getAllStaff(page, 20, type);
      if (data?.success) {
        setStaff(data?.staff);
        setPagesCount(Math.ceil(data?.totalPersonnel / limit));
        verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search) {
      const filteredStaff = staff.filter((item) => {
        const fullName = `${item.firstName} ${item.lastName}`;
        if (fullName.toLowerCase().includes(search.toLowerCase())) {
          return item;
        }
      });
      setSearchedStaff(filteredStaff);
    }
  }, [search, staff]);

  useEffect(() => {
    onGetAllStaff(1, limit, search);
  }, []);

  useEffect(() => {
    if (firstNameSort !== 0) {
      onGetAllStaff(page, 20, search, { firstName: firstNameSort });
    } else if (emailSort !== 0) {
      onGetAllStaff(page, 20, search, { email: emailSort });
    } else if (roleSort !== 0) {
      onGetAllStaff(page, 20, search, { role: roleSort });
    } else {
      onGetAllStaff(page, 20, search, undefined);
    }
  }, [firstNameSort, emailSort, roleSort]);

  if (me?.role !== "admin") {
    navigate("/statistics");
    return <></>;
  }
  const emptyStaff = search.length > 0 ? searchedStaff : staff;
  return (
    <>
      <ManagerModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} onGetAllStaff={onGetAllStaff} />
      <Layout title={"Staff Management"}>
        <div className={styles.navBody}>
          <div className={styles.input}>
            <Input
              icon={SearchIcon}
              placeholder="Search"
              isEmpty={search.length === 0}
              isSearch
              onChange={onChangeSearch}
              style={{ width: "88%" }}
              setEmpty={() => setSearch("")}
            />
          </div>
          <Button onClick={(e) => setIsOpenModal(true)} title="Add New" icon={AddIcon} />
        </div>
        <div className={styles.wrapper}>
          <section className={styles.contentContainer}>
            <div className={styles.sectionHeader}>
              <div
                onClick={() => {
                  setEmailSort(0);
                  setRoleSort(0);
                  setFirstNameSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.nameBlock, {
                  [styles.activeBlockAZ]: firstNameSort === 1,
                  [styles.activeBlockZA]: firstNameSort === -1,
                })}
              >
                <p>User</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setRoleSort(0);
                  setEmailSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.industryBlock, {
                  [styles.activeBlockAZ]: emailSort === 1,
                  [styles.activeBlockZA]: emailSort === -1,
                })}
              >
                <p>Email</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>

              <div
                onClick={() => {
                  setEmailSort(0);
                  setFirstNameSort(0);
                  setRoleSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                }}
                className={clsx(styles.locationBlock, {
                  [styles.activeBlockAZ]: roleSort === 1,
                  [styles.activeBlockZA]: roleSort === -1,
                })}
              >
                <p>Role</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div className={styles.sizeBlock}></div>
            </div>
            <div className={styles.reportsContainer}>
              {isLoading && (
                <div className={styles.loaderWrapper}>
                  <PulseLoader color={"#236BFE"} />
                </div>
              )}
              {!isLoading && search
                ? searchedStaff.map((item, index) => (
                    <StaffManagementItem
                      allChecked={isChecked}
                      key={item.id}
                      item={item}
                      setIsLoading={() => setIsLoading}
                      onGetAllStaff={onGetAllStaff}
                      isLoading={isLoading}
                    />
                  ))
                : !isLoading &&
                  staff.map((item, index) => (
                    <StaffManagementItem
                      allChecked={isChecked}
                      key={item.id}
                      item={item}
                      setIsLoading={() => setIsLoading}
                      onGetAllStaff={onGetAllStaff}
                      isLoading={isLoading}
                    />
                  ))}
              {!isLoading && emptyStaff.length === 0 && <Empty />}
            </div>
            <div className={styles.paginationContainer}>
              {pagesCount >= 0 &&
                Array(pagesCount === 0 ? 1 : pagesCount)
                  .fill("_")
                  .map((item, index) => (
                    <PageItem
                      onPress={() => onGetAllStaff(index + 1, limit, search)}
                      key={index}
                      page={index + 1}
                      isActive={index + 1 === page}
                    />
                  ))}
              {pagesCount > 1 && page !== pagesCount && (
                <div onClick={() => onGetAllStaff(page + 1, limit, search)} className={styles.nextBtn}>
                  Next
                </div>
              )}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
