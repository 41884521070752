import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import styles from "./styles.module.scss";
// import clsx from "clsx";
import { ArrowDownIcon, FilterIcon, SearchIcon } from "../../assets";
import clsx from "clsx";
import PageItem from "../../components/PageItem";
import { PulseLoader } from "react-spinners";
import Input from "../../components/Input";
import Filter from "../../components/Filter";
import FeedbackItem from "../../components/FeedbackItem";
import { ISortFeedback, getAllFeedback } from "../../services/feedback.service";
import { IFeedback } from "../../models/IFeedback";
import { NumbersContext } from "../../components/VerificationCounterContext/VerificationProvider";
import Empty from "../../components/Empty/Index";
import FeedbackFilter from "../../components/Filter/FeedbackFilter";
const limit = 20;

export default function Feedback() {
  const [openFilter, setOpenFilter] = useState(false);

  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [feedback, setFeedback] = useState<IFeedback[]>([]);
  const [reporterSort, setReporterSort] = useState<1 | -1 | 0>(0);
  const [categorySort, setCategorySort] = useState<1 | -1 | 0>(0);
  const [reportedOnSort, setReportedOnSort] = useState<1 | -1 | 0>(0);
  const [createdAtSort, setCreatedAtSort] = useState<1 | -1 | 0>(0);
  const [currentSorts, setCurrentSort] = useState("");
  const [currentFilter, setCurrentFilter] = useState<any>();
  const verificationTotalCount = useContext(NumbersContext);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onGetFeedback = async (
    page: number = 1,
    limit: number,
    search: string = "",
    type?:
      | "reporterInfo.firstName"
      | "reporterInfo.lastName"
      | "category"
      | "reportedOnInfo.firstName"
      | "reportedOnInfo.lastName"
      | "createdAt",
    filter?: any,
  ) => {
    try {
      setIsLoading(true);
      let sort;
      switch (type) {
        case "reporterInfo.firstName":
          sort = { "reporterInfo.firstName": reporterSort };
          break;
        case "category":
          sort = { category: categorySort };
          break;
        case "reportedOnInfo.firstName":
          sort = { "reportedOnInfo.firstName": reportedOnSort };
          break;
        case "createdAt":
          sort = { createdAt: createdAtSort };
          break;
        default:
          break;
      }
      const { data } = await getAllFeedback(page, limit, search, sort || {}, filter || {});
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalFeedbacks / limit));
        setFeedback(data?.feedbacks);
        verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetFeedback(1, 20, search);
  }, []);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        const sort = currentSorts?.length > 0 ? currentSorts : undefined;
        onGetFeedback(1, limit, search, sort as "reporterInfo.firstName", currentFilter);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]); // , firstNameSort, lastNameSort, citySort, typeSort, revenueSort, employeesSort

  useEffect(() => {
    if (!search) {
      const sort = currentSorts?.length > 0 ? currentSorts : undefined;
      onGetFeedback(1, limit, "", sort as "reporterInfo.firstName", currentFilter);
    }
  }, [search]);
  useEffect(() => {
    if (reporterSort !== 0) {
      onGetFeedback(page, 20, search, "reporterInfo.firstName", currentFilter);
      setCurrentSort("reporterInfo.firstName");
    } else if (reportedOnSort !== 0) {
      onGetFeedback(page, 20, search, "reportedOnInfo.firstName", currentFilter);
      setCurrentSort("reportedOnInfo.firstName");
    } else if (createdAtSort !== 0) {
      onGetFeedback(page, 20, search, "createdAt", currentFilter);
      setCurrentSort("createdAt");
    } else if (categorySort !== 0) {
      onGetFeedback(page, 20, search, "category", currentFilter);
      setCurrentSort("category");
    } else {
      onGetFeedback(page, 20, search, "nothing" as "category", currentFilter);
      setCurrentSort("");
    }
  }, [reporterSort, reportedOnSort, createdAtSort, categorySort]);

  const onApplyFilters = (reporterInfo: "owner" | "explorer" | "All", category: string[]) => {
    let filter: any = {};
    if (reporterInfo !== "All") {
      filter["reporterInfo.role"] = reporterInfo;
    }
    if (category.length > 0) {
      filter["category"] = category;
    }
    if (Object.keys(filter).length === 0) {
      setCurrentFilter(undefined);
    } else {
      setCurrentFilter(filter);
    }
    const sort = currentSorts?.length > 0 ? currentSorts : undefined;
    onGetFeedback(page, limit, search, sort as "reporterInfo.firstName", filter || {});
  };

  return (
    <Layout title={"Feedbacks"}>
      <div className={styles.navBody}>
        <div className={styles.input}>
          <Input
            icon={SearchIcon}
            placeholder="Search"
            isEmpty={search.length === 0}
            isSearch
            onChange={onChangeSearch}
            style={{ width: "88%" }}
            setEmpty={() => setSearch("")}
          />
        </div>
        <div className={styles.filterBody}>
          <div
            onClick={(e) => setOpenFilter((current) => !current)}
            className={clsx({ [styles.filter]: !openFilter, [styles.filterSelected]: openFilter })}
          >
            <FilterIcon className={clsx({ [styles.svgOpen]: openFilter })} />
          </div>
          <div className={clsx({ [styles.filterDetails]: !openFilter, [styles.filterDetailsOpen]: openFilter })}>
            <FeedbackFilter onClose={setOpenFilter} onApply={onApplyFilters} />
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <section className={styles.contentContainer}>
          <div className={styles.sectionHeader}>
            <div
              onClick={() => {
                setCategorySort(0);
                setReportedOnSort(0);
                setCreatedAtSort(0);
                setReporterSort((prev) => {
                  if (prev === 0) {
                    return 1;
                  } else if (prev === 1) {
                    return -1;
                  } else return 0;
                });
                // setCurrentSort("reporterInfo.firstName");
                // onGetFeedback(page, 20, search, "reporterInfo.firstName", currentFilter);
              }}
              className={clsx(styles.nameBlock, {
                [styles.activeBlockAZ]: reporterSort === 1,
                [styles.activeBlockZA]: reporterSort === -1,
              })}
            >
              <p>Reporter</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
            </div>
            <div
              onClick={() => {
                setReporterSort(0);
                setReportedOnSort(0);
                setCreatedAtSort(0);
                setCategorySort((prev) => {
                  if (prev === 0) {
                    return 1;
                  } else if (prev === 1) {
                    return -1;
                  } else return 0;
                });
                // setCurrentSort("category");
                // onGetFeedback(page, 20, search, "category", currentFilter);
              }}
              className={clsx(styles.industryBlock, {
                [styles.activeBlockAZ]: categorySort === 1,
                [styles.activeBlockZA]: categorySort === -1,
              })}
            >
              <p>Category</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
            </div>

            <div
              onClick={() => {
                setReporterSort(0);
                setCategorySort(0);
                setCreatedAtSort(0);
                setReportedOnSort((prev) => {
                  if (prev === 0) {
                    return 1;
                  } else if (prev === 1) {
                    return -1;
                  } else return 0;
                });
                // setCurrentSort("reportedOnInfo.firstName");
                // onGetFeedback(page, 20, search, "reportedOnInfo.firstName", currentFilter);
              }}
              className={clsx(styles.locationBlock, {
                [styles.activeBlockAZ]: reportedOnSort === 1,
                [styles.activeBlockZA]: reportedOnSort === -1,
              })}
            >
              <p>Reported on</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
            </div>
            <div
              onClick={() => {
                setReporterSort(0);
                setCategorySort(0);
                setReportedOnSort(0);
                setCreatedAtSort((prev) => {
                  if (prev === 0) {
                    return 1;
                  } else if (prev === 1) {
                    return -1;
                  } else return 0;
                });
                // setCurrentSort("createdAt");
                // onGetFeedback(page, 20, search, "createdAt", currentFilter);
              }}
              className={clsx(styles.sizeBlock, {
                [styles.activeBlockAZ]: createdAtSort === 1,
                [styles.activeBlockZA]: createdAtSort === -1,
              })}
            >
              <p className={styles.dateBlock}>Report date</p> <ArrowDownIcon fill="black" width={"13px"} />
            </div>
          </div>
          <div className={styles.reportsContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <PulseLoader color={"#236BFE"} />
              </div>
            )}
            {!isLoading &&
              feedback.map((item, index) => {
                return <FeedbackItem onFeedback={onGetFeedback} key={item.id} item={item} />;
              })}
          </div>
          {!isLoading && feedback.length === 0 && <Empty />}
          <div className={styles.paginationContainer}>
            {pagesCount >= 0 &&
              Array(pagesCount === 0 ? 1 : pagesCount)
                .fill("_")
                .map((item, index) => (
                  <PageItem
                    onPress={() => onGetFeedback(index + 1, limit, search)}
                    key={index}
                    page={index + 1}
                    isActive={index + 1 === page}
                  />
                ))}
            {pagesCount > 1 && page !== pagesCount && (
              <div onClick={() => onGetFeedback(page + 1, limit, search)} className={styles.nextBtn}>
                Next
              </div>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
}
