import { NumbersProvider } from "./components/VerificationCounterContext/VerificationProvider";
import Navigation from "./navigation";
import { Toaster } from "react-hot-toast";
function App() {
  return (
    <NumbersProvider>
      <Navigation />
      <Toaster
        toastOptions={{
          success: {
            iconTheme: {
              primary: "white",
              secondary: "#63CD5A",
            },
            style: {
              padding: "12px 22px",
              background: "rgba(213, 244, 211, 0.8)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "10px",
              width: "100%",
              justifyContent: "flex-start",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
            },
          },
          error: {
            iconTheme: {
              primary: "white",
              secondary: "#FF3C3C",
            },
            style: {
              padding: "12px 22px",
              background: " rgba(254, 206, 206, 0.8)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "10px",
              fontWeight: 500,
              width: "100%",
              fontSize: "14px",
              lineHeight: "24px",
            },
          },
        }}
      />
    </NumbersProvider>
  );
}

export default App;
