import React, { useEffect, useRef, useState } from "react";
import ContentWrapper from "../Layout/ContentWrapper";
import RadioSettings from "../Selector";
import { genderSelect, reportersSelect } from "../../constants";
import styles from "./style.module.scss";
import Select from "../Select";
import Button from "../Button";
import Checkbox from "../Checkbox";
import UserSelect from "../Select/UserSelect";
import GoogleInput from "../Input/GoogleInput";

export default function FeedbackFilter({
  onClose,
  onApply,
  users,
}: {
  onClose: (value: boolean) => void;
  onApply: (reporterInfo: "owner" | "explorer" | "All", category: string[]) => void;
  users?: any;
}) {
  const [gender, setGender] = useState("All");
  const [reporters, setReporters] = useState("All");
  const [businessType, setBusinessType] = useState("All");
  const [revenue, setRevenue] = useState("All");
  const [employees, setEmployees] = useState("All");
  const [isUserBehavior, setIsUserBehavior] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [isFalseInformation, setIsFalseInformation] = useState(false);
  const [isScam, setIsScam] = useState(false);
  const [isProblemTransactions, setIsProblemTransactions] = useState(false);
  const [reportsFrom, setReportsFrom] = useState();
  const [location, setLocation] = useState<{ city: string; state?: string; country: string }>({
    city: "",
    state: "",
    country: "",
  });
  useEffect(() => {
    if (users) setReportsFrom(users[0]);
  }, [users]);

  const onClearHandler = () => {
    setGender("All");
    setLocation({ city: "", country: "", state: "" });
    setBusinessType("All");
    setRevenue("All");
    setEmployees("All");
    setIsAll(false);
    setReporters("All");
    setIsUserBehavior(false);
    setIsFalseInformation(false);
    setIsScam(false);
    setIsProblemTransactions(false);
    if (users) {
      setReportsFrom(users[0]);
    }
  };

  useEffect(() => {
    setIsUserBehavior(isAll);
    setIsFalseInformation(isAll);
    setIsScam(isAll);
    setIsProblemTransactions(isAll);
  }, [isAll]);

  return (
    <ContentWrapper>
      <div className={styles.filterHeader}>
        <p>Filter by</p> <span onClick={onClearHandler}>Clear all filters</span>
      </div>{" "}
      <>
        {" "}
        <div className={styles.radioBody}>
          <p>Reporters</p>
          <RadioSettings options={reportersSelect} name="reporters" value={reporters} onChange={setReporters} />
        </div>
        <div className={styles.dividerX}></div>
        <div className={styles.radioBody}>
          <p>Category</p>
          <div className={styles.checkboxBody}>
            {/* <div className={styles.checkboxContainer}>
              <Checkbox isChecked={isAll} setIsChecked={setIsAll} />
              <span>All</span>
            </div> */}
            <div className={styles.checkboxContainer}>
              <Checkbox isChecked={isUserBehavior} setIsChecked={setIsUserBehavior} />
              <span>Negative content</span>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox isChecked={isFalseInformation} setIsChecked={setIsFalseInformation} />
              <span>False information</span>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox isChecked={isScam} setIsChecked={setIsScam} />
              <span>Scam or fraud</span>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox isChecked={isProblemTransactions} setIsChecked={setIsProblemTransactions} />
              <span>Problem with website</span>
            </div>
          </div>
        </div>
      </>
      <div
        onClick={() => {
          onClose(false);
          let categoryFilter: string[] = [];
          if (isUserBehavior) categoryFilter = [...categoryFilter, "Negative content"];
          if (isFalseInformation) categoryFilter = [...categoryFilter, "False information"];
          if (isScam) categoryFilter = [...categoryFilter, "Scam or fraud"];
          if (isProblemTransactions) categoryFilter = [...categoryFilter, "Problem with website"];
          onApply?.(reporters as "All", categoryFilter);
        }}
        className={styles.applyButton}
      >
        <Button title="Apply" />
      </div>
    </ContentWrapper>
  );
}
