import { FC, useEffect, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../assets/images/avatar.png";
// import ReportType from "../ReportType";
import { ArrowDownIcon } from "../../assets";
// import { Report } from "../../modals";
import { useNavigate } from "react-router-dom";
import StaffItem from "../StaffItem";
import { IOwner } from "../../models/IOwner";
import { IExplorer } from "../../models/IExplorers";
// import { IEmployer } from "../../models";

// interface Props {
//   item: IEmployer;
// }

const BusinessItem = ({
  item,
  allChecked,
  setDeleteOwners,
}: {
  item: IExplorer;
  allChecked: boolean;
  setDeleteOwners: (item: any) => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(allChecked);
  }, [allChecked]);
  // FC<Props>
  const navigate = useNavigate();

  useEffect(() => {
    if (isChecked) {
      setDeleteOwners((current: any) => [...current, item.id]);
    }
    if (!isChecked) setDeleteOwners((current: any) => current.filter((id: any) => id != item.id));
  }, [isChecked]);

  function formatDate(dateString: string) {
    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = MONTHS[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

  return (
    <div className={styles.businessBody}>
      <div className={styles.wrapper}>
        <div className={styles.checkbox}>
          <CheckBox isChecked={isChecked} setIsChecked={() => setIsChecked((current) => !current)} />
        </div>
        <div onClick={() => navigate(`/business-explorers/${item.id}`)}>
          <StaffItem image={item.avatar} name={`${item.firstName} ${item.lastName}`} />
        </div>
        <div onClick={() => navigate(`/business-explorers/${item.id}`)}>
          <div className={styles.ownerLocationWrapper}>
            <p>{`${item.location.city}${item.location.state ? `, ${item.location.state}` : ""}`}</p>
          </div>
        </div>
        <div onClick={() => navigate(`/business-explorers/${item.id}`)}>
          <div className={styles.businessLocationWrapper}>
            <p>{item.phone}</p>
          </div>
        </div>
        <div onClick={() => navigate(`/business-explorers/${item.id}`)}>
          <div className={styles.businessTypeWrapper}>
            <p>{item.email}</p>
          </div>
        </div>
        <div onClick={() => navigate(`/business-explorers/${item.id}`)}>
          <div className={styles.revenueWrapper}>
            <p>{formatDate(item.createdAt)}</p>
          </div>
        </div>
      </div>
      <div onClick={() => navigate(`/business-explorers/${item.id}`)} className={styles.select}>
        <div className={styles.arrowBtn}>
          <ArrowDownIcon width={"15px"} fill="white" />
        </div>
      </div>
    </div>
  );
};

export default BusinessItem;
