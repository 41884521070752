import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../assets";
// import { Button, Radio, Textarea } from "../../components";
import PlaceholderAvatar from "./../../assets/images/avatar.png";
// import { blockCandidate } from "../../services/candidate.service";
// import { blockEmployer } from "../../services/employer.service";
import styles from "./styles.module.scss";
import Textarea from "../../components/Textarea";
import Button from "../../components/Button";
import Tag from "../../components/Tags";
import UserInfoBlock from "../../components/UserInfoBlock";
import { IPost } from "../../models/IModel";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  item: IPost;
  // userId: string;
}
const PostModal: FC<Props> = ({ isOpen, setIsOpen, item }): JSX.Element => {
  function formatDate(dateString: string) {
    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = MONTHS[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

  let revenue;
  if (!item.author?.[0]?.profile?.[0]?.business[0]?.revenue) {
    revenue = "";
  } else if (
    item.author?.[0]?.profile?.[0]?.business[0]?.revenue &&
    item.author?.[0]?.profile?.[0]?.business[0]?.revenue?.min ===
      item.author?.[0]?.profile?.[0]?.business[0]?.revenue?.max
  ) {
    revenue = `${item.author?.[0]?.profile?.[0]?.business[0]?.revenue?.min}k +`;
  } else {
    revenue = `${
      item.author?.[0]?.profile?.[0]?.business[0]?.revenue?.min > 0
        ? item.author?.[0]?.profile?.[0]?.business[0]?.revenue?.min + "k"
        : item.author?.[0]?.profile?.[0]?.business[0]?.revenue?.min || 0
    } - ${item.author?.[0]?.profile?.[0]?.business[0]?.revenue?.max || 100}k`;
  }
  useEffect(() => {
    const contentWrapper = document.querySelector(".wrapper__body__content");
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.overflowX = "hidden";
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>Post</h5> <CloseIcon onClick={() => setIsOpen(false)} className={styles.closeBtn} />
        </div>
        <div className={styles.modalBody}>
          <div className={styles.postModalBody}>
            <div className={styles.mainInfo}>
              <p>{item.opportunityName}</p>
              <div className={styles.tagContainer}>
                <div className={styles.tagBody}>
                  {item.skills.map((tag) => (
                    <Tag label={tag} key={tag} />
                  ))}
                </div>
                <span>{formatDate(item.createdAt)}</span>
              </div>
            </div>
            <div className={styles.content}>
              <p>{item.description}</p>
            </div>
          </div>
          {item.author && item.author?.[0]?.profile && item.author?.[0]?.profile?.[0] && (
            <UserInfoBlock
              item={{
                name: `${item.author?.[0]?.profile?.[0]?.firstName} ${item.author?.[0]?.profile?.[0]?.lastName}`,
                image: item.author?.[0]?.profile?.[0]?.avatar || PlaceholderAvatar,
                location: `${item.author?.[0]?.profile?.[0]?.location.city}${
                  item.author?.[0]?.profile?.[0]?.location.state
                    ? `, ${item.author?.[0]?.profile?.[0]?.location.city}`
                    : ""
                }`,
                lookingFor:
                  item.author?.[0]?.profile?.[0]?.business && item.author?.[0]?.profile?.[0]?.business?.[0]
                    ? item.author?.[0]?.profile?.[0]?.business?.[0]?.currentNeeds.join(", ")
                    : "-",
                phone:
                  item.author?.[0]?.profile?.[0]?.business && item.author?.[0]?.profile?.[0]?.business?.[0]
                    ? item.author?.[0]?.profile?.[0]?.business?.[0]?.phone
                    : "-",
                email: item.author?.[0]?.profile?.[0]?.email,
                bussinessName:
                  item.author?.[0]?.profile?.[0]?.business && item.author?.[0]?.profile?.[0]?.business?.[0]
                    ? item.author?.[0]?.profile?.[0]?.business?.[0]?.name
                    : "-",
                industry:
                  item.author?.[0]?.profile?.[0]?.business && item.author?.[0]?.profile?.[0]?.business?.[0]
                    ? item.author?.[0]?.profile?.[0]?.business?.[0]?.industry
                    : ["-"],
                countEmployees:
                  typeof item.author?.[0]?.profile?.[0]?.business[0]?.employees.min !== "undefined"
                    ? item.author?.[0]?.profile?.[0]?.business[0]?.employees.min ===
                      item.author?.[0]?.profile?.[0]?.business[0]?.employees.max
                      ? `${item.author?.[0]?.profile?.[0]?.business[0]?.employees.min}+`
                      : typeof item.author?.[0]?.profile?.[0]?.business[0]?.employees.min === "number" &&
                        typeof item.author?.[0]?.profile?.[0]?.business[0]?.employees.max === "number"
                      ? `${item.author?.[0]?.profile?.[0]?.business[0]?.employees.min} - ${item.author?.[0]?.profile?.[0]?.business[0]?.employees.max}`
                      : ""
                    : "",
                revenue: revenue,
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PostModal;
