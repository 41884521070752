import { ChangeEvent, FC, useState, useEffect, useRef } from "react";
import { PulseLoader } from "react-spinners";
import PlaceholderAvatar from "./../../../../assets/images/avatar.png";
import styles from "./styles.module.scss";
import { toast } from "react-hot-toast";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import GoogleInput from "../../../../components/Input/GoogleInput";
import Select from "../../../../components/Select";
import MultiSelect from "../../../../components/Select/MultiSelect";
import Textarea from "../../../../components/Textarea";
import { industries, toastError } from "../../../../constants";
import AgreementModal from "../../../../modals/Agreement";
import { IExplorer } from "../../../../models/IExplorers";
import { editExplorer } from "../../../../services/businessExplorers.service";
import { uploadFile } from "../../../../services/file.service";
import { getUser } from "../../../../utils/tokenStorage";
import { getLinkForUploadImage } from "../../../../services/auth.service";
import GoogleInputWithAddress from "../../../../components/Input/GoogleInputWithAddress";
import SimpleMultiSelect from "../../../../components/Select/SimpleMultiSelect";

const seekingNeedsOption = [
  "Contract opportunities",
  "Consulting opportunities",
  "Full Time Employment",
  "Part Time Employment",
  "Investment Opportunities",
];

const AccountDetailsExplorer = ({
  owner,
  onGetExlorer,
  id,
}: {
  owner?: IExplorer | undefined;
  onGetExlorer?: (value: string) => void;
  id?: string;
}) => {
  const [firstName, setFirstName] = useState(owner?.firstName || "");
  const [lastName, setLastName] = useState(owner?.lastName || "");
  const [location, setLocation] = useState<{ address: string; city: string; state?: string; country: string }>(
    owner?.location || { address: "", city: "", state: "", country: "" },
  );
  const [locationSecondary, setLocationSecondary] = useState(owner?.transientLocation || "");

  const [phone, setPhone] = useState(owner?.phone || "");
  const [gender, setGender] = useState<string>(owner?.gender || "Female");
  const [mobilityStatus, setMobilityStatus] = useState(owner?.mobilityStatus || "Lviv");
  const [isLoading, setIsLoading] = useState(false);
  const [age, setAge] = useState(`${owner?.age}` || "");
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState<string>(owner?.avatar || "");
  const [aboutMe, setAboutMe] = useState<string>(owner?.description || "");
  const [avatarFile, setAvatarFile] = useState<ArrayBuffer | null | undefined | string | any>();
  const [avatarFileName, setAvatarFileName] = useState<string>("");
  const [seekingNeeds, setSeekingNeeds] = useState(owner?.currentNeeds || ["Contract opportunities"]);
  const [seekingNeedsOther, setSeekingNeedsOther] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [commonError, setCommonError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [seekingNeedsError, setSeekingNeedsError] = useState("");
  const [aboutMeError, setAboutMeError] = useState("");
  const [isEmptyLocation, setIsEmptyLocation] = useState(false);
  const [businessIndustry, setBusinessIndustry] = useState(["Education"]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isAgreeModal, setIsAgreeModal] = useState(false);

  const me = getUser();

  useEffect(() => {
    if (owner) {
      setFirstName(owner.firstName);
      setLastName(owner.lastName);
      setLocation(owner.location);
      setGender(owner.gender || "Male");
      setAge(`${owner.age}`);
      setMobilityStatus(owner.mobilityStatus);
      setPhone(owner.phone);
      setAvatar(owner.avatar || "");
      setLocationSecondary(owner.transientLocation || "");
      setSeekingNeeds(owner.currentNeeds || ["Contract opportunities"]);
      setAboutMe(owner.description || "");
      setEmail(owner?.email || "");
      setBusinessIndustry(owner?.industry);
      owner?.currentNeeds.forEach((item) => {
        if (!seekingNeedsOption.includes(item) || item === "Other") {
          setSeekingNeedsOther(item);
        }
      });
    }
  }, [owner]);

  const saveRef = useRef<HTMLDivElement>(null);

  function validateFirstName(firstName: string) {
    if (firstName.length === 0) {
      return "First name is required";
    }
    return true;
  }

  function validatelastName(lastName: string) {
    if (lastName.length === 0) {
      return "Last name is required";
    }
    return true;
  }

  function validateAge(age: string) {
    const numericRegex = /^[0-9]+$/;
    if (!numericRegex.test(age)) {
      return "Age must be a valid number";
    }

    const ageNumber = parseInt(age, 10);

    if (ageNumber < 0) {
      return "Age must be greater than or equal to zero";
    }

    return true;
  }

  function validateLocation(location: { city: string; country: string }) {
    if (location.city.length === 0 && location.country.length === 0) {
      return "Location is required";
    }
    return true;
  }

  function validatePhone(phone: string) {
    const regex = /^[\d+\-() ]+$/;

    if (!regex.test(phone)) {
      return "Please enter a valid phone number.";
    }

    if (phone.includes("-")) {
      return "Phone number cannot contain hyphens.";
    }

    return true;
  }

  function validateEmail(email: string) {
    if (email.length > 80) {
      return "Email address must be at most 80 characters long.";
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regex.test(email)) {
      return "Please enter a valid email address.";
    }

    return true;
  }

  const inputFile = useRef<HTMLInputElement>(null);
  const showOpenFileDialog = () => {
    if (me?.role === "manager") {
      return;
    }
    inputFile.current?.click();
  };
  const onDeleteAvatar = () => {
    if (me?.role === "manager") {
      return;
    }
    setAvatarFile("");
    setAvatar("");
    setAvatarFileName("");
    setButtonDisabled(false);
  };

  const onSave = async () => {
    try {
      const firstNameError = validateFirstName(firstName);
      if (typeof firstNameError === "string") {
        setFirstNameError(firstNameError);
        return;
      }
      const lastNameError = validatelastName(lastName);
      if (typeof lastNameError === "string") {
        setLastNameError(lastNameError);
        return;
      }

      const locationError = validateLocation(location);
      if (typeof locationError === "string") {
        setLocationError(locationError);
        return;
      }

      if (isEmptyLocation) {
        setLocationError("Location is required");
        return;
      }

      const ageError = validateAge(age);
      if (typeof ageError === "string") {
        setAgeError(ageError);
        return;
      }

      const emailError = validateEmail(email);
      if (typeof emailError === "string") {
        setEmailError(emailError);
        return;
      }

      const phoneError = validatePhone(phone);
      if (phone.length > 0 && typeof phoneError === "string") {
        setPhoneError(phoneError);
        return;
      }

      if (seekingNeeds.length === 0) {
        setSeekingNeedsError("Seeking need is required");
        return;
      }

      if (seekingNeedsOther.length === 1) {
        setSeekingNeedsError("Field Other must be at least 2 characters");
        return;
      }

      if (aboutMe.length === 1) {
        setAboutMeError("Must be at least 2 characters");
        return;
      }
      if (businessIndustry.length === 0) {
        setCommonError("Industry is required");
        return;
      }
      if (businessIndustry.length > 3) {
        setCommonError("Industry must be at most 3");
        return;
      }

      setIsLoading(true);

      const formatedNeeds = seekingNeeds.filter((need) => seekingNeedsOption.includes(need));

      let imageLink = "";
      if (avatarFile) {
        //@ts-ignore
        const resLinkImg = await getLinkForUploadImage(avatarFileName);
        imageLink = resLinkImg?.data?.links[0].downloadUrl;
        let blob = await fetch(avatar).then((r) => r.blob());
        const resUploadImg = await uploadFile(blob, resLinkImg?.data?.links[0].uploadUrl);
      }
      const { data } = await editExplorer(
        id || "",
        owner?.email || "",
        firstName,
        lastName,
        imageLink || avatar || null,
        gender as "Male" | "Female",
        aboutMe,
        +age,
        mobilityStatus,
        location,
        phone,
        seekingNeedsOther.length > 0 ? [...formatedNeeds, seekingNeedsOther] : formatedNeeds,
        businessIndustry,
        locationSecondary,
      );
      if (data?.success) {
        onGetExlorer?.(id || "");
        setFirstNameError("");
        setLastNameError("");
        setAgeError("");
        setLocationError("");
        setCommonError("");
        setAboutMeError("");
        setEmailError("");
        setSeekingNeedsError("");
      }
      setIsLoading(false);
      setButtonDisabled(true);
      toast.success("User has been successfully updated");
    } catch (error: any) {
      setIsLoading(false);
      if (!error?.success) {
        setCommonError(error.message);
        toast.error(toastError);
      }
    }
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setAvatar(URL.createObjectURL(e.target.files[0]));
      let file = e.target.files[0];
      setAvatarFile(e.target.files[0]);
      setButtonDisabled(false);
      setAvatarFileName(file?.name);
    } catch (error) {}
  };

  const onSelectLocation = (location: { address: string; city: string; country: string }) => {
    setLocation(location);
    setLocationError("");

    setButtonDisabled(false);
  };
  const onSelectLocationSecodnary = (location: { address: string; city: string; state?: string; country: string }) => {
    setLocationSecondary(location.address);
    setButtonDisabled(false);
  };
  const handlePhoneChange = (event: any) => {
    setPhoneError("");
    let value = event.target.value;
    if (!value.startsWith("+")) {
      value = "+" + value;
    }
    setPhone(value);

    setButtonDisabled(false);
  };

  useEffect(() => {
    if (owner?.location?.city || owner?.location.country) {
      setIsEmptyLocation(false);
    }
  }, [owner]);

  useEffect(() => {
    setButtonDisabled(true);
    const locationInput: any = document.querySelector(".pac-target-input");
    setIsEmptyLocation(true);
    saveRef.current?.addEventListener("click", (e) => {
      if (locationInput && locationInput.value.length === 0) {
        setIsEmptyLocation(true);
      } else {
        setIsEmptyLocation(false);
      }
    });
    return () => {
      saveRef.current?.removeEventListener("click", (e) => {
        if (locationInput && locationInput.value.length === 0) {
          setIsEmptyLocation(true);
        } else {
          setIsEmptyLocation(false);
        }
      });
    };
  }, []);

  return (
    <>
      <AgreementModal
        isLoading={isLoading}
        isOpen={isAgreeModal}
        onAccept={onSave}
        setIsOpen={setIsAgreeModal}
        title="Confirmation"
      />
      {!owner ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#236BFE"} />
        </div>
      ) : (
        <>
          <div className={styles.profileSection}>
            <div className={styles.profileBody}>
              <div className={styles.avatarBody}>
                {avatar && <img src={avatar} alt={"avatar"} className={styles.avatar} />}
                {!avatar && (
                  <img
                    // src={employer?.image ? employer?.image : PlaceholderAvatar}
                    src={PlaceholderAvatar}
                    alt="avatar"
                    className={styles.avatar}
                  />
                )}
              </div>
              {me?.role !== "manager" && (
                <div className={styles.avatarInfoBody}>
                  <div>
                    <input
                      style={{ display: "none" }}
                      ref={inputFile}
                      type={"file"}
                      accept="image/*"
                      onChange={onSelectFile}
                      onClick={(e: any) => (e.target.value = null)}
                      className={styles.bootcampsSettingsUploadImgBtn}
                    />
                    <span onClick={showOpenFileDialog}>Change Avatar</span>
                  </div>
                  <span className={styles.deleteBody} onClick={onDeleteAvatar}>
                    Delete
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={styles.dividerX}></div>
          <div className={styles.formSection}>
            <Input
              label="First Name"
              disabled={me?.role === "manager"}
              placeholder="Write your first name"
              style={{ padding: "12px 16px" }}
              value={firstName}
              error={firstNameError}
              onChange={(e) => {
                setFirstName(e.target.value);
                setFirstNameError("");
                setButtonDisabled(false);
              }}
            />
            <Input
              label="Last Name"
              disabled={me?.role === "manager"}
              placeholder="Write your last name"
              style={{ padding: "12px 16px" }}
              value={lastName}
              error={lastNameError}
              onChange={(e) => {
                setLastName(e.target.value);
                setLastNameError("");
                setButtonDisabled(false);
              }}
            />
            <Input
              label="Email"
              disabled={me?.role === "manager"}
              placeholder="Write your email"
              style={{ padding: "12px 16px" }}
              error={emailError}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");

                setButtonDisabled(false);
              }}
            />
            <Input
              label="Phone"
              disabled={me?.role === "manager"}
              type="tel"
              placeholder="Write your business phone"
              style={{ padding: "12px 16px" }}
              value={phone}
              onChange={handlePhoneChange}
              error={phoneError}
            />
            <GoogleInputWithAddress
              label="Location"
              error={locationError}
              disabled={me?.role === "manager"}
              placeholder="Write your location"
              value={
                location.address
                  ? location.address
                  : location.city && location.country
                  ? `${location.city},${
                      location.state || (location.state && location.state?.length > 0) ? `${location.state}, ` : ""
                    } ${location.country}`
                  : ""
              }
              onSelect={onSelectLocation}
            />{" "}
            <GoogleInputWithAddress
              disabled={me?.role === "manager"}
              label="Secondary Address"
              placeholder="Your Secondary Address"
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setLocationSecondary("");
                  setButtonDisabled(false);
                }
              }}
              value={locationSecondary}
              onSelect={onSelectLocationSecodnary}
            />
            <Select
              label="Gender"
              disabled={me?.role === "manager"}
              options={["Female", "Male"]}
              value={gender}
              onChange={(option) => {
                setGender(option);
                setButtonDisabled(false);
              }}
            />
            <Input
              label="Age"
              placeholder="Write age"
              disabled={me?.role === "manager"}
              style={{ padding: "12px 16px" }}
              value={age}
              error={ageError}
              onChange={(e) => {
                setAge(e.target.value);
                setAgeError("");
                setButtonDisabled(false);
              }}
              type="string"
            />
            <Select
              label="Mobility Status"
              disabled={me?.role === "manager"}
              options={["Transient", "Permanently based"]}
              value={mobilityStatus}
              onChange={(option) => {
                setMobilityStatus(option);

                setButtonDisabled(false);
              }}
            />
          </div>
          <div className={styles.multiSelect}>
            {" "}
            {/* <MultiSelect
              multiple
              removeOther
              disabled={me?.role === "manager"}
              label="Industry"
              subtitle="Choose which industry your business is for"
              options={[
                "Education",
                "Construction",
                "Design",
                "Corporate Services",
                "Retail",
                "Energy & Mining",
                "Manufacturing",
                "Finance",
              ]}
              setButtonDisabled={setButtonDisabled}
              value={businessIndustry}
              onChange={(option) => {
                setBusinessIndustry(option);
                setButtonDisabled(false);
              }}
            /> */}
            <SimpleMultiSelect
              value={businessIndustry}
              onChange={(option) => {
                setButtonDisabled(false);
                setBusinessIndustry(option);
              }}
              label="Industry"
              options={industries}
            />
          </div>
          <div className={styles.multiSelect}>
            {" "}
            <MultiSelect
              multiple
              disabled={me?.role === "manager"}
              setOtherFun={setSeekingNeedsOther}
              other={seekingNeedsOther}
              label="Seeking Needs"
              subtitle="Choose all resources your business need"
              options={seekingNeedsOption}
              setButtonDisabled={setButtonDisabled}
              value={seekingNeeds}
              onChange={(option) => {
                setSeekingNeeds(option);
                setButtonDisabled(false);
              }}
            />
          </div>
          <Textarea
            label="About me"
            disabled={me?.role === "manager"}
            placeholder="Write about you"
            value={aboutMe}
            maxLength={1000}
            error={aboutMeError}
            onChange={(e) => {
              setAboutMe(e.target.value);
              setAboutMeError("");

              setButtonDisabled(false);
            }}
            style={{ minHeight: "164px", width: "96%" }}
          />
          {seekingNeedsError && <p className={styles.commonError}>{seekingNeedsError}</p>}
          {commonError && <p className={styles.commonError}>{commonError}</p>}
          {me?.role !== "manager" && (
            <div ref={saveRef} className={styles.button}>
              <Button title="Save" onClick={() => setIsAgreeModal(true)} disabled={buttonDisabled || isLoading} />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default AccountDetailsExplorer;
