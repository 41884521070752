import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../assets/images/companyAvatar.png";
import Tag from "../Tags";
import { LocationIcon, LocationV, PlaceholderIcon } from "../../assets";
import { IPost } from "../../models/IModel";
import PostModal from "../../modals/Post";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const tags = ["Expanding", "New Customers", "Opportunity"];

const PostItems = ({ item }: { item: IPost }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <PostModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} item={item} />
      <div onClick={() => setIsOpenModal((current) => !current)} className={styles.postBody}>
        <div className={styles.image}>
          {!imageLoaded && <Skeleton width={300} height={200} />}
          <img src={item.image ? item.image : PlaceholderAvatar} onLoad={() => setImageLoaded(true)} />
        </div>
        <div className={styles.infoBody}>
          <div className={styles.headerBody}>
            <h5>{item.opportunityName}</h5>
            <div className={styles.location}>
              <LocationV />{" "}
              <span>{`${item.businessLocation.city}${
                item.businessLocation.state ? `, ${item.businessLocation.state}` : ""
              }`}</span>
            </div>
          </div>
          <p>{item.description.length > 300 ? `${item.description.slice(0, 300)}...` : item.description}</p>
          <div className={styles.tagBody}>
            {item.skills.map((tag) => (
              <Tag label={tag} key={tag} />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.dividerX}></div>
    </>
  );
};
export default PostItems;
