import { FC, useEffect, useRef, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import { MoreVerticalIcon } from "../../assets";
import StaffItem from "../StaffItem";
import { IStaff } from "../../models/IStaff";
import ContentWrapper from "../Layout/ContentWrapper";
import clsx from "clsx";
import { deleteStaff } from "../../services/staff.service";
import EditManagerModal from "../../modals/Manager/EditUser";
import Delete from "../../modals/Delete";
import { toastError } from "../../constants";
import { toast } from "react-hot-toast";

const StaffManagementItem = ({
  item,
  allChecked,
  setIsLoading,
  onGetAllStaff,
  isLoading,
}: {
  item: IStaff;
  allChecked: boolean;
  setIsLoading: (value: boolean) => void;
  onGetAllStaff: (page: number, limit: number) => void;
  isLoading: boolean;
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showPopap, setShowPopap] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  useEffect(() => {
    setIsChecked(allChecked);
  }, [allChecked]);

  const onEdit = () => {
    setShowPopap(false);
    setIsOpenEditModal(true);
  };

  const onDelete = async () => {
    setIsShowDeleteModal(true);
    setShowPopap(false);
  };

  const confirmedDelete = async () => {
    try {
      setIsLoading(true);
      const { data } = await deleteStaff({ id: [item.id] });
      if (data?.success) {
        onGetAllStaff(1, 20);
      }
      setIsLoading(false);
      toast.success("User has been successfully deleted");
    } catch (error) {
      toast.error(toastError);
      setIsLoading(false);
    }
    setIsShowDeleteModal(false);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!event.target.closest(".details__body")) {
        setShowPopap(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <EditManagerModal
        item={item}
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        onGetAllStaff={onGetAllStaff}
      />
      <Delete
        title="Delete user"
        isLoading={isLoading}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        onDelete={confirmedDelete}
        type="delete"
      />
      <div className={styles.businessBody}>
        <div className={styles.wrapper}>
          <div>
            <StaffItem image={item.avatar || null} name={`${item.firstName} ${item.lastName}`} />
          </div>
          <div>
            <div className={styles.ownerLocationWrapper}>
              <p>{item.email}</p>
            </div>
          </div>
          <div>
            <div className={styles.businessLocationWrapper}>
              <p>{item.role === "manager" ? "Organization Manager" : "Moderator"}</p>
            </div>
          </div>
        </div>
        <div className={clsx(styles.select, "details__body")}>
          <div onClick={() => setShowPopap((current) => !current)}>
            <MoreVerticalIcon />
          </div>
          {showPopap && (
            <div className={styles.popap}>
              <ContentWrapper className={{ padding: "10px" }}>
                <p onClick={onEdit} className={styles.edit}>
                  Edit
                </p>
                <p onClick={onDelete} className={styles.delete}>
                  Delete
                </p>
              </ContentWrapper>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default StaffManagementItem;
