import { FC, useEffect, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../assets/images/avatar.png";
// import ReportType from "../ReportType";
import { ArrowDownIcon } from "../../assets";
// import { Report } from "../../modals";
import { useNavigate } from "react-router-dom";
import StaffItem from "../StaffItem";
import ActiveButton from "../ActiveButtons/ActiveButton";
import DeclineButton from "../ActiveButtons/DeclineButton";
import ApproveModal from "../../modals/Approve";
import ReportModal from "../../modals/Report";
import { IFeedback } from "../../models/IFeedback";

const FeedbackItem = ({
  item,
  onFeedback,
}: {
  item: IFeedback;
  onFeedback: (page: number, limit: number, search: string) => void;
}) => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  function formatDate(dateString: string) {
    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = MONTHS[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

  function whichRole(role: string) {
    if (role?.toLowerCase() === "owner") {
      return "Business Owner";
    } else if (role?.toLowerCase() === "explorer") {
      return "Business Explorer";
    } else {
      return "Super Admin";
    }
  }
  return (
    <>
      <ReportModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        reporter={item.reporterInfo}
        reportedOn={item.reportedOnInfo}
        category={item.category}
        userId={item.reporterInfo?.id}
        feedBackId={item.id}
        date={item.createdAt}
        reportedId={item.reportedOnInfo?.[0]?.id}
        onFeedback={onFeedback}
        description={item.description}
        attachmentImage={item.image || undefined}
      />
      <div className={styles.businessBody}>
        <div className={styles.wrapper}>
          <div onClick={() => setIsOpenModal((current) => !current)}>
            {item?.reporterInfo ? (
              <StaffItem
                image={item?.reporterInfo?.avatar || null}
                name={`${item?.reporterInfo?.firstName || ""} ${item?.reporterInfo?.lastName || ""}`}
                description={whichRole(item?.reporterInfo?.role)}
              />
            ) : (
              <StaffItem image={null} name={`Deleted user`} />
            )}
          </div>
          <div onClick={() => setIsOpenModal((current) => !current)}>
            <div className={styles.ownerLocationWrapper}>
              <p>{item.category}</p>
            </div>
          </div>
          <div style={{ width: "100%" }} onClick={() => setIsOpenModal((current) => !current)}>
            <div className={styles.businessTypeWrapper}>
              {item.reportedOnInfo && item.reportedOnInfo.length > 0 ? (
                <StaffItem
                  small
                  image={item.reportedOnInfo?.[0]?.avatar || null}
                  name={`${item.reportedOnInfo?.[0]?.firstName} ${item.reportedOnInfo?.[0]?.lastName}`}
                  description={whichRole(item.reportedOnInfo?.[0]?.role)}
                />
              ) : (
                <div style={{ opacity: 0 }}>
                  <StaffItem image={null} name={`Deleted user`} />
                </div>
              )}
            </div>
          </div>
          <div onClick={() => setIsOpenModal((current) => !current)}>
            <div className={styles.revenueWrapper}>
              <p>{formatDate(item.createdAt)}</p>
            </div>
          </div>
          <div onClick={() => setIsOpenModal((current) => !current)} className={styles.select}>
            <div className={styles.arrowBtn}>
              <ArrowDownIcon width={"15px"} fill="white" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedbackItem;
