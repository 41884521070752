import axiosInstance from "../apis";
import { AxiosResponse } from "axios";
type SortType = { role: 1 | -1 } | { firstName: 1 | -1 } | { email: 1 | -1 } | undefined;

export const getAllStaff = async (page: number, limit: number = 10, type?: SortType): Promise<AxiosResponse> => {
  const sortType = type && `${Object.keys(type || {})?.[0]}=${Object.values(type || {})?.[0]}`;
  if (!sortType) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`admin/all?page=${page}&limit=${limit}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e?.response?.data));
    });
  }
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/all?page=${page}&limit=${limit}&${sortType}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const createStaff = (
  email: string,
  avatar: string,
  firstName: string,
  lastName: string,
  password: string,
  role: "moderator" | "manager",
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      email,
      password,
      firstName,
      lastName,
      role,
    };
    if (avatar) {
      body.avatar = avatar;
    }
    axiosInstance
      .post("admin/create", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const editStaff = (
  id: string,
  email: string,
  avatar: string,
  firstName: string,
  lastName: string,
  password: string | null,
  role: "moderator" | "manager",
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      email,
      firstName,
      lastName,
      password,
      role,
      avatar: avatar ? avatar : null,
    };
    axiosInstance
      .patch(`admin/update?userId=${id}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const deleteStaff = (body: { id: string[] }): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`admin/delete`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
