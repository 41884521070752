import clsx from "clsx";
import styles from "./styles.module.scss";
interface Props {
  label: string;
  isSelected?: boolean;
  style?: {};
}
export default function Tag({ label, isSelected = false, style }: Props) {
  return (
    <div style={style} className={clsx({ [styles.tag]: !isSelected, [styles.selectedTag]: isSelected })}>
      {label}
    </div>
  );
}
