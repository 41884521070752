import { ChartIcon, CheckCircleIcon, FeedbackIcon, SearchVIcon, StoreIcon, UsersCandidatesIcon } from "../assets";
export const BASE_URL = "https://api.liberiandx.com";
export const GOOGLE_API_KEY = "AIzaSyB2twU-RNkCanAXFaK1xPudVKOgen213Ec";
export const navBarItems = [
  { logo: ChartIcon, description: "Statistics", path: "statistics" },
  { logo: StoreIcon, description: "Business Owners", path: "business-owners" },
  { logo: SearchVIcon, description: "Business Explorers", path: "business-explorers" },
  { logo: CheckCircleIcon, description: "Verification", path: "verification" },
  { logo: FeedbackIcon, description: "Feedback", path: "feedback" },
  { logo: UsersCandidatesIcon, description: "Staff Management", path: "staff-management" },
];

export const toastError = "Something went wrong!";

export const Age = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
  "100",
];
export const selectorButton = [
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
  { label: "All time", value: "allTime" },
];
export const verificationTabs = [
  { label: "Business Owners", value: "BusinessOwners" },
  { label: "Seekers/Explorers", value: "Seekers/Explorers" },
];

export const genderSelect = [
  { label: "All", value: "all" },
  { label: "Male", value: "male" },
];
export const reportersSelect = [
  { label: "All", value: "All" },
  { label: "Business Owners", value: "owner" },
  { label: "Seekers/Explorers", value: "explorer" },
];
export const approveRadios = [
  { label: "Approve User", value: "approveUser" },
  { label: "Reject User", value: "rejectPhoto" },
];
export const feedbackRadios = [{ label: "Skip this report", value: "skipThisReport" }];
export const blockReasonSelect = [
  { label: "Minor safety", value: "Minor safety" },
  { label: "Adult nudity and sexual activities", value: "Adult nudity and sexual activities" },
  { label: "Bulling and harassmen", value: "Bulling and harassmen" },
  { label: "Illegal activities and regulated goods", value: "Illegal activities and regulated goods" },
  { label: "Other", value: "Other" },
];
export const BlockReason = [
  "Minor safety",
  "Adult nudity and sexual activities",
  "Bulling and harassment",
  "Illegal activities and regulated goods",
  "Other",
];
export const profileTabs = [
  { label: "Account Details", value: "AccountDetails" },
  { label: "Business Details", value: "BusinessDetails" },
  { label: "Posts", value: "Posts" },
];
export const industries = [
  "Information Technology and Services",
  "Hospital & Health Care",
  "Construction",
  "Retail",
  "Education Management",
  "Financial Services",
  "Accounting",
  "Computer Software",
  "Higher Education",
  "Automotive",
  "Government Administration",
  "Marketing & Advertising",
  "Banking",
  "Health, Welness & Fitness",
  "Real Estate",
  "Food & Beverages",
  "Telecommunications",
  "Oil & Energy",
  "Hospitality",
  "Mechanical or Industrial Engineering",
  "Primary/Secondary Education",
  "Internet",
  "Electrical/Electronic Manufacturing",
  "Insurance",
  "Medical Practice",
  "Human Resources",
  "Consumer Services",
  "Transportation/Trucking/Railroad",
  "Pharmaceuticals",
  "Restaurants",
  "Management Consulting",
  "Civil Engineering",
  "Research",
  "Design",
  "Logistics & Supply Chain",
  "Law Practice",
  "Architecture & Planning",
  "Apparel & Fashion",
  "Consumer Goods",
  "Facilities Services",
  "Food Production",
  "Non-Profit Organization Management",
  "Machinery",
  "Entertainment",
  "Chemicals",
  "Wholesale",
  "Arts & Crafts",
  "Utilities",
  "Farming",
  "Legal Services",
  "Mining & Metals",
  "Airlines/Aviation",
  "Leisure, Travel & Tourism",
  "Sports",
  "Building Materials",
  "Environmental Services",
  "Professional Training & Coaching",
  "Medical Devices",
  "Music",
  "Individual & Family Services",
  "Cosmetics",
  "Mental Health Care",
  "Industrial Automation",
  "Security & Investigations",
  "Staffing & Recruiting",
  "Aviation & Aerospace",
  "Graphic Design",
  "Biotechnology",
  "Textiles",
  "Import and Export",
  "Consumer Electronics",
  "Public Relations and Communications",
  "Broadcast Media",
  "Business Supplies & Equipment",
  "Writing & Editing",
  "Military",
  "Media Production",
  "Computer Networking",
  "International Trade and Development",
  "Renewables & Environment",
  "Events Services",
  "Civic & Social Organization",
  "Photography",
  "Computer Hardware",
  "Defense & Space",
  "Furniture",
  "Computer & Network Security",
  "Printing",
  "Fine Art",
  "Investment Management",
  "E-Learning",
  "Outsourcing/Offshoring",
  "Warehousing",
  "Law Enforcement",
  "Publishing",
  "Religious Institutions",
  "Maritime",
  "Information Services",
  "Supermarkets",
  "Executive Office",
  "Animation",
  "Government Relations",
  "Semiconductors",
  "Program Development",
  "Plastics",
  "Online Media",
  "Public Safety",
  "Packaging and Containers",
  "Judiciary",
  "Alternative Medicine",
  "Performing Arts",
  "Commercial Real Estate",
  "Motion Pictures & Film",
  "Veterinary",
  "Computer Games",
  "Luxury Goods & Jewelry",
  "International Affairs",
  "Investment Banking",
  "Market Research",
  "Wine & Spirits",
  "Package/Freight Delivery",
  "Newspapers",
  "Translation & Localization",
  "Recreational Facilities & Services",
  "Sporting Goods",
  "Public Policy",
  "Capital Markets",
  "Paper & Forest Products",
  "Libraries",
  "Wireless",
  "Venture Capital & Private Equity",
  "Gambling & Casinos",
  "Ranching",
  "Glass, Ceramics & Concrete",
  "Philanthropy",
  "Dairy",
  "Museums and Institutions",
  "Shipbuilding",
  "Think Tanks",
  "Political Organization",
  "Fishery",
  "Fund-Raising",
  "Tobacco",
  "Railroad Manufacture",
  "Alternative Dispute Resolution",
  "Nanotechnology",
  "Legislative Office",
  "Mobile Games",
];
