import axios from "axios";
import { BASE_URL } from "../constants";
import { getNewAccessToken } from "../services/auth.service";
import {
  getAccessToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "../utils/tokenStorage";
let isRefreshing = false;
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessToken();

    if (accessToken) {
      //@ts-ignore
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;

    if (status === 401 && !originalRequest._isRetry && !isRefreshing) {
      isRefreshing = true;

      return getNewAccessToken()
        .then(({ data }) => {
          originalRequest._isRetry = true;
          if (data?.success) {
            setAccessToken(data?.tokens?.accessToken);
            setRefreshToken(data?.tokens?.refreshToken);
          } else {
            removeAccessToken();
            removeRefreshToken();
            window.location.reload();
          }
          originalRequest.headers["Authorization"] =
            "Bearer " + data?.tokens?.accessToken;
          isRefreshing = false;
          return axiosInstance.request(originalRequest);
        })
        .catch((e) => {
          isRefreshing = false;

          removeAccessToken();
          removeRefreshToken();
          window.location.reload();
        });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
