import { ArrowUpIcon } from "../../assets";
import styles from "./styles.module.scss";

export default function Increase({ percent }: { percent: string }) {
  return (
    <div className={styles.statsBody}>
      <div className={styles.arrowBody}>
        <ArrowUpIcon width={"10px"} className={styles.arrowUp} />
      </div>
      <p className={styles.increaseInfo}>{percent}</p>
    </div>
  );
}
