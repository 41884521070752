import { FC, ReactNode, useEffect } from "react";
// import { SignIn } from "../pages";
import { Routes, Route, Navigate, BrowserRouter, useNavigate, useHref, useLocation } from "react-router-dom";
import SignIn from "../pages/SignIn";
import Statistics from "../pages/Statistics";
import BusinessOwners from "../pages/BusinessOwners";
import BusinessExplorers from "../pages/BusinessExplorers";
import Verification from "../pages/Verification";
import Feedback from "../pages/Feedback";
import StaffManagement from "../pages/Staff Management";
import BusinessProfile from "../pages/BusinessProfile";
import ExplorerProfile from "../pages/ExplorerProfile";
import PrivateRoute from "./PrivateRoute";
import { getAccessToken } from "../utils/tokenStorage";
import OwnerVerificationProfile from "../pages/Verification/OwnerVerificationProfile";
import ExplorerVerificationProfile from "../pages/Verification/ExplorerVerificationProfile";
// import PrivateRoute from "./PrivateRoute";
// import { getUser } from "../utils/tokenStorage";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Guard>
        <Routes>
          <Route path="signIn" element={<SignIn />} />
          {/* <Route path="/*" element={<PrivateRoute />} /> */}
          <Route index path="statistics" element={<Statistics />} />
          <Route path="business-owners" element={<BusinessOwners />} />
          <Route path="business-owners/:id" element={<BusinessProfile />} />
          <Route path="business-explorers/:id" element={<ExplorerProfile />} />
          <Route index path="business-explorers" element={<BusinessExplorers />} />
          <Route path="verification" element={<Verification />} />
          <Route path="verification/owner/:id" element={<OwnerVerificationProfile />} />
          <Route path="verification/explorer/:id" element={<ExplorerVerificationProfile />} />
          <Route index path="feedback" element={<Feedback />} />
          <Route path="staff-management" element={<StaffManagement />} />
          <Route path="*" element={<Navigate to="/statistics" replace />} />
        </Routes>
      </Guard>
    </BrowserRouter>
  );
};
export default Navigation;

const Guard = ({ children }: { children: ReactNode }) => {
  const token = getAccessToken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token && location.pathname !== "/signIn") {
      navigate("/signIn");
    }
  }, [token, location, navigate]);
  return <>{children}</>;
};
