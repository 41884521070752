import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../assets";
// import { Button, Radio, Textarea } from "../../components";
import PlaceholderAvatar from "./../../assets/images/avatar.png";
// import { blockCandidate } from "../../services/candidate.service";
// import { blockEmployer } from "../../services/employer.service";
import styles from "./styles.module.scss";
import Textarea from "../../components/Textarea";
import Button from "../../components/Button";
import Tag from "../../components/Tags";
import UserInfoBlock from "../../components/UserInfoBlock";
import RadioSettings from "../../components/Selector";
import { approveRadios, blockReasonSelect, feedbackRadios, toastError } from "../../constants";
import StaffItem from "../../components/StaffItem";
import { IReportedOnInfo, IReporterInfo } from "../../models/IFeedback";
import { toast } from "react-hot-toast";
import { blockUser, deleteUSer } from "../../services/businessOwners.service";
import { blockUserExplorers, deleteUserExplorer } from "../../services/businessExplorers.service";
import { resolveFeedback } from "../../services/feedback.service";
import { getUser } from "../../utils/tokenStorage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  reporter: IReporterInfo;
  category: string;
  reportedOn?: IReportedOnInfo;
  date: string;
  description: string;
  attachmentImage?: string;
  userId: string;
  reportedId: string | undefined;
  feedBackId: string;
  onFeedback: (page: number, limit: number, search: string) => void;
}
const ReportModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  reporter,
  category,
  reportedOn,
  date,
  description,
  userId,
  reportedId = "",
  feedBackId,
  attachmentImage,
  onFeedback,
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [blockReason, setBlockReason] = useState<string>("Other");
  const [approve, setApprove] = useState("skipThisReport");
  const [otherReason, setOtherReason] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const me = getUser();

  const onChangeOtherReason = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(e.target.value);
  };
  const onBlockAccount = async () => {
    if (reportedOn?.[0]?.role === "owner") {
      try {
        setDisableButton(true);
        setIsLoading(true);
        const { data } = await blockUser(
          reportedId || "",
          blockReason === "Other" ? otherReason : blockReason,
          "block",
        );
        await resolveFeedback(feedBackId);
        if (data?.success) {
          setIsLoading(false);
          toast.success("User has been blocked");
          onFeedback(1, 20, "");
          setDisableButton(false);
        }
      } catch (error) {
        toast.error(toastError);
        setIsLoading(false);
        setDisableButton(false);
      }
      return;
    }
    if (reportedOn?.[0]?.role === "explorer") {
      try {
        setIsLoading(true);
        setDisableButton(true);
        const { data } = await blockUserExplorers(
          reportedId || "",
          blockReason === "Other" ? otherReason : blockReason,
          "block",
        );
        await resolveFeedback(feedBackId);
        if (data?.success) {
          setIsLoading(false);
          toast.success("User has been blocked");
          onFeedback(1, 20, "");
          setDisableButton(false);
        }
      } catch (error) {
        toast.error(toastError);
        setIsLoading(false);
        setDisableButton(false);
      }
      return;
    }
  };
  const onDeleteAccount = async () => {
    if (reportedOn?.[0]?.role === "owner") {
      try {
        setDisableButton(true);

        setIsLoading(true);
        const { data } = await deleteUSer(reportedId || "0");
        await resolveFeedback(feedBackId);
        if (data?.success) {
          setIsLoading(false);
          toast.success("User has been successfully deleted");
          onFeedback(1, 20, "");
          setDisableButton(false);
        }
      } catch (error) {
        toast.error(toastError);
        setIsLoading(false);
        setDisableButton(false);
      }
      return;
    }
    if (reportedOn?.[0]?.role === "explorer") {
      try {
        setIsLoading(true);
        setDisableButton(true);

        const { data } = await deleteUserExplorer(reportedId || "0");
        await resolveFeedback(feedBackId);
        if (data?.success) {
          setIsLoading(false);
          toast.success("User has been successfully deleted");
          onFeedback(1, 20, "");
          setDisableButton(false);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(toastError);
        setDisableButton(false);
      }
      return;
    }
  };

  const sendHandler = async () => {
    if (approve === "block") {
      onBlockAccount();
    } else if (approve === "delete") {
      onDeleteAccount();
    } else {
      try {
        setDisableButton(true);

        const { data } = await resolveFeedback(feedBackId);
        if (data?.success) {
          setDisableButton(false);
          setIsLoading(false);
          toast.success("Feedback resolved");
          onFeedback(1, 20, "");
        }
      } catch (error) {
        setDisableButton(false);
        setIsLoading(false);
        toast.error(toastError);
      }
    }
    // navigate(-1);
  };

  useEffect(() => {
    const contentWrapper = document.querySelector(".wrapper__body__content");
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.overflowX = "hidden";
    }
  }, [isOpen]);

  function whichRole(role: string) {
    if (role?.toLowerCase() === "owner") {
      return "Business Owner";
    } else if (role?.toLowerCase() === "explorer") {
      return "Business Explorer";
    } else {
      return "Super Admin";
    }
  }
  function formatDate(dateString: string) {
    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = MONTHS[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
      className={styles.modal}
      ariaHideApp={false}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h5>Report</h5> <CloseIcon onClick={() => setIsOpen(false)} className={styles.closeBtn} />
        </div>
        <div className={styles.modalBody}>
          <div className={styles.mainInfo}>
            <p className={styles.category}>{category}</p>
            <div className={styles.reportersContainer}>
              <div className={styles.staffBody}>
                <span className={styles.repName}>Reporter</span>
                <div className={styles.staff}>
                  {reporter ? (
                    <StaffItem
                      image={reporter?.avatar}
                      name={`${reporter?.firstName || ""} ${reporter?.lastName || ""}`}
                      description={whichRole(reporter?.role)}
                    />
                  ) : (
                    <StaffItem image={null} name={`Deleted user`} />
                  )}
                </div>
              </div>
              {reportedOn && reportedOn?.length > 0 && (
                <div className={styles.staffBody}>
                  <span className={styles.repName}>Reported on</span>
                  <div className={styles.staff}>
                    <StaffItem
                      image={reportedOn?.[0]?.avatar || null}
                      name={`${reportedOn?.[0]?.firstName} ${reportedOn?.[0]?.lastName}`}
                      description={whichRole(reportedOn?.[0]?.role)}
                    />
                  </div>
                </div>
              )}
              <span className={styles.date}>{formatDate(date)}</span>
            </div>
          </div>
          <div className={styles.descriptionBody}>
            <span>Description</span>
            <p>{description}</p>
          </div>
          {attachmentImage && (
            <div className={styles.attachment}>
              <div className={styles.attachName}>
                <span>Attachment</span>
              </div>
              <div className={styles.attachmentBody}>
                {!imageLoaded && <Skeleton width={300} height={200} />}
                <img src={attachmentImage} onLoad={() => setImageLoaded(true)} />
              </div>
            </div>
          )}
          {me?.role !== "manager" && (
            <div className={styles.approveBody}>
              <div className={styles.approveSection}>
                <div className={styles.radio}>
                  <RadioSettings options={feedbackRadios} name="report" value={approve} onChange={setApprove} />
                  {reportedOn && reportedOn?.length > 0 && (
                    <div>
                      <RadioSettings
                        options={[
                          { label: `Block ${reportedOn?.[0]?.firstName} ${reportedOn?.[0]?.lastName}`, value: "block" },
                        ]}
                        name="report"
                        value={approve}
                        onChange={setApprove}
                      />
                    </div>
                  )}
                </div>
                {approve === "block" && reportedOn && reportedOn?.length > 0 && (
                  <div className={styles.subRadio}>
                    <p>Choose a reason:</p>
                    <RadioSettings
                      options={blockReasonSelect}
                      name="reason"
                      value={blockReason}
                      onChange={setBlockReason}
                    />
                    {blockReason === "Other" && (
                      <Textarea
                        placeholder="Message"
                        value={otherReason}
                        onChange={onChangeOtherReason}
                        maxLength={240}
                        style={{ maxHeight: "110px", width: "91%" }}
                      />
                    )}
                  </div>
                )}
                {reportedOn && reportedOn?.length > 0 && (
                  <div className={styles.radio}>
                    <RadioSettings
                      options={[
                        {
                          label: `Delete ${reportedOn?.[0]?.firstName} ${reportedOn?.[0]?.lastName}'s account`,
                          value: "delete",
                        },
                      ]}
                      name="report"
                      value={approve}
                      onChange={setApprove}
                    />
                  </div>
                )}
              </div>
              <div className={styles.button}>
                <Button title="Send" onClick={sendHandler} disabled={disableButton} />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReportModal;
