import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../assets/images/avatar.png";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useState } from "react";
interface Props {
  image: null | string;
  name: string;
  description?: string;
  small?: boolean;
}

const StaffItem = ({ image, name, description, small }: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={clsx({ [styles.avatarWrapper]: !small, [styles.avatarWrapperSmall]: small })}>
      <div style={{ position: "relative" }} className={styles.avatar}>
        {!imageLoaded && <Skeleton style={{ position: "absolute" }} width={300} height={200} />}
        <img src={image ? image : PlaceholderAvatar} alt="avatar" onLoad={() => setImageLoaded(true)} />
      </div>
      <p className={styles.name}>
        {name} {description && <span>{description}</span>}
      </p>
    </div>
  );
};
export default StaffItem;
