import Decrease from "../Statistic/Decrease";
import Increase from "../Statistic/Increase";
import styles from "./styles.module.scss";
interface Props {
  title: string;
  subtitle: string;
  percent: string;
  todayInfo: string;
  isIncrease: boolean;
}
export default function StatisticInfo({ title, subtitle, percent, todayInfo, isIncrease }: Props) {
  return (
    <div>
      <h3 className={styles.subtitle}>{title}</h3> <h4 className={styles.infoPerDay}>{subtitle}</h4>
      <div className={styles.divider}></div>
      <div>
        <span className={styles.today}>Today</span>
        <div className={styles.todayWrapper}>
          <h5>{todayInfo}</h5>
          {isIncrease ? <Increase percent={percent} /> : <Decrease percent={percent} />}
        </div>
      </div>
    </div>
  );
}
