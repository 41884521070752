import React, { useEffect, useRef, useState } from "react";
import ContentWrapper from "../Layout/ContentWrapper";
import RadioSettings from "../Selector";
import { genderSelect, industries, reportersSelect } from "../../constants";
import styles from "./style.module.scss";
import Select from "../Select";
import Button from "../Button";
import Checkbox from "../Checkbox";
import UserSelect from "../Select/UserSelect";
import GoogleInput from "../Input/GoogleInput";
import clsx from "clsx";
import SimpleMultiSelect from "../Select/SimpleMultiSelect";

export default function OwnerFilter({
  onClose,
  onApply,
  users,
}: {
  onClose: (value: boolean) => void;
  onApply?: (
    gender: string,
    mobilityStatus: string,
    location: { city: string; state?: string; country: string },
    type: string,
    age: string,
    employers: string,
    revenue: string,
    businessIndustry: string[],
    businessNeeds: string[],
  ) => void;
  users?: any;
}) {
  const [gender, setGender] = useState("All");
  const [reporters, setReporters] = useState("all");
  const [businessType, setBusinessType] = useState("All");
  const [age, setAge] = useState("All");
  const [mobilityStatus, setMobilityStatus] = useState("All");

  const [revenue, setRevenue] = useState("All");
  const [employees, setEmployees] = useState("All");
  const [businessIndustry, setBusinessIndustry] = useState(["All"]);
  const [businessNeeds, setBusinessNeeds] = useState(["All"]);
  const [reportsFrom, setReportsFrom] = useState();
  const [location, setLocation] = useState<{ city: string; state?: string; country: string }>({
    city: "",
    state: "",
    country: "",
  });
  useEffect(() => {
    if (users) setReportsFrom(users[0]);
  }, [users]);

  const onClearHandler = () => {
    setGender("All");
    setMobilityStatus("All");
    setLocation({ city: "", country: "", state: "" });
    setBusinessType("All");
    setAge("All");
    setRevenue("All");
    setEmployees("All");
    setBusinessIndustry(["All"]);
    setBusinessNeeds(["All"]);
  };

  // useEffect(() => {
  //   if (isIndustryAll) {
  //     setIsEducation(true);
  //     setIsConstruction(true);
  //     setIsDesign(true);
  //     setIsCorporate(true);
  //     setIsRetail(true);
  //     setIsEnergy(true);
  //     setIsManufacturing(true);
  //     setIsFinance(true);
  //   }
  //   if (!isIndustryAll) {
  //     setIsEducation(false);
  //     setIsConstruction(false);
  //     setIsDesign(false);
  //     setIsCorporate(false);
  //     setIsRetail(false);
  //     setIsEnergy(false);
  //     setIsManufacturing(false);
  //     setIsFinance(false);
  //   }
  // }, [isIndustryAll]);

  return (
    <ContentWrapper>
      <div className={styles.filterWrapper}>
        <div className={styles.filterHeader}>
          <p>Filter by</p>{" "}
          <span className={styles.clear} onClick={onClearHandler}>
            Clear all filters
          </span>
        </div>{" "}
        <div className={styles.radioBody}>
          <p>Gender</p>
          <RadioSettings
            options={[
              { label: "All", value: "All" },
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
            ]}
            name="gender"
            value={gender}
            onChange={setGender}
          />
          <p>Mobility status</p>
          <RadioSettings
            options={[
              { label: "All", value: "All" },
              { label: "Transient", value: "Transient" },
              { label: "Permanently based", value: "Permanently based" },
            ]}
            name="gender"
            value={mobilityStatus}
            onChange={setMobilityStatus}
          />
        </div>
        <div className={styles.dividerX}></div>
        <div className={clsx(styles.locationBody, styles.locationOwnerBody)}>
          <GoogleInput
            isFilter
            label="Business Location"
            placeholder="Write location"
            value={
              location.city && location.country
                ? `${location.city},${
                    location.state || (location.state && location.state?.length > 0) ? `${location.state}, ` : ""
                  } ${location.country}`
                : ""
            }
            onChange={(e) => {
              if (e.target.value.length === 0) setLocation({ city: "", state: "", country: "" });
            }}
            onSelect={setLocation}
          />
        </div>
        <div className={styles.dividerX}></div>
        <div>
          <Select
            label="Business Type"
            options={["All", "Startup", "Small business", "Medium business", "Large business"]}
            value={businessType}
            onChange={setBusinessType}
          />
          <div className={styles.dividerX}></div>
          <div>
            <Select label="Age" options={["All", "18-29", "30-50", "50+"]} value={age} onChange={setAge} />
          </div>
        </div>
        <div className={styles.dividerX}></div>
        <div>
          {" "}
          <Select
            label="Employees"
            options={["All", "0 - 50", "50 - 100", "100+"]}
            value={employees}
            onChange={setEmployees}
          />
          <div className={styles.dividerX}></div>
          <div style={{ marginTop: "10px" }}>
            <Select
              label="Revenue"
              options={["All", "0 - 100k", "100k - 250k ", " 250k - 500k ", "500k - 700k ", "700k +"]}
              value={revenue}
              onChange={setRevenue}
            />
          </div>
        </div>
        <div className={styles.dividerX}></div>
        {/* <div className={styles.checkboxesBody}> */}
        <div className={styles.radioBody}>
          <SimpleMultiSelect
            value={businessIndustry}
            onChange={setBusinessIndustry}
            label="Industry"
            options={industries}
          />
        </div>
        <div className={styles.dividerX}></div>
        <SimpleMultiSelect
          value={businessNeeds}
          onChange={setBusinessNeeds}
          label="Business Needs"
          options={[
            "All",
            "Local Staff",
            "Diaspora Staff",
            "Partnership",
            "Funding",
            "Additional needs",
            "Contract & consulting opportunities",
            "Contractors",
            "Suppliers",
            "Buyers",
          ]}
        />
        <div
          onClick={() => {
            onClose(false);
            onApply?.(
              gender,
              mobilityStatus,
              location,
              businessType,
              age,
              employees,
              revenue,
              businessIndustry,
              businessNeeds,
            );
          }}
          className={styles.applyButton}
        >
          <Button title="Apply" />
        </div>
      </div>
    </ContentWrapper>
  );
}
