import styles from "./styles.module.scss";

interface Prorps {
  title: string;
  subtitle: number;
  statusPercent: number;
}

export default function Progress({ title, subtitle, statusPercent }: Prorps) {
  return (
    <div className={styles.infoBody}>
      <div className={styles.infoContentBody}>
        <div className={styles.infoWrapper}>
          <span className={styles.title}>{title}</span>
          <span className={styles.statusPercent}>{statusPercent}%</span>
        </div>
      </div>
      <span className={styles.subtitle}>{subtitle}</span>
      <div className={styles.progressBar}>
        <ProgressBar value={Math.ceil(statusPercent)} />
      </div>
    </div>
  );
}

const ProgressBar = ({ value }: { value: number }) => {
  return (
    <div className={styles.progressBar}>
      <div className={styles.fill} style={{ width: `${value}%` }}></div>
    </div>
  );
};
