import { FC, useEffect, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../assets/images/avatar.png";
// import ReportType from "../ReportType";
import { ArrowDownIcon } from "../../assets";
// import { Report } from "../../modals";
import { useNavigate } from "react-router-dom";
import StaffItem from "../StaffItem";
import { IOwner } from "../../models/IOwner";
// import { IEmployer } from "../../models";

// interface Props {
//   item: IEmployer;
// }
interface Props {
  id: string;
  image: null;
  name: string;
  description: string;
  ownerLocation: string;
  businessLocation: string;
  businessType: string;
  revenue: string;
  employees: string;
}

const OwnersItem = ({
  item,
  allChecked,
  setDeleteOwners,
}: {
  item: IOwner;
  allChecked: boolean;
  setDeleteOwners: (item: any) => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(allChecked);
  }, [allChecked]);

  useEffect(() => {
    if (isChecked) {
      setDeleteOwners((current: any) => [...current, item.id]);
    }
    if (!isChecked) setDeleteOwners((current: any) => current.filter((id: any) => id != item.id));
  }, [isChecked]);
  // FC<Props>
  const navigate = useNavigate();

  let revenue;
  if (!item?.business[0]?.revenue) {
    revenue = "";
  } else if (item?.business[0]?.revenue && item?.business[0]?.revenue?.min === item?.business[0]?.revenue?.max) {
    revenue = `${item?.business[0]?.revenue?.min}k +`;
  } else {
    revenue = `${
      item?.business[0]?.revenue?.min > 0 ? item?.business[0]?.revenue?.min + "k" : item?.business[0]?.revenue?.min || 0
    } - ${item?.business[0]?.revenue?.max || 100}k`;
  }

  return (
    <div className={styles.businessBody}>
      <div className={styles.wrapper}>
        <div className={styles.checkbox}>
          <CheckBox isChecked={isChecked} setIsChecked={() => setIsChecked((current) => !current)} />
        </div>
        <div onClick={() => navigate(`/business-owners/${item.id}`)}>
          <StaffItem
            image={item.avatar}
            name={`${item.firstName} ${item.lastName}`}
            description={item.business[0]?.name}
          />
        </div>
        <div onClick={() => navigate(`/business-owners/${item.id}`)}>
          <div className={styles.ownerLocationWrapper}>
            <p>
              {" "}
              {item.location.city
                ? `${item?.location.city} ${item?.location.state ? `, ${item?.location.state}` : ""}` || ""
                : ""}
            </p>
          </div>
        </div>
        <div onClick={() => navigate(`/business-owners/${item.id}`)}>
          <div className={styles.businessLocationWrapper}>
            <p>
              {item.business[0]?.location.city
                ? `${item.business[0]?.location.city} ${
                    item.business[0]?.location.state ? `, ${item.business[0]?.location.state}` : ""
                  }` || ""
                : ""}
            </p>
          </div>
        </div>
        <div onClick={() => navigate(`/business-owners/${item.id}`)}>
          <div className={styles.businessTypeWrapper}>
            <p>{item.business[0]?.type || ""}</p>
          </div>
        </div>
        <div onClick={() => navigate(`/business-owners/${item.id}`)}>
          <div className={styles.revenueWrapper}>
            <p>{revenue}</p>
          </div>
        </div>
        <div onClick={() => navigate(`/business-owners/${item.id}`)}>
          <div className={styles.employeesWrapper}>
            <p>
              {typeof item.business[0]?.employees.min !== "undefined"
                ? item.business[0]?.employees.min === item.business[0]?.employees.max
                  ? `${item.business[0]?.employees.min}+`
                  : typeof item.business[0]?.employees.min === "number" &&
                    typeof item.business[0]?.employees.max === "number"
                  ? `${item.business[0]?.employees.min} - ${item.business[0]?.employees.max}`
                  : ""
                : ""}
            </p>
          </div>
        </div>
      </div>
      <div onClick={() => navigate(`/business-owners/${item.id}`)} className={styles.select}>
        <div className={styles.arrowBtn}>
          <ArrowDownIcon width={"15px"} fill="white" />
        </div>
      </div>
    </div>
  );
};
{
  /* <div className={styles.dateWrapper}>

<div className={styles.arrowBtn}>
  <ArrowDownIcon width={"15px"} fill="white" />
</div>
</div> */
}
export default OwnersItem;
