import { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Progress from "../../components/Progress";
import StatisticInfo from "../../components/StatisticInfo/StatisticInfo";
import styles from "./styles.module.scss";
import Charts from "../../components/Charts/Charts";
import { PulseLoader } from "react-spinners";
import { ExplorersPercent, getStatistics } from "../../services/statistics.service";
import { NumbersContext } from "../../components/VerificationCounterContext/VerificationProvider";

export default function Statistics() {
  const [currentTime, setCurrentTime] = useState<"week" | "year" | "month">("year");
  const [isLoading, setIsLoading] = useState(true);
  const [statisticsData, setStatisticsData] = useState<ExplorersPercent>();
  const verificationTotalCount = useContext(NumbersContext);

  const onGetStatistics = async (period: "week" | "year" | "month") => {
    try {
      setIsLoading(true);
      const { data } = await getStatistics(period);

      if (data?.success) {
        setStatisticsData(data);
        verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    onGetStatistics(currentTime);
  }, []);

  const staticInfo = [
    {
      title: "Business Owners",
      subtitle: `${statisticsData?.totalOwners}`,
      todayInfo: `${statisticsData?.ownersToday}`,
      percent: `${Math.abs(Math.round(statisticsData?.ownersPercent || 0))}%`,
      isIncrease: typeof statisticsData?.ownersPercent === "number" && statisticsData?.ownersPercent > 0 ? true : false,
    },
    {
      title: "Business Explorers",
      subtitle: `${statisticsData?.totalExplorers}`,
      todayInfo: `${statisticsData?.explorersToday}`,
      percent: `${Math.abs(Math.round(statisticsData?.explorersPercent || 0))}%`,
      isIncrease:
        typeof statisticsData?.explorersPercent === "number" && statisticsData?.explorersPercent > 0 ? true : false,
    },
    {
      title: "Listings",
      subtitle: `${statisticsData?.totalPosts}`,
      todayInfo: "0",
      percent: `${Math.abs(Math.round(statisticsData?.postsPercent || 0))}%`,
      isIncrease: typeof statisticsData?.postsPercent === "number" && statisticsData?.postsPercent > 0 ? true : false,
    },
  ];
  const progress = [
    {
      title: "Education",
      subtitle: statisticsData?.totalEducation || 0,
      statusPercent: Math.abs(Math.round(statisticsData?.educationPercent || 0)),
    },
    {
      title: "Construction",
      subtitle: statisticsData?.totalConstruction || 0,
      statusPercent: Math.abs(Math.round(statisticsData?.constructionPercent || 0)),
    },
    {
      title: "Design",
      subtitle: statisticsData?.totalDesign || 0,
      statusPercent: Math.abs(Math.round(statisticsData?.designPercent || 0)),
    },
    {
      title: "Corporate Services",
      subtitle: statisticsData?.totalCorporateServices || 0,
      statusPercent: Math.abs(Math.round(statisticsData?.corporateServicesPercent || 0)),
    },
    {
      title: "Retail",
      subtitle: statisticsData?.totalRetail || 0,
      statusPercent: Math.abs(Math.round(statisticsData?.retailPercent || 0)),
    },
    {
      title: "Energy & Mining",
      subtitle: statisticsData?.totalEnergyMining || 0,
      statusPercent: Math.abs(Math.round(statisticsData?.energyMiningPercent || 0)),
    },
    {
      title: "Manufacturing",
      subtitle: statisticsData?.totalManufacturing || 0,
      statusPercent: Math.abs(Math.round(statisticsData?.manufacturingPercent || 0)),
    },
    {
      title: "Finance",
      subtitle: statisticsData?.totalFinance || 0,
      statusPercent: Math.abs(Math.round(statisticsData?.financePercent || 0)),
    },
  ];
  return (
    <Layout title={"Statistics"}>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <PulseLoader color={"#236BFE"} />
        </div>
      ) : (
        <>
          <div className={styles.statisticBody}>
            <div className={styles.statistic}>
              <StatisticInfo
                percent={`${Math.abs(Math.round(statisticsData?.usersPercent || 0))}%`}
                subtitle={`${statisticsData?.totalUsers}`}
                title="All Users"
                todayInfo={`${statisticsData?.usersToday}`}
                isIncrease={
                  typeof statisticsData?.usersPercent === "number" && statisticsData?.usersPercent > 0 ? true : false
                }
              />
            </div>
            <div className={styles.chartBody}>
              <div className={styles.chart}>
                <Charts setCurrentTime={setCurrentTime} data={statisticsData?.totalPeriod} />
              </div>
            </div>
          </div>
          <div className={styles.commonInfo}>
            <div className={styles.dividerTop}></div>
            {staticInfo.map((statiscit, index) => {
              return (
                <div key={index} className={styles.statisticInfo}>
                  <div className={styles.dividerLeft}></div>
                  <StatisticInfo
                    percent={statiscit.percent}
                    subtitle={statiscit.subtitle ? `${statiscit.subtitle}` : ""}
                    title={statiscit.title}
                    todayInfo={statiscit.todayInfo ? `${statiscit.todayInfo}` : ""}
                    isIncrease={statiscit.isIncrease}
                  />
                  <div className={styles.dividerRight}></div>
                </div>
              );
            })}
            <div className={styles.dividerBottom}></div>
          </div>
          <div>
            <h3 className={styles.titleProgress}>Top Industries</h3>
            <div className={styles.progressWrapper}>
              {progress.map((progressItem, index) => {
                return <Progress key={index} {...progressItem} />;
              })}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}
