// import { IStaff } from "../models";
import jwt_decode from "jwt-decode";
import { IStaff } from "../models/IStaff";

export const setAccessToken = (token: string) => {
  let decoded: any = jwt_decode(token); // : IStaff
  localStorage.setItem("accessToken", token);
  setUser(decoded);
};

export const removeAccessToken = () => {
  localStorage.removeItem("accessToken");
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem("accessToken");
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export const removeRefreshToken = () => {
  localStorage.removeItem("refreshToken");
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem("refreshToken");
};

export const setUser = (user: any) => {
  // : IStaff
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem("user");
};

export const getUser = (): IStaff | null => {
  const user = localStorage.getItem("user");
  if (user === null || user === undefined) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
