import { ChangeEvent, useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import styles from "./styles.module.scss";
import Input from "../../components/Input";
import { ArrowDownIcon, FilterIcon, SearchIcon } from "../../assets";
import clsx from "clsx";
import Checkbox from "../../components/Checkbox";
import { PulseLoader } from "react-spinners";
import PageItem from "../../components/PageItem";
import Filter from "../../components/Filter";
import BusinessItem from "../../components/BusinessItem";
import { deleteUserExplorer, getAllBusinessExolorers } from "../../services/businessExplorers.service";
import Empty from "../../components/Empty/Index";
import { IExplorer } from "../../models/IExplorers";
import Button from "../../components/Button";
import Delete from "../../modals/Delete";
import { toastError } from "../../constants";
import { toast } from "react-hot-toast";
import { NumbersContext } from "../../components/VerificationCounterContext/VerificationProvider";
import { getUser } from "../../utils/tokenStorage";
import ExplorerFilter from "../../components/Filter/ExplorerFilter";
const limit = 20;

export default function BusinessOwners() {
  const [openFilter, setOpenFilter] = useState(false);
  const [deleteOwners, setDeleteOwners] = useState<string[]>([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState(false);
  const [owners, setOwners] = useState<IExplorer[]>([]);
  const [firstNameSort, setFirstNameSort] = useState<1 | -1 | 0>(0);
  const [lastNameSort, setLastNameSort] = useState<1 | -1 | 0>(0);
  const [citySort, setCitySort] = useState<1 | -1 | 0>(0);
  const [phoneSort, setPhoneSort] = useState<1 | -1 | 0>(0);
  const [emailSort, setEmailSort] = useState<1 | -1 | 0>(0);
  const [createdAtSort, setCreatedAtSort] = useState<1 | -1 | 0>(0);
  const [currentSorts, setCurrentSort] = useState("");
  const [currentFilter, setCurrentFilter] = useState<any>();
  const verificationTotalCount = useContext(NumbersContext);
  const me = getUser();
  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const onGetExplorers = async (
    page: number = 1,
    limit: number,
    search: string = "",
    type?: "firstName" | "lastName" | "location.city" | "phone" | "email" | "createdAt",
    filter?: any,
  ) => {
    try {
      setIsLoading(true);
      let sort;
      switch (type) {
        case "firstName":
          sort = { firstName: firstNameSort };
          break;
        case "lastName":
          sort = { lastName: lastNameSort };
          break;
        case "location.city":
          sort = { "location.city": citySort };
          break;
        case "phone":
          sort = { phone: phoneSort };
          break;
        case "email":
          sort = { email: emailSort };
          break;
        case "createdAt":
          sort = { createdAt: createdAtSort };
          break;
        default:
          break;
      }
      const { data } = await getAllBusinessExolorers(page, limit, search, sort || {}, filter || {});
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalExplorersNV / limit));
        setOwners(data?.explorers);
        verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        const sort = currentSorts?.length > 0 ? currentSorts : undefined;
        onGetExplorers(1, limit, search, sort as "firstName", currentFilter);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      const sort = currentSorts?.length > 0 ? currentSorts : undefined;
      onGetExplorers(1, limit, "", sort as "firstName", currentFilter);
    }
  }, [search]);

  const onDeleteEmployer = async () => {
    try {
      setIsLoading(true);
      const { data } = await deleteUserExplorer(deleteOwners);
      if (data?.success) {
        onGetExplorers(page, 20);
        toast.success("Users have been successfully deleted");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(toastError);
    }
  };

  const onApplyFilters = (
    gender: string,
    location: {
      country: string;
      state: string;
      city: string;
    },
    age: string,
    mobilityStatus: string,
    industry: string[],
    currentNeeds: string[],
  ) => {
    let filter: any = {};
    if (gender !== "All") {
      filter.gender = gender;
    }
    if (location.city.length > 0) {
      filter.location = {};
      filter.location.city = location.city;
      filter.location.state = location.state;
      filter.location.country = location.country;
    }
    if (mobilityStatus !== "All") {
      filter.mobilityStatus = [mobilityStatus];
    }
    if (age !== "All") {
      filter.age = {};
      //@ts-ignore
      filter.age.minAge = +age.match(/\d+/g)[0].trim();
      //@ts-ignore
      filter.age.maxAge = age.match(/\d+/g)?.length > 1 ? +age.match(/\d+/g)[1].trim() : +age.match(/\d+/g)[0].trim(); //@ts-ignore
    }
    if (industry.length > 0 && !(industry.length === 1 && industry.includes("All"))) {
      filter.industry = industry;
    }
    if (currentNeeds.length > 0 && !(currentNeeds.length === 1 && currentNeeds.includes("All"))) {
      filter.currentNeeds = currentNeeds;
    }

    if (Object.keys(filter).length === 0) {
      setCurrentFilter(undefined);
    } else {
      setCurrentFilter(filter);
    }
    const sort = currentSorts?.length > 0 ? currentSorts : undefined;
    onGetExplorers(page, limit, search, sort as "firstName", filter as {});
  };
  useEffect(() => {
    if (firstNameSort !== 0) {
      onGetExplorers(page, 20, search, "firstName", currentFilter);
      setCurrentSort("firstName");
    } else if (citySort !== 0) {
      onGetExplorers(page, 20, search, "location.city", currentFilter);
      setCurrentSort("location.city");
    } else if (phoneSort !== 0) {
      onGetExplorers(page, 20, search, "phone", currentFilter);
      setCurrentSort("phone");
    } else if (emailSort !== 0) {
      onGetExplorers(page, 20, search, "email", currentFilter);
      setCurrentSort("email");
    } else if (createdAtSort !== 0) {
      onGetExplorers(page, 20, search, "createdAt", currentFilter);
      setCurrentSort("createdAt");
    } else {
      onGetExplorers(page, 20, search, "nothing" as "firstName", currentFilter);
      setCurrentSort("");
    }
  }, [firstNameSort, citySort, phoneSort, emailSort, createdAtSort]);
  return (
    <>
      <Delete
        title="Delete user"
        onDelete={onDeleteEmployer}
        isLoading={isLoading || false}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        type="delete"
        text={`Are you sure you want to delete ${
          deleteOwners.length == 1 ? `1 user` : `${deleteOwners.length} users`
        }?`}
      />

      <Layout title={"Seekers/Explorers"}>
        <div className={styles.navBody}>
          <div className={styles.input}>
            <Input
              icon={SearchIcon}
              placeholder="Search"
              isEmpty={search.length === 0}
              isSearch
              onChange={onChangeSearch}
              style={{ width: "88%" }}
              setEmpty={() => setSearch("")}
            />
          </div>
          <div className={styles.filterBody}>
            <div className={clsx({ [styles.deleteOwnersButton]: deleteOwners.length > 0 })}>
              {deleteOwners.length > 0 && me?.role !== "manager" && (
                <Button
                  title={`Delete (${deleteOwners.length})`}
                  styleType="filled"
                  onClick={() => setIsShowDeleteModal(true)}
                  loading={isLoading}
                  style={{
                    border: "none",
                    width: "120px",
                    height: "43px",
                    background: "#FF3C3C",
                    borderRadius: "16px",
                  }}
                />
              )}
              <div
                onClick={(e) => setOpenFilter((current) => !current)}
                className={clsx({ [styles.filter]: !openFilter, [styles.filterSelected]: openFilter })}
              >
                <FilterIcon className={clsx({ [styles.svgOpen]: openFilter })} />
              </div>
            </div>
            <div className={clsx({ [styles.filterDetails]: !openFilter, [styles.filterDetailsOpen]: openFilter })}>
              <ExplorerFilter onClose={setOpenFilter} onApply={onApplyFilters} />
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <section className={styles.contentContainer}>
            <div className={styles.sectionHeader}>
              <div className={styles.checkbox}>
                <Checkbox isChecked={isChecked} setIsChecked={() => setIsChecked((current) => !current)} mainCheckBox />
              </div>
              <div
                onClick={() => {
                  setCitySort(0);
                  setPhoneSort(0);
                  setEmailSort(0);
                  setCreatedAtSort(0);
                  setFirstNameSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("firstName");
                  // onGetExplorers(page, 20, search, "firstName", currentFilter);
                }}
                className={clsx(styles.nameBlock, {
                  [styles.activeBlockAZ]: firstNameSort === 1,
                  [styles.activeBlockZA]: firstNameSort === -1,
                })}
              >
                <p>Name</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setPhoneSort(0);
                  setEmailSort(0);
                  setCreatedAtSort(0);
                  setCitySort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("location.city");
                  // onGetExplorers(page, 20, search, "location.city", currentFilter);
                }}
                className={clsx(styles.industryBlock, {
                  [styles.activeBlockAZ]: citySort === 1,
                  [styles.activeBlockZA]: citySort === -1,
                })}
              >
                <p>Location</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>

              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setCitySort(0);
                  setEmailSort(0);
                  setCreatedAtSort(0);
                  setPhoneSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("phone");
                  // onGetExplorers(page, 20, search, "phone", currentFilter);
                }}
                className={clsx(styles.locationBlock, {
                  [styles.activeBlockAZ]: phoneSort === 1,
                  [styles.activeBlockZA]: phoneSort === -1,
                })}
              >
                <p>Phone</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setCitySort(0);
                  setPhoneSort(0);
                  setCreatedAtSort(0);
                  setEmailSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("email");
                  // onGetExplorers(page, 20, search, "email", currentFilter);
                }}
                className={clsx(styles.sizeBlock, {
                  [styles.activeBlockAZ]: emailSort === 1,
                  [styles.activeBlockZA]: emailSort === -1,
                })}
              >
                <p>Email</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setCitySort(0);
                  setPhoneSort(0);
                  setEmailSort(0);
                  setCreatedAtSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("createdAt");
                  // onGetExplorers(page, 20, search, "createdAt", currentFilter);
                }}
                className={clsx(styles.sizeBlock, {
                  [styles.activeBlockAZ]: createdAtSort === 1,
                  [styles.activeBlockZA]: createdAtSort === -1,
                })}
              >
                <p className={styles.dateBlock}>Registration date</p> <ArrowDownIcon fill="#141415" width={"13px"} />
              </div>
            </div>
            <div className={styles.reportsContainer}>
              {isLoading && (
                <div className={styles.loaderWrapper}>
                  <PulseLoader color={"#236BFE"} />
                </div>
              )}
              {!isLoading &&
                owners.length > 0 &&
                owners.map((item, index) => {
                  return (
                    <BusinessItem allChecked={isChecked} key={item.id} item={item} setDeleteOwners={setDeleteOwners} />
                  );
                })}
              {!isLoading && owners.length === 0 && <Empty />}
            </div>
            <div className={styles.paginationContainer}>
              {pagesCount >= 0 &&
                Array(pagesCount === 0 ? 1 : pagesCount)
                  .fill("_")
                  .map((item, index) => (
                    <PageItem
                      onPress={() => onGetExplorers(index + 1, limit, search)}
                      key={index}
                      page={index + 1}
                      isActive={index + 1 === page}
                    />
                  ))}
              {pagesCount > 1 && page !== pagesCount && (
                <div onClick={() => onGetExplorers(page + 1, limit, search)} className={styles.nextBtn}>
                  Next
                </div>
              )}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
