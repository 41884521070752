import { ChangeEvent, useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import styles from "./styles.module.scss";
import Input from "../../components/Input";
import { ArrowDownIcon, FilterIcon, SearchIcon } from "../../assets";
import clsx from "clsx";
import Checkbox from "../../components/Checkbox";
import { PulseLoader } from "react-spinners";
import PageItem from "../../components/PageItem";
import OwnersItem from "../../components/OwnersItem";
import Filter from "../../components/Filter";
import { motion } from "framer-motion";
import { deleteUSer, getAllBusinessOwners } from "../../services/businessOwners.service";
import { IOwner } from "../../models/IOwner";
import Empty from "../../components/Empty/Index";
import Button from "../../components/Button";
import Delete from "../../modals/Delete";
import { toast } from "react-hot-toast";
import { toastError } from "../../constants";
import { NumbersContext } from "../../components/VerificationCounterContext/VerificationProvider";
import { getUser } from "../../utils/tokenStorage";
import OwnerFilter from "../../components/Filter/OwnerFilter";
const limit = 20;

export default function BusinessOwners() {
  const [openFilter, setOpenFilter] = useState(false);

  const [owners, setOwners] = useState<IOwner[]>([]);

  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState(false);
  // const [employers, setEmployers] = useState<IEmployer[]>([]);
  const [firstNameSort, setFirstNameSort] = useState<1 | -1 | 0>(0);
  const [lastNameSort, setLastNameSort] = useState<1 | -1 | 0>(0);
  const [citySort, setCitySort] = useState<1 | -1 | 0>(0);
  const [businessCitySort, setBusinessCitySort] = useState<1 | -1 | 0>(0);
  const [typeSort, setTypeSort] = useState<1 | -1 | 0>(0);
  const [revenueSort, setRevenueSort] = useState<1 | -1 | 0>(0);
  const [employeesSort, setEmployeesSort] = useState<1 | -1 | 0>(0);

  const [deleteOwners, setDeleteOwners] = useState<string[]>([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [currentSorts, setCurrentSort] = useState("");
  const [currentFilter, setCurrentFilter] = useState<any>();

  const verificationTotalCount = useContext(NumbersContext);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const me = getUser();

  const onGetOwners = async (
    page: number = 1,
    limit: number,
    search: string = "",
    type?:
      | "firstName"
      | "lastName"
      | "location.city"
      | "business.location.country"
      | "business.type"
      | "business.revenue.min"
      | "business.revenue.max"
      | "business.employees.min"
      | "business.employees.max",
    filter?: any,
  ) => {
    try {
      setIsLoading(true);
      let sort;
      switch (type) {
        case "firstName":
          sort = { firstName: firstNameSort };
          break;
        case "lastName":
          sort = { lastName: lastNameSort };
          break;
        case "location.city":
          sort = { "location.city": citySort };
          break;
        case "business.type":
          sort = { "business.type": typeSort };
          break;
        case "business.location.country":
          sort = { "business.location.country": businessCitySort };
          break;
        case "business.revenue.min":
          sort = { "business.revenue.min": revenueSort };
          break;
        case "business.employees.min":
          sort = { "business.employees.min": employeesSort };
          break;
        default:
          break;
      }
      const { data } = await getAllBusinessOwners(page, limit, search, sort || {}, filter || {});
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalOwners / limit));
        setOwners(data?.owners);
        verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        const sort = currentSorts?.length > 0 ? currentSorts : undefined;
        onGetOwners(1, limit, search, sort as "firstName", currentFilter);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      const sort = currentSorts?.length > 0 ? currentSorts : undefined;
      onGetOwners(1, limit, "", sort as "firstName", currentFilter);
    }
  }, [search]);
  const onDeleteEmployer = async () => {
    try {
      setIsLoading(true);
      const { data } = await deleteUSer(deleteOwners);
      if (data?.success) {
        onGetOwners(page, 20);
        toast.success("Users have been successfully deleted");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(toastError);
    }
  };

  const onApplyFilters = (
    gender: string,
    mobilityStatus: string,
    location: { city: string; state?: string; country: string },
    type: string,
    age: string,
    employers: string,
    revenue: string,
    businessIndustry: string[],
    businessNeeds: string[],
  ) => {
    let filter: any = {};
    if (gender !== "All") {
      filter.gender = gender;
    }
    if (mobilityStatus !== "All") {
      filter.mobilityStatus = [mobilityStatus];
    }
    if (age !== "All") {
      filter.age = {};
      //@ts-ignore
      filter.age.minAge = +age.match(/\d+/g)[0].trim();
      //@ts-ignore
      filter.age.maxAge = age.match(/\d+/g)?.length > 1 ? +age.match(/\d+/g)[1].trim() : +age.match(/\d+/g)[0].trim(); //@ts-ignore
    }
    if (location.city.length > 0) {
      filter.location = {};
      filter.location.city = location.city;
      filter.location.state = location.state;
      filter.location.country = location.country;
    }

    if (type !== "All") {
      filter.type = type;
    }
    if (revenue !== "All") {
      filter.revenue = {};
      //@ts-ignore
      filter.revenue.min = +revenue.match(/\d+/g)[0].trim();
      //@ts-ignore
      filter.revenue.max =
        //@ts-ignore
        revenue.match(/\d+/g)?.length > 1 ? +revenue.match(/\d+/g)[1].trim() : +revenue.match(/\d+/g)[0].trim();
    }
    if (employers !== "All") {
      filter.employees = {};
      //@ts-ignore
      filter.employees.min = +employers.match(/\d+/g)[0].trim();
      filter.employees.max = //@ts-ignore
        employers.match(/\d+/g)?.length > 1
          ? //@ts-ignore
            +employers.match(/\d+/g)[1].trim()
          : //@ts-ignore
            +employers.match(/\d+/g)[0].trim();
    }
    if (businessIndustry.length > 0 && !(businessIndustry.length === 1 && businessIndustry.includes("All"))) {
      //@ts-ignore
      filter.industry = businessIndustry;
    }
    if (businessNeeds.length > 0 && !(businessNeeds.length === 1 && businessNeeds.includes("All"))) {
      //@ts-ignore
      filter.currentNeeds = businessNeeds;
    }

    if (Object.keys(filter).length === 0) {
      setCurrentFilter(undefined);
    } else {
      setCurrentFilter(filter);
    }
    const sort = currentSorts?.length > 0 ? currentSorts : undefined;
    onGetOwners(page, limit, search, sort as "firstName", filter as {});
  };
  useEffect(() => {
    if (firstNameSort !== 0) {
      onGetOwners(page, 20, search, "firstName", currentFilter);
      setCurrentSort("firstName");
    } else if (citySort !== 0) {
      onGetOwners(page, 20, search, "location.city", currentFilter);
      setCurrentSort("location.city");
    } else if (businessCitySort !== 0) {
      onGetOwners(page, 20, search, "business.location.country", currentFilter);
      setCurrentSort("business.location.country");
    } else if (typeSort !== 0) {
      onGetOwners(page, 20, search, "business.type", currentFilter);
      setCurrentSort("business.type");
    } else if (revenueSort !== 0) {
      onGetOwners(page, 20, search, "business.revenue.min", currentFilter);
      setCurrentSort("business.revenue.min");
    } else if (employeesSort !== 0) {
      onGetOwners(page, 20, search, "business.employees.min", currentFilter);
      setCurrentSort("business.employees.min");
    } else {
      onGetOwners(page, 20, search, "nothing" as "firstName", currentFilter);
      setCurrentSort("");
    }
  }, [firstNameSort, citySort, businessCitySort, typeSort, revenueSort, employeesSort]);

  return (
    <>
      <Delete
        title="Delete user"
        onDelete={onDeleteEmployer}
        isLoading={isLoading || false}
        isOpen={isShowDeleteModal}
        setIsOpen={setIsShowDeleteModal}
        type="delete"
        text={`Are you sure you want to delete ${
          deleteOwners.length == 1 ? `1 user` : `${deleteOwners.length} users`
        }?`}
      />

      <Layout title={"Business Owners"}>
        <div className={styles.navBody}>
          <div className={styles.input}>
            <Input
              icon={SearchIcon}
              placeholder="Search"
              isEmpty={search.length === 0}
              isSearch
              onChange={onChangeSearch}
              style={{ width: "88%" }}
              setEmpty={() => setSearch("")}
            />
          </div>
          <div className={styles.filterBody}>
            <div className={clsx({ [styles.deleteOwnersButton]: deleteOwners.length > 0 })}>
              {deleteOwners.length > 0 && me?.role !== "manager" && (
                <Button
                  title={`Delete (${deleteOwners.length})`}
                  styleType="filled"
                  onClick={() => setIsShowDeleteModal(true)}
                  loading={isLoading}
                  style={{
                    border: "none",
                    width: "120px",
                    height: "43px",
                    background: "#FF3C3C",
                    borderRadius: "16px",
                  }}
                />
              )}
              <div
                onClick={(e) => setOpenFilter((current) => !current)}
                className={clsx({ [styles.filter]: !openFilter, [styles.filterSelected]: openFilter })}
              >
                <FilterIcon className={clsx({ [styles.svgOpen]: openFilter })} />
              </div>
            </div>
            <div className={clsx({ [styles.filterDetails]: !openFilter, [styles.filterDetailsOpen]: openFilter })}>
              <OwnerFilter onClose={setOpenFilter} onApply={onApplyFilters} />
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <section className={styles.contentContainer}>
            <div className={styles.sectionHeader}>
              <div className={styles.checkbox}>
                <Checkbox isChecked={isChecked} setIsChecked={() => setIsChecked((current) => !current)} mainCheckBox />
              </div>
              <div
                onClick={() => {
                  setCitySort(0);
                  setBusinessCitySort(0);
                  setTypeSort(0);
                  setRevenueSort(0);
                  setEmployeesSort(0);
                  setFirstNameSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("firstName");
                  // onGetOwners(page, 20, search, "firstName", currentFilter);
                }}
                className={clsx(styles.nameBlock, {
                  [styles.activeBlockAZ]: firstNameSort === 1,
                  [styles.activeBlockZA]: firstNameSort === -1,
                })}
              >
                <p>Owner name</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setBusinessCitySort(0);
                  setTypeSort(0);
                  setRevenueSort(0);
                  setEmployeesSort(0);
                  setCitySort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("location.city");
                  // onGetOwners(page, 20, search, "location.city", currentFilter);
                }}
                className={clsx(styles.industryBlock, {
                  [styles.activeBlockAZ]: citySort === 1,
                  [styles.activeBlockZA]: citySort === -1,
                })}
              >
                <p>Owner Location</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>

              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setCitySort(0);
                  setTypeSort(0);
                  setRevenueSort(0);
                  setEmployeesSort(0);
                  setBusinessCitySort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("business.location.country");
                  // onGetOwners(page, 20, search, "business.location.country", currentFilter);
                }}
                className={clsx(styles.locationBlock, {
                  [styles.activeBlockAZ]: businessCitySort === 1,
                  [styles.activeBlockZA]: businessCitySort === -1,
                })}
              >
                <p>Business Location</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setCitySort(0);
                  setBusinessCitySort(0);
                  setRevenueSort(0);
                  setEmployeesSort(0);
                  setTypeSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("business.type");
                  // onGetOwners(page, 20, search, "business.type", currentFilter);
                }}
                className={clsx(styles.sizeBlock, {
                  [styles.activeBlockAZ]: typeSort === 1,
                  [styles.activeBlockZA]: typeSort === -1,
                })}
              >
                <p>Business Type</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setCitySort(0);
                  setBusinessCitySort(0);
                  setTypeSort(0);
                  setEmployeesSort(0);
                  setRevenueSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("business.revenue.min");
                  // onGetOwners(page, 20, search, "business.revenue.min", currentFilter);
                }}
                className={clsx(styles.sizeBlock, {
                  [styles.activeBlockAZ]: revenueSort === 1,
                  [styles.activeBlockZA]: revenueSort === -1,
                })}
              >
                <p>Revenue</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
              <div
                onClick={() => {
                  setFirstNameSort(0);
                  setCitySort(0);
                  setBusinessCitySort(0);
                  setTypeSort(0);
                  setRevenueSort(0);
                  setEmployeesSort((prev) => {
                    if (prev === 0) {
                      return 1;
                    } else if (prev === 1) {
                      return -1;
                    } else return 0;
                  });
                  // setCurrentSort("business.employees.min");
                  // onGetOwners(page, 20, search, "business.employees.min", currentFilter);
                }}
                className={clsx(styles.sizeBlock, {
                  [styles.activeBlockAZ]: employeesSort === 1,
                  [styles.activeBlockZA]: employeesSort === -1,
                })}
              >
                <p>Employees</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
              </div>
            </div>
            <div className={styles.reportsContainer}>
              {isLoading && (
                <div className={styles.loaderWrapper}>
                  <PulseLoader color={"#236BFE"} />
                </div>
              )}
              {!isLoading &&
                owners.length > 0 &&
                owners.map((item, index) => {
                  return (
                    <OwnersItem allChecked={isChecked} key={item.id} item={item} setDeleteOwners={setDeleteOwners} />
                  );
                })}
              {!isLoading && owners.length === 0 && <Empty />}
            </div>
            <div className={styles.paginationContainer}>
              {pagesCount >= 0 &&
                Array(pagesCount === 0 ? 1 : pagesCount)
                  .fill("_")
                  .map((item, index) => (
                    <PageItem
                      onPress={() => onGetOwners(index + 1, limit, search)}
                      key={index}
                      page={index + 1}
                      isActive={index + 1 === page}
                    />
                  ))}
              {pagesCount > 1 && page !== pagesCount && (
                <div onClick={() => onGetOwners(page + 1, limit, search)} className={styles.nextBtn}>
                  Next
                </div>
              )}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
