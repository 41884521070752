import axiosInstance from "../apis";
import { AxiosResponse } from "axios";
export type ISortFeedback =
  | { "reporterInfo.firstName": 1 | -1 }
  | { "reporterInfo.lastName": 1 | -1 }
  | { category: -1 | 1 }
  | { "reportedOnInfo.firstName": -1 | 1 }
  | { "reportedOnInfo.lastName": -1 | 1 }
  | { createdAt: -1 | 1 }
  | undefined;
type IFilter = { "reporterInfo.role": "owner" | "explorer" } | { category: string[] } | undefined;
// export const getAllBusinessExolorers = async (
//   page: number,
//   limit: number = 20,
//   search: string,
//   sort: ISort,
//   filter?: IFilter,
// ): Promise<AxiosResponse> => {
//   const body = {
//     search: search || null,
//     sort,
//     filter: filter || {},
//   };

export const getAllFeedback = async (
  page: number,
  limit: number = 20,
  search: string,
  sort: ISortFeedback | {},
  filter?: IFilter,
): Promise<AxiosResponse> => {
  const body = {
    search: search || null,
    sort,
    filter: filter || {},
  };

  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`feedback/all?page=${page}&limit=${limit}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const resolveFeedback = async (id: string): Promise<AxiosResponse> => {
  const body = {
    feedbackId: id,
  };

  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`feedback`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

// type ISortExplorers =
//   | { firstName: 1 | -1 }
//   | { lastName: 1 | -1 }
//   | { "location.city": 1 | -1 }
//   | { phone: 1 | -1 }
//   | { email: 1 | -1 }
//   | { createdAt: 1 | -1 };

// export const getAllExplorersVerificationVerify = async (
//   page: number,
//   limit: number = 20,
//   search: string,
//   sort: ISortExplorers,
//   filter?: IFilter,
// ): Promise<AxiosResponse> => {
//   const body = {
//     search: search || null,
//     sort,
//     filter: filter || {},
//   };

//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .patch(`explorer/verify?page=${page}&limit=${limit}`, body)
//       .then((res) => resolve(res))
//       .catch((e) => reject(e?.response?.data));
//   });
// };

// export const verifyUserOwner = async (
//   value: { ownerId: string; status: "Approved" | "Declined"; reason?: string | null }[],
// ): Promise<AxiosResponse> => {
//   return new Promise((resolve, reject) => {
//     const body = {
//       verify: value,
//     };
//     axiosInstance
//       .put(`owner/verify`, body)
//       .then((res) => resolve(res))
//       .catch((e) => reject(e?.response?.data));
//   });
// };
// export const verifyUserExplorer = async (
//   value: { explorerId: string; status: "Approved" | "Declined"; reason?: string | null }[],
// ): Promise<AxiosResponse> => {
//   return new Promise((resolve, reject) => {
//     const body = {
//       verify: value,
//     };
//     axiosInstance
//       .put(`explorer/verify`, body)
//       .then((res) => resolve(res))
//       .catch((e) => reject(e?.response?.data));
//   });
// };

// export const editExplorer = (
//   id: string,
//   email: string,
//   firstName: string,
//   lastName: string,
//   avatar: null | string,
//   gender: "Male" | "Female",
//   aboutMe: string,
//   age: number,
//   mobilityStatus: string,
//   location: {
//     country: string;
//     state?: string;
//     city: string;
//   },
//   phone: string,
//   currentNeeds: string[],
// ): Promise<AxiosResponse> => {
//   return new Promise((resolve, reject) => {
//     const body: any = {
//       phone,
//       email,
//       gender,
//       firstName,
//       lastName,
//       description: aboutMe,
//       age,
//       mobilityStatus,
//       location,
//       currentNeeds,
//       avatar: avatar ? avatar : null,
//     };
//     axiosInstance
//       .post(`explorer?explorerId=${id}`, body)
//       .then((res) => resolve(res))
//       .catch((e) => reject(e?.response?.data));
//   });
// };

// export const blockUserExplorers = async (
//   id: string,
//   reason: string,
//   type: "block" | "unblock",
// ): Promise<AxiosResponse> => {
//   const body = {
//     explorerId: id,
//     reason,
//   };
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .patch(`explorer/${type}`, body)
//       .then((res) => resolve(res))
//       .catch((e) => reject(e?.response?.data));
//   });
// };
// export const deleteUserExplorer = async (id: string | string[]): Promise<AxiosResponse> => {
//   let idList;
//   if (Array.isArray(id)) {
//     idList = id;
//   } else idList = [id];
//   const body = {
//     idList,
//   };
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .patch(`explorer/delete`, body)
//       .then((res) => resolve(res))
//       .catch((e) => reject(e?.response?.data));
//   });
// };
