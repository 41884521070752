import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { toast } from "react-hot-toast";
import Layout from "../../components/Layout";
import { blockUserExplorers, deleteUserExplorer, getOneExplorer } from "../../services/businessExplorers.service";
import { IExplorer } from "../../models/IExplorers";
import AccountDetailsExplorer from "./AccountDetails";
import { toastError } from "../../constants";

interface Props {}

const ExplorerProfile: FC<Props> = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<"Posts" | "BusinessDetails" | "AccountDetails">("AccountDetails");
  const [search, setSearch] = useState<string>("");
  const [explorer, setExplorer] = useState<IExplorer>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const onGetExplorer = async (id: string) => {
    try {
      setIsLoading(true);

      const { data } = await getOneExplorer(id);

      if (data?.success) {
        setExplorer(data?.explorer);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      onGetExplorer(id);
    }
  }, [id]);
  const onDeleteExplorer = async () => {
    try {
      setIsLoading(true);
      const { data } = await deleteUserExplorer(id || "0");
      if (data?.success) {
        setIsLoading(false);
        navigate(-1);
        toast.success("User has been successfully deleted");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(toastError);
    }
  };
  const onBlock = async (reason: string, type: "block" | "unblock") => {
    try {
      setIsLoading(true);
      const { data } = await blockUserExplorers(explorer?.id || "", reason, type);
      if (data?.success) {
        setIsLoading(false);
        onGetExplorer(explorer?.id || "");
        toast.success(type === "block" ? "User has been blocked" : "User has been unblocked ");
      }
    } catch (error) {
      toast.error(toastError);
      setIsLoading(false);
    }
  };
  return (
    <Layout
      subtitle={"Seeker's Profile"}
      onDeleteEmployer={onDeleteExplorer}
      onBlock={onBlock}
      isLoadingDelete={isLoadingDelete}
      isBlocked={explorer?.block}
      userId={explorer?.id || "1"}
    >
      <AccountDetailsExplorer owner={explorer} id={explorer?.id} onGetExlorer={onGetExplorer} />
    </Layout>
  );
};

export default ExplorerProfile;
