import clsx from "clsx";
import React, { FunctionComponent, InputHTMLAttributes, SVGProps, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { GOOGLE_API_KEY } from "../../constants";
import Autocomplete from "react-google-autocomplete";
import Input from ".";
// @ts-ignore
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  wrapperStyles?: any;
  placeholder: string;
  value: string;
  style?: {};
  error?: string;
  isFilter?: boolean;
  onSelect: (value: { city: string; country: string; state: string }) => void;
}

// export default function GoogleInput({
//   label = "",
//   wrapperStyles = {},
//   style,
//   placeholder,
//   onSelect,
//   value,
//   error,
//   isFilter,
//   ...props
// }: Props) {
//   const [defaultValue, setDefaultValue] = useState("");

//   useEffect(() => {
//     setDefaultValue(value);
//   }, [value]);

//   const changeStyleGoogleInput = () => {
//     const googleHeaderInput: NodeListOf<HTMLDivElement> | null = document.querySelectorAll(".pac-container");

//     const googleInput: HTMLInputElement | null = document.querySelector(".google_input");

//     if (googleHeaderInput) {
//       Array.from(googleHeaderInput).forEach((div) => {
//         div.style.width = (typeof googleInput?.offsetWidth === "number" ? googleInput?.offsetWidth : "") + "px";
//       });
//     }
//   };

//   const deleteGoogleInput = () => {
//     const googleHeaderInput: NodeListOf<HTMLDivElement> | null = document.querySelectorAll(".pac-container");

//     const googleInput: HTMLInputElement | null = document.querySelector(".google_input");

//     if (googleHeaderInput) {
//       Array.from(googleHeaderInput).forEach((div) => {
//         div.innerHTML = "";
//         div.style.removeProperty("width");
//         div.style.removeProperty("position");
//         div.style.removeProperty("left");
//         div.style.removeProperty("top");
//       });
//     }
//   };
//   return (
//     <div className={clsx(styles.input, wrapperStyles)}>
//       {label && <span>{label}</span>}
//       <div className={styles.inputWrapper}>
//         <Autocomplete
//           placeholder={placeholder}
//           defaultValue={defaultValue}
//           onClick={changeStyleGoogleInput}
//           onChange={changeStyleGoogleInput}
//           onBlur={deleteGoogleInput}
//           className={clsx(styles.input__mainGoogle, "google_input")}
//           language="EN"
//           {...props}
//           apiKey={GOOGLE_API_KEY}
//           onPlaceSelected={(place: any) => {
//             let updatedLocation = { city: "", state: "", country: "" };
//             place.address_components.forEach((location: any) => {
//               if (location.types.includes("locality")) {
//                 updatedLocation.city = location.long_name;
//               } else if (location.types.includes("country")) {
//                 updatedLocation.country = location.long_name;
//               } else if (location.types.includes("administrative_area_level_1")) {
//                 updatedLocation.state = location.long_name;
//               }
//             });

//             onSelect(updatedLocation);
//           }}
//         />
//         {error && <p className={styles.error}>{error}</p>}
//       </div>
//     </div>
//   );
// }

export default function GoogleInput({
  label = "",
  wrapperStyles = {},
  style,
  placeholder,
  onSelect,
  value,
  error,
  isFilter,
  ...props
}: Props) {
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const googleRef = useRef<HTMLInputElement>(null);
  const handlePlaceSelect = (selectedAddress: string) => {
    setAddress(selectedAddress);
    setSuggestions([]);
    if (window.google && window.google.maps) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: selectedAddress }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
          const addressComponents = results[0].address_components;
          let city = "";
          let state = "";
          let country = "";
          let address = "";
          for (let i = 0; i < addressComponents.length; i++) {
            const types = addressComponents[i].types;
            if (
              city === "" &&
              (types.includes("locality") ||
                types.includes("administrative_area_level_1") ||
                types.includes("administrative_area_level_2") ||
                types.includes("administrative_area_level_3") ||
                types.includes("country"))
            ) {
              city = addressComponents[i].long_name;
            }
            if (
              types.includes("administrative_area_level_1") ||
              types.includes("administrative_area_level_2") ||
              types.includes("administrative_area_level_3")
            ) {
              state = addressComponents[i].long_name;
            }
            if (types.includes("country")) {
              country = addressComponents[i].long_name;
            }
          }
          address = results[0].formatted_address;
          onSelect({ city, country, state });
        }
      });
    }
  };

  const fetchSuggestions = () => {
    if (window.google && window.google.maps) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        { input: address, types: ["(cities)"] },
        (predictions: any[], status: google.maps.places.PlacesServiceStatus) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        },
      );
    }
    // if (window.google && window.google.maps) {
    //   const placeSearchService = new window.google.maps.places.PlacesService();
    //   placeSearchService.search(
    //     {
    //       input: address,
    //       fields: ["place_id", "description", "formatted_address"],
    //     },
    //     (results: any[], status: google.maps.places.PlacesServiceStatus) => {
    //       if (status === window.google.maps.PlacesServiceStatus.OK) {
    //         setSuggestions(results);
    //       } else {
    //         setSuggestions([]);
    //       }
    //     },
    //   );
    // }
  };

  let debounceTimeout: ReturnType<typeof setTimeout>;

  function debounce<T extends (...args: any[]) => void>(func: T, delay: number): void {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(func, delay) as ReturnType<typeof setTimeout>;
  }

  const handleInputChange = (event: any) => {
    setAddress(event.target.value);
    debounce(fetchSuggestions, 300);
  };

  useEffect(() => {
    if (!value && googleRef.current) {
      googleRef.current.value = "";
    } else {
      setAddress(value);
      // if (googleRef.current) googleRef.current.value = value;
    }
  }, [value]);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setSuggestions([]);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className={"input__container"}>
        <div className="input__relative">
          <div style={{ position: "relative" }}>
            <div
              ref={dropdownRef}
              className={clsx(styles.googleOpen_wrapper, { [styles.googleOpen_wrapper_open]: suggestions.length > 0 })}
            >
              <Input
                label={label}
                name=""
                {...props}
                type="text"
                placeholder={placeholder}
                value={address}
                onChange={handleInputChange}
              />
              {suggestions.length > 0 && (
                <ul
                  className={clsx({
                    [styles.optionOpen]: suggestions.length > 0,
                    [styles.option]: suggestions.length === 0,
                    // [styles.lastOption]: options[options.length - 1] === LastElem && isOpen,
                  })}
                >
                  {suggestions.map((suggestion) => (
                    <li
                      style={{ padding: "10px 0 10px 0" }}
                      className={styles.optionItem}
                      key={suggestion.place_id}
                      onClick={() => {
                        handlePlaceSelect(suggestion.description);
                      }}
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {error && (
              <p style={{ position: "absolute", bottom: "-20px" }} className="input__error">
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
