import { FC, useEffect, useState } from "react";
import CheckBox from "../Checkbox";
import styles from "./styles.module.scss";
import PlaceholderAvatar from "../../assets/images/avatar.png";
// import ReportType from "../ReportType";
import { ArrowDownIcon } from "../../assets";
// import { Report } from "../../modals";
import { useNavigate } from "react-router-dom";
import StaffItem from "../StaffItem";
import { IOwner } from "../../models/IOwner";
import ApproveModal from "../../modals/Approve";
import ActiveButton from "../ActiveButtons/ActiveButton";
import DeclineButton from "../ActiveButtons/DeclineButton";
import AgreementModal from "../../modals/Agreement";
import { getUser } from "../../utils/tokenStorage";
// import { IEmployer } from "../../models";

// interface Props {
//   item: IEmployer;
// }
interface Props {
  id: string;
  image: null;
  name: string;
  description: string;
  ownerLocation: string;
  businessLocation: string;
  businessType: string;
  revenue: string;
  employees: string;
}

const OwnerVerificationItem = ({
  item,
  allChecked,
  setVerifyUser,
  isLoading,
  handleVerify,
}: {
  item: IOwner;
  allChecked: boolean;
  setVerifyUser: (item: any) => void;
  isLoading: boolean;
  handleVerify: (verifyData?: { id: string; status: "Approved" | "Declined"; reason?: string }) => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const me = getUser();
  useEffect(() => {
    setIsChecked(allChecked);
  }, [allChecked]);

  useEffect(() => {
    if (isChecked) {
      setVerifyUser((current: any) => [...current, item.id]);
    }
    if (!isChecked) setVerifyUser((current: any) => current.filter((id: any) => id != item.id));
  }, [isChecked]);

  useEffect(() => {
    return () => setVerifyUser([]);
  }, []);
  const navigate = useNavigate();

  let revenue;

  if (!item?.business[0]?.revenue) {
    revenue = "";
  } else if (item?.business[0]?.revenue && item?.business[0]?.revenue?.min === item?.business[0]?.revenue?.max) {
    revenue = `${item?.business[0]?.revenue?.min}k +`;
  } else {
    revenue = `${
      item?.business[0]?.revenue?.min > 0 ? item?.business[0]?.revenue?.min + "k" : item?.business[0]?.revenue?.min || 0
    } - ${item?.business[0]?.revenue?.max || 100}k`;
  }

  function formatDate(dateString: string) {
    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = MONTHS[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

  const verify = (status?: "Approved" | "Declined", reason?: string) => {
    if (isDelete) {
      handleVerify({ id: item.id, status: status || "Declined", reason: reason || "" });
    } else handleVerify({ id: item.id, status: "Approved" });
  };
  return (
    <>
      <AgreementModal
        isLoading={isLoading}
        isOpen={showConfirmModal}
        setIsOpen={setShowConfirmModal}
        text={isDelete ? "Decline verification?" : "Verify user?"}
        title="Verification"
        onAccept={verify}
      />
      <ApproveModal isOpen={showApproveModal} setIsOpen={setShowApproveModal} onAccept={verify} user={item} />
      <div className={styles.businessBody}>
        <div className={styles.wrapper}>
          <div className={styles.checkbox}>
            <CheckBox isChecked={isChecked} setIsChecked={() => setIsChecked((current) => !current)} />
          </div>
          <div onClick={() => navigate(`/verification/owner/${item.id}`)}>
            <StaffItem
              image={item.avatar}
              name={`${item.firstName} ${item.lastName}`}
              description={item.business[0]?.name}
            />
          </div>
          <div onClick={() => navigate(`/verification/owner/${item.id}`)}>
            <div className={styles.ownerLocationWrapper}>
              <p>
                {item.business[0]?.location.city
                  ? `${item.business[0]?.location.city} ${
                      item.business[0]?.location.state ? `, ${item.business[0]?.location.state}` : ""
                    }` || ""
                  : ""}
              </p>
            </div>
          </div>
          <div onClick={() => navigate(`/verification/owner/${item.id}`)}>
            <div className={styles.businessLocationWrapper}>
              <p>{item.business[0]?.type}</p>
            </div>
          </div>
          <div onClick={() => navigate(`/verification/owner/${item.id}`)}>
            <div className={styles.businessTypeWrapper}>
              <p>{revenue}</p>
            </div>
          </div>
          <div onClick={() => navigate(`/verification/owner/${item.id}`)}>
            <div className={styles.revenueWrapper}>
              <p>
                {typeof item.business[0]?.employees.min !== "undefined"
                  ? item.business[0]?.employees.min === item.business[0]?.employees.max
                    ? `${item.business[0]?.employees.min}+`
                    : typeof item.business[0]?.employees.min === "number" &&
                      typeof item.business[0]?.employees.max === "number"
                    ? `${item.business[0]?.employees.min} - ${item.business[0]?.employees.max}`
                    : ""
                  : ""}
              </p>
            </div>
          </div>
          <div onClick={() => navigate(`/verification/owner/${item.id}`)}>
            <div className={styles.employeesWrapper}>
              <p>{formatDate(item.createdAt)}</p>
            </div>
          </div>
          {me?.role !== "manager" && (
            <div className={styles.acceptButtons}>
              <div
                onClick={() => {
                  setShowConfirmModal(true);
                }}
              >
                <ActiveButton />
              </div>
              <div
                onClick={() => {
                  setShowApproveModal(true);
                  setDelete(true);
                }}
              >
                <DeclineButton />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default OwnerVerificationItem;
