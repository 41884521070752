import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { getAllBusinessOwnersVerificationVerify } from "../../services/verification.service";
import Checkbox from "../../components/Checkbox";
import styles from "./styles.module.scss";
import { ArrowDownIcon } from "../../assets";
import clsx from "clsx";
import OwnerVerificationItem from "../../components/VerificationItem/OwnersVerificationItem";
import { PulseLoader } from "react-spinners";
import PageItem from "../../components/PageItem";
import { IOwner } from "../../models/IOwner";
import Empty from "../../components/Empty/Index";
import { NumbersContext } from "../../components/VerificationCounterContext/VerificationProvider";
import { getUser } from "../../utils/tokenStorage";
const limit = 20;
export default function BusinessOwnersVerification({
  setVerifyUsers,
  setTypePage,
  isLoading: loadingServer,
  handleVerify,
  setTagOwner,
  setTagExplorer,
}: {
  setVerifyUsers: (value: string[]) => void;
  setTypePage: (value: "owner" | "explorer") => void;
  isLoading: boolean;
  handleVerify: (verifyData?: { id: string; status: "Approved" | "Declined" }) => void;
  setTagOwner: (value: number) => void;
  setTagExplorer: (value: number) => void;
}) {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState(false);
  const [owners, setOwners] = useState<IOwner[]>([]);
  const [firstNameSort, setFirstNameSort] = useState<1 | -1 | 0>(0);
  const [lastNameSort, setLastNameSort] = useState<1 | -1 | 0>(0);
  const [citySort, setCitySort] = useState<1 | -1 | 0>(0);
  const [businessCitySort, setBusinessCitySort] = useState<1 | -1 | 0>(0);
  const [typeSort, setTypeSort] = useState<1 | -1 | 0>(0);
  const [revenueSort, setRevenueSort] = useState<1 | -1 | 0>(0);
  const [employeesSort, setEmployeesSort] = useState<1 | -1 | 0>(0);
  const [createdAtSort, setCreatedAtSort] = useState<1 | -1 | 0>(0);

  const verificationTotalCount = useContext(NumbersContext);

  useEffect(() => {
    setIsLoading(loadingServer);
  }, [loadingServer]);
  const onGetVerificationOwners = async (
    page: number = 1,
    limit: number,
    search: string = "",
    type?:
      | "firstName"
      | "lastName"
      | "location.city"
      | "business.location.country"
      | "business.type"
      | "business.revenue.min"
      | "business.revenue.max"
      | "business.employees.min"
      | "business.employees.max"
      | "createdAt",
  ) => {
    try {
      setIsLoading(true);
      let sort;
      switch (type) {
        case "firstName":
          sort = { firstName: firstNameSort };
          break;
        case "lastName":
          sort = { lastName: lastNameSort };
          break;
        case "location.city":
          sort = { "business.location.city": citySort };
          break;
        case "business.type":
          sort = { "business.type": typeSort };
          break;
        case "business.location.country":
          sort = { "business.location.country": businessCitySort };
          break;
        case "business.revenue.min":
          sort = { "business.revenue.min": revenueSort };
          break;
        case "business.employees.min":
          sort = { "business.employees.min": employeesSort };
          break;
        case "createdAt":
          sort = { createdAt: createdAtSort };
          break;
        default:
          break;
      }
      const { data } = await getAllBusinessOwnersVerificationVerify(page, limit, search, sort || {});
      if (data?.success) {
        setPagesCount(Math.ceil(data?.totalOwners / limit));
        setOwners(data?.owners);
        setTagOwner(data?.totalOwnersNV);
        setTagExplorer(data?.totalExplorersNV);
        verificationTotalCount.addNumber(data?.totalOwnersNV + data?.totalExplorersNV);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    onGetVerificationOwners(1, limit, search);
    setTypePage("owner");
  }, []);

  useEffect(() => {
    if (firstNameSort !== 0) {
      onGetVerificationOwners(page, 20, search, "firstName");
    } else if (citySort !== 0) {
      onGetVerificationOwners(page, 20, search, "location.city");
    } else if (createdAtSort !== 0) {
      onGetVerificationOwners(page, 20, search, "createdAt");
    } else if (typeSort !== 0) {
      onGetVerificationOwners(page, 20, search, "business.type");
    } else if (revenueSort !== 0) {
      onGetVerificationOwners(page, 20, search, "business.revenue.min");
    } else if (employeesSort !== 0) {
      onGetVerificationOwners(page, 20, search, "business.employees.min");
    } else {
      onGetVerificationOwners(page, 20, search, "nothing" as "firstName");
    }
  }, [firstNameSort, citySort, createdAtSort, typeSort, revenueSort, employeesSort]);

  return (
    <div className={styles.wrapper}>
      <section className={styles.contentContainer}>
        <div className={styles.sectionHeader}>
          <div className={styles.checkbox}>
            <Checkbox isChecked={isChecked} setIsChecked={() => setIsChecked((current) => !current)} mainCheckBox />
          </div>
          <div
            onClick={() => {
              setCitySort(0);
              setBusinessCitySort(0);
              setTypeSort(0);
              setRevenueSort(0);
              setEmployeesSort(0);
              setCreatedAtSort(0);
              setFirstNameSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetVerificationOwners(page, 20, search, "firstName");
            }}
            className={clsx(styles.nameBlock, {
              [styles.activeBlockAZ]: firstNameSort === 1,
              [styles.activeBlockZA]: firstNameSort === -1,
            })}
          >
            <p>Name</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>
          <div
            onClick={() => {
              setFirstNameSort(0);
              setBusinessCitySort(0);
              setTypeSort(0);
              setRevenueSort(0);
              setEmployeesSort(0);
              setCreatedAtSort(0);
              setCitySort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetVerificationOwners(page, 20, search, "location.city");
            }}
            className={clsx(styles.industryBlock, {
              [styles.activeBlockAZ]: citySort === 1,
              [styles.activeBlockZA]: citySort === -1,
            })}
          >
            <p>Business Location</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>

          <div
            onClick={() => {
              setFirstNameSort(0);
              setCitySort(0);
              setBusinessCitySort(0);
              setRevenueSort(0);
              setEmployeesSort(0);
              setCreatedAtSort(0);
              setTypeSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetVerificationOwners(page, 20, search, "business.type");
            }}
            className={clsx(styles.sizeBlock, {
              [styles.activeBlockAZ]: typeSort === 1,
              [styles.activeBlockZA]: typeSort === -1,
            })}
          >
            <p>Business Type</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>
          <div
            onClick={() => {
              setFirstNameSort(0);
              setCitySort(0);
              setBusinessCitySort(0);
              setTypeSort(0);
              setEmployeesSort(0);
              setCreatedAtSort(0);
              setRevenueSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetVerificationOwners(page, 20, search, "business.revenue.min");
            }}
            className={clsx(styles.sizeBlock, {
              [styles.activeBlockAZ]: revenueSort === 1,
              [styles.activeBlockZA]: revenueSort === -1,
            })}
          >
            <p>Revenue</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>
          <div
            onClick={() => {
              setFirstNameSort(0);
              setCitySort(0);
              setBusinessCitySort(0);
              setTypeSort(0);
              setRevenueSort(0);
              setCreatedAtSort(0);
              setEmployeesSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetVerificationOwners(page, 20, search, "business.employees.min");
            }}
            className={clsx(styles.sizeBlock, {
              [styles.activeBlockAZ]: employeesSort === 1,
              [styles.activeBlockZA]: employeesSort === -1,
            })}
          >
            <p>Employees</p> <ArrowDownIcon fill="#B2B3BD" width={"13px"} />
          </div>
          <div
            onClick={() => {
              setFirstNameSort(0);
              setCitySort(0);
              setBusinessCitySort(0);
              setTypeSort(0);
              setRevenueSort(0);
              setEmployeesSort(0);
              setCreatedAtSort((prev) => {
                if (prev === 0) {
                  return 1;
                } else if (prev === 1) {
                  return -1;
                } else return 0;
              });
              // onGetVerificationOwners(page, 20, search, "createdAt");
            }}
            className={clsx(styles.sizeBlock, {
              [styles.activeBlockAZ]: createdAtSort === 1,
              [styles.activeBlockZA]: createdAtSort === -1,
            })}
          >
            <p className={styles.dateBlock}>Registration date</p> <ArrowDownIcon fill="black" width={"13px"} />
          </div>
        </div>
        <div className={styles.reportsContainer}>
          {isLoading && (
            <div className={styles.loaderWrapper}>
              <PulseLoader color={"#236BFE"} />
            </div>
          )}
          {!isLoading &&
            owners.map((item, index) => {
              return (
                <OwnerVerificationItem
                  key={item.id}
                  isLoading={isLoading}
                  item={item}
                  allChecked={isChecked}
                  setVerifyUser={setVerifyUsers}
                  handleVerify={handleVerify}
                />
              );
            })}
          {!isLoading && owners.length === 0 && <Empty />}
        </div>
        <div className={styles.paginationContainer}>
          {pagesCount >= 0 &&
            Array(pagesCount === 0 ? 1 : pagesCount)
              .fill("_")
              .map((item, index) => (
                <PageItem
                  onPress={() => onGetVerificationOwners(index + 1, limit, search)}
                  key={index}
                  page={index + 1}
                  isActive={index + 1 === page}
                />
              ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div onClick={() => onGetVerificationOwners(page + 1, limit, search)} className={styles.nextBtn}>
              Next
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
