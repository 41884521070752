import axiosInstance from "../apis";
import { AxiosResponse } from "axios";
type ISort =
  | { firstName: 1 | -1 }
  | { lastName: 1 | -1 }
  | { "location.city": 1 | -1 }
  | { "business.type": 1 | -1 }
  | { "business.location.country": 1 | -1 }
  | { "business.revenue.min": 1 | -1 }
  | { "business.revenue.max": 1 | -1 }
  | { "business.employees.min": 1 | -1 }
  | { "business.employees.max": 1 | -1 }
  | { createdAt: 1 | -1 };
// type IFilter =
//   | { gender: "Male" | "Female" }
//   | { "business.location.country": string }
//   | { "business.type": string }
//   | { "business.revenue.min": number }
//   | { "business.revenue.max": number }
//   | { "business.employees.min": number }
//   | { "business.employees.max": number };
interface IFilter {
  gender: string;
  mobilityStatus: string[];
  location: {
    country: string;
    state: string;
    city: string;
  };
  type: string;
  age: {
    minAge: number;
    maxAge: number;
  };
  employees: {
    min: number;
    max: number;
  };
  revenue: {
    min: number;
    max: number;
  };
  yearsInBusiness: {
    min: number;
    max: number;
  };
  industry: string[];
  currentNeeds: string[];
}

export const getAllBusinessOwners = async (
  page: number,
  limit: number = 20,
  search: string,
  sort?: ISort | {},
  filter?: IFilter,
): Promise<AxiosResponse> => {
  const body = {
    search: search || null,
    sort,
    filter: filter || {},
  };

  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`owner/all?page=${page}&limit=${limit}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const getOneOwner = async (id: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`owner?ownerId=${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const getPosts = async (id: string, page: number, limit: number, search?: string): Promise<AxiosResponse> => {
  if (search) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`post/owner?ownerId=${id}&page=${page}&limit=${limit}&search=${search}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e?.response?.data));
    });
  }
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`post/owner?ownerId=${id}&page=${page}&limit=${limit}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const editOwner = (
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  avatar: string | null,
  gender: string,
  age: number,
  mobilityStatus: string,
  location: {
    country: string;
    city: string;
  },
  transientLocation?: string,
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      gender,
      firstName,
      lastName,
      age,
      mobilityStatus,
      location,
      avatar: avatar ? avatar : null,
      transientLocation: transientLocation,
    };
    axiosInstance
      .post(`owner?ownerId=${id}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const editBusiness = (
  id: string,
  socialConnection: string,
  name: string,
  website: string | null,
  phone: string | null,
  email: string,
  revenue: { min: number; max: number },
  type: string,
  travelFrequency: string,
  location: {
    country: string;
    city: string;
  },
  stage: string,
  currentNeeds: string[],
  employees: { min: number; max: number },
  yearsInBusiness: { min: number; max: number },
  industry: string[],
  description: string | null,
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const body: any = {
      ownerId: id,
      socialConnection,
      name,
      website,
      phone,
      email,
      revenue,
      type,
      location,
      stage,
      currentNeeds,
      employees,
      yearsInBusiness,
      description,
      industry,
      travelFrequency,
    };

    axiosInstance
      .post(`business`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
export const blockUser = async (id: string, reason: string, type: "block" | "unblock"): Promise<AxiosResponse> => {
  const body = {
    ownerId: id,
    reason,
  };
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`owner/${type}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};

export const deleteUSer = async (id: string | string[]): Promise<AxiosResponse> => {
  let idList;
  if (Array.isArray(id)) {
    idList = id;
  } else idList = [id];
  const body = {
    idList,
  };
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(`owner/delete`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e?.response?.data));
  });
};
