import axiosInstance from "../apis";
import { AxiosResponse } from "axios";

export const uploadFile = (file: any, uploadUrl: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(uploadUrl, file, {
        headers: {
          "Content-Type": "application/octet-stream",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
