import clsx from "clsx";
import React, { ButtonHTMLAttributes, FC, FunctionComponent, ReactNode, SVGProps, useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  loading?: boolean;
  styleType?: "filled" | "outlined" | "outlined-dashed" | "inactive" | "disabled";
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  textPosition?: "center" | "flex-start";
  isShowBorder?: boolean;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const Button: FC<IProps> = ({
  title,
  loading = false,
  styleType = "filled",
  backgroundColor = "",
  color = "",
  disabled: disableStatus = false,
  textPosition = "center",
  isShowBorder = true,
  icon,
  ...props
}): JSX.Element => {
  const [disabled, setDisabled] = useState(false);
  const Icon: FunctionComponent<SVGProps<SVGSVGElement>> | any = icon;

  useEffect(() => {
    setDisabled(disableStatus);
  }, [disableStatus]);

  // useEffect(() => {
  //   if (loading) {
  //     setDisabled(true);
  //   } else setDisabled(false);
  // }, [loading]);
  return (
    <button
      disabled={loading || disabled}
      style={{
        background: backgroundColor,
        color: color,
        justifyContent: textPosition,
        borderColor: isShowBorder ? color : "transparent",
        boxSizing: "border-box",
      }}
      className={clsx({
        [styles.disabled]: disabled || loading,
        [styles.filled]: styleType === "filled" && !disabled,
        [styles.outlined]: styleType === "outlined",
      })}
      {...props}
    >
      <div style={{ color }} className={styles.titleWrapper}>
        {icon && <Icon />}
        {title}
      </div>
    </button>
  );
};

export default Button;
