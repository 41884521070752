import { ArrowDownIcon } from "../../assets";
import styles from "./styles.module.scss";

export default function Decrease({ percent }: { percent: string }) {
  return (
    <div className={styles.statsBody}>
      <div className={styles.arrowBodyDescrease}>
        <ArrowDownIcon width={"10px"} className={styles.arrowUp} />
      </div>
      <p className={styles.decreaseInfo}>{percent}</p>
    </div>
  );
}
